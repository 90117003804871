<app-page-progress [progress]="progress"></app-page-progress>
<div class="ctn-redirect-sura">
  <div class="ctn-redirect-sura__info">
    <img src="./assets/icons/warning.svg" alt="Sura" />
    <div class="ctn-redirect-sura__info__title">
      <div>Apreciado usuario SURA:</div>
    </div>
    <div class="ctn-redirect-sura__info__parrafo">
      <p class="ctn__text">
        Para realizar la solicitud de tus medicamentos a domicilio, por favor ingresa a través de Farmadomicilios
      </p>
      <p class="ctn__text">
         <a class="url" >
          <b>{{urlSuraRedirect}}</b>
      </a>, muchas gracias.
      </p>
    </div>
    <div class="buttons">
      <button class="behind" (click)="toReturn()">
        Regresar
      </button>
    </div>
  </div>
</div>
