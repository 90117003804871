import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { environment } from 'src/app/configs/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormulaService {

  constructor(
    private _HTTP: HttpService
  ) { }

  public getFormula(identification: string, identificationType: string, idEps: string, status: string, numberFormula?: string, authorization?: string): Observable<any> {
    const params = {
      numeroId: identification,
      tipoId: identificationType,
      eps: idEps,
      estado: status,
      numeroOrden: numberFormula,
      numeroPreautorizacion: authorization,
    };

    //return this._HTTP.get<any>(`${environment.API_FORMULA}/consultarFormula`, {params})
    //https://run.mocky.io/v3/cac63ff6-d6ea-4714-a984-f057b1552795 - Ori
    //`https://run.mocky.io/v3/25eacf93-12c7-4050-9f9e-8579495f8816`
    return this._HTTP.get<any>(`${environment.API_FORMULA}/consultarFormula`, {params})
    .pipe(
      map(
        response =>  response
      )
    );
  }

  public getFormulaMock(): Observable<any> {

    //return this._HTTP.get<any>(`${environment.API_FORMULA}/consultarFormula`, {params})
    //https://run.mocky.io/v3/cac63ff6-d6ea-4714-a984-f057b1552795 - Ori
    //`https://run.mocky.io/v3/25eacf93-12c7-4050-9f9e-8579495f8816`

    return this._HTTP.get<any>(`https://run.mocky.io/v3/80621304-7dfb-4dac-aefa-9a0671eb13ed`)
    .pipe(
      map(
        response =>  response
      )
    );
  }


  public getFormulaExpirationDate(request: any){
    let formulas={
      formulas : request
    }
    return this._HTTP.post<any>(`${environment.API_FORMULA}/fechavencimientoformula`, formulas)
      .pipe(
        map(
          respose => respose
        )
      );
  }

}
