export class AppConfig {
    public static KEY_TIPO_DOCUMENTO(): string { return 'customer_tipo_documento'; }
    public static TOKEN_NAME_JTWT(): string { return 'jw_token' }
    public static SSO_TOKEN(): string { return 'sso_token' }
    public static SSO_CIAM(): string { return 'sso_persona' }
    public static get VALIDITY_TOKEN_MICROSERVICES(): number { return 604800;  }
    public static KEY_TIMES_MICROSERVICES(): string { return  'key_time_microservices'; }
    public static get DEVELOP_ENDPOINT(): string  { return  'http://localhost:3000'; }
    public static  get USER_NAME(): string { return  'edwin'; }
    public static  get USER_PASSWORD(): string { return  'password'; }
    public static get USER_BASE64(): string { return  'ZWR3aW46MTIz'; }
    public static get KEY_RATE_NOTIFICATION(): string {return 'key_ratenotification';}
    public static get KEY_TYPE_RESERVATION(): string {return 'key_reservation';}
  }
