import { Component, HostListener, OnInit } from '@angular/core';
import { Carousel } from 'src/app/core/models/carousel/carousel';
import { CAROUSEL_DATA_ITEMS } from 'src/app/core/constants/carousel.const';
import { IMAGES_PORTAL, MESSAGES} from 'src/app/core/constants/assetsHome.const';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { JwUser } from 'src/app/core/models/jw-user/jw-user';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import { AppConfig } from 'src/app/configs/app-config';
import { PatientService } from 'src/app/core/services/patient/patient.service';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { EpsService } from 'src/app/core/services/eps/eps.service';
import { Eps } from 'src/app/core/models/eps/eps';
import { JwtService } from 'src/app/core/services/jwt.service';
import { environment } from 'src/app/configs/environment';
import { ParameterService } from 'src/app/core/services/parameter/parameter.service';
import { Parameter } from 'src/app/core/models/parameter/parameter';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { ContentManagerService } from 'src/app/core/services/content-manager/content-manager.service';
import { SessionStorageService } from 'src/app/core/services/session-storage/local-storage.service';

declare let dataLayer;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit {

  @HostListener('window:onbeforeunload', ['$event'])
  beforeunloadHandler(event) {
      localStorage.clear();
  }

  route = environment.ROUTE_PATIENT;
  orderManager = environment.ROUTE_ORDER_MANAGER;
  preagendamiento: string;
  droguerias: string;
  progress = 16.6;

  public vulnerable: PatientVulnerable;
  public eps: Eps;
  sessionSSO: JwUser;
  documentSSO: string;
  modalMessage: string;
  public parameter: Parameter;
  enabledContinue: boolean = true;
  enableCloseSession: boolean = false;
  idModal = "home";
  assetsHomeImages: any = [];
  assetsHomeText: any = [];
  portalEps: any = [];

  ALERT_MESSAGE_HOME_PORTAL: string = '0';
  MESSAGE_HOME_COOMEVA: string = '0';


  public carouselData: Carousel[] = CAROUSEL_DATA_ITEMS;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getPatient$ = this._behaviorSubjectService.getPatient();
  getEps$ = this._behaviorSubjectService.getEps();
  getElectronicFormula = this._behaviorSubjectService.getElectronicFormula();
  getAttachedFormula = this._behaviorSubjectService.getAttachedFormula();
  getAttachedFormulaSelected = this._behaviorSubjectService.getAttachedFormulaSelected();
  getSelectAddress = this._behaviorSubjectService.getSelectAddress();
  getAddress = this._behaviorSubjectService.getAddress();
  getContact = this._behaviorSubjectService.getContact();
  getAdresses = this._behaviorSubjectService.getAddresses();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
  getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();

  constructor(
    private _behaviorSubjectService: BehaviorSubjectService,
    private _auth: AuthService,
    private _localStorage: LocalStorageService,
    private _patientService: PatientService,
    private _epsService: EpsService,
    public _jwtService: JwtService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _parameterService: ParameterService,
    private _recaptchaV3Service: ReCaptchaV3Service,
    private _modal: ModalService,
    private _yiiService: ContentManagerService,
    private sessionStorageService: SessionStorageService
  ) {


    this.preagendamiento = environment.PREAGENDAMIENTO;
    this.droguerias = environment.DROGUERIAS_COLSUBSIDIO;
    this._localStorage.removeItem('enableSchedule');
    this.getParameterMessage(environment.ALERT_MESSAGE_HOME_PORTAL);

    //this.getUserSSO();
  }

  ngOnInit(): void {
    this.loadContentHome();
    this.nextSubject();
    this.tagUrl();
    this.getParameterKey(environment.KEY_WHITE_LIST_PORTAL);


    this._recaptchaV3Service.execute('ingreso')
      .subscribe((token) => {
        if (token.length) {
          return true
        } else {
          return false
        }
      });

    localStorage.setItem('editar', '');
  }

  nextSubject() {
    this.getPatientVulnerable$.next(null);
    this.getPatient$.next(null);
    this.getEps$.next(null);
    this.getElectronicFormula.next([]);
    this.getAttachedFormula.next([]);
    this.getAttachedFormulaSelected.next([]);
    this.getSelectAddress.next(null);
    this.getAddress.next(null);
    this.getContact.next(null);
    this.getAdresses.next(null);
    this.getAttachedFormulaNoPbs$.next(null);
    this.getAttachedFormulaNoPbsPreload$.next([]);
  }

  public tagRequestMedications() {
    dataLayer.push({
      'event': 'ga_event_botonservicios',
      'category': 'Home',
      'action': 'Clic-boton',
      'label': 'Solicitar medicamentos',
      'UES': 'Medicamentos'
    });
  }


  public tagOrderManager() {
    if(this.isLoggedCIAM()){
      dataLayer.push({
        'event': 'ga_event_botonservicios',
        'category': 'Home',
        'action': 'Clic-boton',
        'label': 'Gestionar solicitudes',
        'UES': 'Medicamentos'
      });
    }else{
      alert('Debe iniciar sesión para acceder a esta opción.');
    }

  }


  public tagPrescheduleshift() {
    dataLayer.push({
      'event': 'ga_event_botonservicios',
      'category': 'Home',
      'action': 'Clic-boton',
      'label': 'Agendar un turno',
      'UES': 'Medicamentos'
    });
  }

  public tagBuyMedications() {
    dataLayer.push({
      'event': 'ga_event_botonservicios',
      'category': 'Home',
      'action': 'Clic-boton',
      'label': 'Comprar medicamentos',
      'UES': 'Medicamentos'
    });
  }


  public tagUrl() {
    dataLayer.push({
      'event': 'ga_virtual_Page',
      'titleVp': 'Selección',
      'urlVp': '/vp/seleccion'
    });
  }



  async logout() {
    this._auth.logout(this._localStorage.parseItem<JwUser>(AppConfig.TOKEN_NAME_JTWT()).customer.tokenId);
  }



  async logoutWhitOutRedirect() {
    this._auth.logoutWhitOutRedirect(this._localStorage.parseItem<JwUser>(AppConfig.TOKEN_NAME_JTWT()).customer.tokenId);
  }


  public patientVulnerable() {
    this._patientService.getPatientVulnerable(this.documentSSO, this.sessionSSO.customer.documentNumber).subscribe(
      response => {
        if (response.pacientes) {
          this.vulnerable = response.pacientes[0];
          this.vulnerable.domicileApply = 0;
          this.epsNit();
          this.getPatientVulnerable$.next(this.vulnerable);
        } else {

          this.route = '/eps';
          //   this.setVulnerable();

        }
      }
    );
  }

  public epsNit() {
    this._epsService.getEpsNit(this.vulnerable.eps).subscribe(
      response => {
        this.eps = response.eps[0];
        if (this.eps.nit == '800088702') {
          this._router.navigate(['/redireccion-sura'], {
            relativeTo: this._route
          });

          this.logoutWhitOutRedirect();

        } else {
          this.getEps$.next(this.eps);
        }
      }
    );
  }
  /*
    getUserSSO() {
      this.sessionSSO = this._jwtService.getJwtUserSSO();
      this._patientService.getTypeDocumentsById('', this.sessionSSO.customer.documentType, 'SSO').subscribe(res => {
        this.documentSSO = res.TipoDocumento[0].id;
        this.patientVulnerable();
      });
    }

    public setVulnerable() {
      let objVulnerable: PatientVulnerable = {
        tipoIdentificacion: this.documentSSO,
        identificacion: this.sessionSSO.customer.documentNumber,
        nombres: this.sessionSSO.customer.firstName,
        apellidos: this.sessionSSO.customer.lastName,
        eps: '',
        correoElectronico: this.sessionSSO.customer.mail,
        celular: this.sessionSSO.customer.telephoneNumber
      }
      this.vulnerable = objVulnerable;
      this.getPatientVulnerable$.next(this.vulnerable);
    }
    */


  getParameterKey(key: string) {
    this._parameterService.getParameters(key).subscribe(
      response => {
        this.parameter = response.parametro;
      }

    );
  }

  getParameterMessage(key: string) {
    this._parameterService.getParameters(key).subscribe(
      response => {
        if (response.parametro != null) {
          switch (key) {
            case environment.ALERT_MESSAGE_HOME_PORTAL:
              this.ALERT_MESSAGE_HOME_PORTAL = response.parametro.valor;
              break;
          }
          this.loadModal();
        }
      }

    );

  }

  behind() {
    this.enabledContinue = true;
  }

  redirectToFormula() {
    if (this.parameter != null && this.parameter.valor == '1') {
      this.enabledContinue = false;
    } else {
      this.tagRequestMedications();
      this._router.navigate([this.route], {
        relativeTo: this._route
      });
    }
  }

  validateSSOJWT(): boolean {
    let varSSO = this._jwtService.getJwtUserSSO();
    if (varSSO != null && varSSO != undefined) {
      return true;
    } else {
      return false;
    }
  };


  loadModal() {
    if (this.ALERT_MESSAGE_HOME_PORTAL == '1') {
      setTimeout(() => {
        this._modal.openModal(this.idModal);
      }, 1000);
    }
  }

  loadContentHome() {

    this.assetsHomeText;
    this.assetsHomeImages;

    if (this.assetsHomeText == null || this.assetsHomeImages == null || this.assetsHomeText.length == 0 || this.assetsHomeImages.length == 0 ){
      this.assetsHomeText = [];
      this.assetsHomeImages = [];
      this._yiiService.getContentYiiImagesByGroup(IMAGES_PORTAL).subscribe(res => {
        this.assetsHomeImages = res;
        this._yiiService.getContentYiiMessageByIdNameArray(MESSAGES).subscribe(res => {
          this.assetsHomeText.push({
            id: res.id,
            content: res.content,
            name: res.name
          })
          this._localStorage.saveWithExpiration('assetsText',  this.assetsHomeText );

        });
        this._localStorage.saveWithExpiration('assetsImages', this.assetsHomeImages);
        this.getEpsFromAssetsArray(this.assetsHomeImages);
      })
    }else{
      this.getEpsFromAssetsArray(this.assetsHomeImages);
    }
  }

getEpsFromAssetsArray(array: any []){
  if(array != null && array.length > 0){
    this.portalEps = array.filter(item => item.name.includes('EPS'));

  }
}

getKeyText(key:string): string{
  if(this.assetsHomeText != null && this.assetsHomeText.length > 0){
    let item = this.assetsHomeText.find(element => element.name == key);
    return item.content;
  }
  return '';
}

validExpirationLocalStorageAssets(key: string){
  let data = this._localStorage.findWithExpiration(key);
  if(data != null){
    return data;
  }else{
    return [];
  }

}

isLoggedCIAM(): boolean {
  let dataSession= this.sessionStorageService.getItemSessionStorage("sso_persona");
  if(dataSession){
    return true;
  }else{
    return false;
  }

}


}
