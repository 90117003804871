import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { environment } from 'src/app/configs/environment';
import { map, mergeMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentManagerService {

  constructor(
    private _http: HttpService
  ) { }

  public getContentYiiMessageByIdName(name_id: string) {
    const payload = new HttpParams()
      .set('name_id', name_id);
    return this._http.post<any>(`${environment.API_YII}/restmessage/getbynameid`, payload).pipe(map(res => {
      let data: any = {
        id: res.id,
        content: res.content,
        name: res.name_id
      }
      return data;
    }));
  }

  public getContentYiiImagesByIdName(name_id: string) {
    const payload = new HttpParams()
      .set('name_id', name_id);
    return this._http.post<any>(`${environment.API_YII}/restimages/getbynameid`, payload).pipe(map(res => res));
  }

  public getContentYiiImagesByGroup(group: string) {
    const payload = new HttpParams()
      .set('group', group);
    return this._http.post<any>(`${environment.API_YII}/restimages/getbygroup`, payload).pipe(map(res => {
      let arrayData: any = [];
      res.sort((a, b) => this.compare(a, b)).forEach(element => {
        arrayData.push({
          id: element.id,
          content: element.src,
          name: element.name,
          description: element.description
        })
      });
      return arrayData;
    }));
  }




  compare(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }

  public getContentYiiMessageByIdNameArray(name_id: string[]) {

    return from(name_id).pipe(
      mergeMap(id => <Observable<any>>this.getContentYiiMessageByIdName(id))
    );
  }




}
