export class RoutingPath {
    public static appRouting = {
      pages: {
        token: {
          path: 'iPlanetDirectoryPro/:idToken'
        }
      }
    }
  
  }
  