import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { environment } from 'src/app/configs/environment';
import { Router, ActivatedRoute } from '@angular/router';

import { AddressService } from 'src/app/core/services/address/address.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { PatientService } from 'src/app/core/services/patient/patient.service';

import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { Department } from 'src/app/core/models/department/department';
import { City } from 'src/app/core/models/city/city';
import { Address } from 'src/app/core/models/address/address';
import { Abbreviation } from 'src/app/core/models/abbreviation/abbreviation';

declare let dataLayer;

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressRecurrentComponent implements OnInit {

	icon = './assets/icons/arrow-maps.svg';
	title = 'Ingresa la dirección';
	subtitle = 'Donde enviaremos tus medicamentos.';
	idPatient: string;
	public idAddress: any;
	progress = 49.8;
	messageDomicile = environment.MESSAGE_ADDRESS_RECURRENT;

	addressRecurrentForm: FormGroup;
	newAddress: boolean;
	updaAddress: Address;
	activate: boolean = false;
	disabled: boolean = false;
	enableTimeZone: boolean = false;

	/*VARIBALES SERVICES*/
	departments: Department[] = [];
	cities: City[] = [];
	abreviatura: Abbreviation[] = [];
	address: Address[] = [];
	order: any;

	/*VARIABLES BEHAVIOR SUBJECT*/
	getEps$ = this._behaviorSubjectService.getEps();
	getAddress$ = this._behaviorSubjectService.getAddress();
	getAddresses$ = this._behaviorSubjectService.getAddresses();
	getSelectAddress$ = this._behaviorSubjectService.getSelectAddress();
	getResumenRecurrent$ = this._behaviorSubjectService.getResumenRecurrent();
	getSubjectRecurrent$ = this._behaviorSubjectService.getSubjectRecurrent();


	public eps: Eps;
	public selectedAddress: any;
	public addresses: any;
	public subjectRecurrent: any;
	public resumenRecurrent: any;

	constructor(
		private _formBuilder: FormBuilder,
		private _router: Router,
		private _route: ActivatedRoute,
		private _addressService: AddressService,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _CHANGEDECTOR: ChangeDetectorRef,
		private _patientService: PatientService,
		private _location: Location,
	) {
		this.addressRecurrentForm = this._formBuilder.group({
			addressNomenclature: ['', [Validators.required]],
			numberNomenclature: ['', [Validators.required]],
			numberOne: ['', [Validators.required]],
			numberTwo: ['', [Validators.required]],
			name: ['', [Validators.required]],
			district: [''],
			comments: ['']
		});

		/*SUBSCRIBE BEHAVIOR SUBJECT */
		this.eps = this.getEps$.getValue();
		this.selectedAddress = this.getSelectAddress$.getValue();
		this.addresses = this.getAddresses$.getValue();
		this._CHANGEDECTOR.markForCheck();
		this.getEnableTimeZone();
		this.subjectRecurrent = this.getSubjectRecurrent$;
		this.resumenRecurrent = this.getResumenRecurrent$;
	}

	ngOnInit(): void {
		this.tagUrl();

		this.selectAddress();
		this._CHANGEDECTOR.markForCheck();

		this.order = this.getAddress$.getValue();

		if (this.order) {
			this.setValue();
		}

	}

	public setValue() {
		this.addressRecurrentForm.setValue({
			addressNomenclature: this.order.addressNomenclature,
			numberNomenclature: this.order.numberNomenclature,
			numberOne: this.order.numberOne,
			numberTwo: this.order.numberTwo,
			name: this.order.name,
			district: this.order.district,
			comments: this.order.comments,
		});

		this.idAddress = this.order.idAddress;
	}

	public ngAfterContentChecked(): void {
		this._CHANGEDECTOR.markForCheck();
	}

	public getPatient() {
		this._patientService.getPatient(this.subjectRecurrent.tipoIdentificacion, this.subjectRecurrent.identificacion).subscribe(
			response => {
				this.idPatient = response.patient[0].id;
			}
		);
	}

	public selectAddress() {
		this.getAbreviatura();
		this.getPatient();

		if (this.selectedAddress != null && this.selectedAddress.address == 'nueva') {
			this.resetValue();
		} else if (this.addresses.newAddress == true) {
			if (this.addresses.newAddress) {
				this.resetValue();
			}
		} else {
			this.addresses.addresses.forEach(e => {
				if (e.idDireccion == this.selectedAddress.address) {
					this.updaAddress = e;
					this.addressRecurrentForm.get('name').setValue(this.updaAddress.nombre);
					this.addressRecurrentForm.get('district').setValue(this.updaAddress.barrio);
					this.addressRecurrentForm.get('comments').setValue(this.updaAddress.comentarios);

					let separationNumeral = this.updaAddress.direccion.split('#');
					let separationSpaces = separationNumeral[0].split(' ');
					let abbreviationNumber = ''
					var i;
					for (i = 1; i < separationSpaces.length; i++) {
						abbreviationNumber = abbreviationNumber + ' ' + separationSpaces[i];
					}
					let separationHyphen = separationNumeral[1].split('-')

					let secondPartAddress = ''
					var i;
					for (i = 1; i < separationHyphen.length; i++) {
						secondPartAddress = secondPartAddress + ' ' + separationHyphen[i];
					}

					this.addressRecurrentForm.get('addressNomenclature').setValue(separationSpaces[0]);
					this.addressRecurrentForm.get('numberNomenclature').setValue(abbreviationNumber);
					this.addressRecurrentForm.get('numberOne').setValue(separationHyphen[0]);
					this.addressRecurrentForm.get('numberTwo').setValue(secondPartAddress);

					this.idAddress = this.updaAddress.idDireccion;
				}
			});
		}
	}

	public resetValue() {
		this.addressRecurrentForm.get('addressNomenclature').setValidators([Validators.required]);
		this.addressRecurrentForm.get('numberNomenclature').setValidators([Validators.required]);
		this.addressRecurrentForm.get('numberOne').setValidators([Validators.required]);
		this.addressRecurrentForm.get('numberTwo').setValidators([Validators.required]);
		this.addressRecurrentForm.get('name').setValidators([Validators.required]);

		this.addressRecurrentForm.get('addressNomenclature').setValue('');
		this.addressRecurrentForm.get('numberNomenclature').setValue('');
		this.addressRecurrentForm.get('numberOne').setValue('');
		this.addressRecurrentForm.get('numberTwo').setValue('');
		this.addressRecurrentForm.get('name').setValue('');
		this.addressRecurrentForm.get('district').setValue('');
		this.addressRecurrentForm.get('comments').setValue('');
	}

	public getAbreviatura() {
		this._addressService.getAbreviatura().subscribe(
			response => {
				this.abreviatura = response.nomenclaturas;
			}
		);
	}

	private createAddress() {
		const addAddress = `${this.addressRecurrentForm.get('addressNomenclature').value} ${this.addressRecurrentForm.get('numberNomenclature').value} #${this.addressRecurrentForm.get('numberOne').value} - ${this.addressRecurrentForm.get('numberTwo').value}`;

		let address = {
			direccion: addAddress,
			nombre: this.addressRecurrentForm.get('name').value,
			idPaciente: this.idPatient,
			tipoIdentificacion: this.subjectRecurrent.tipoIdentificacion,
			identificacion: this.subjectRecurrent.identificacion,
			idCiudad: this.addresses.idCity,
			comentarios: this.addressRecurrentForm.get('comments').value,
			canal: environment.CHANNEL,
			disponibilidad: '1',
			barrio: this.addressRecurrentForm.get('district').value,
			tipo: environment.TYPE,
			coordenadas: {
				latitud: null,
				longitud: null
			}
		}

		this._addressService.createAddress(address).subscribe(
			response => {
				this.idAddress = response['idDireccion'];
				this.resumenRecurrent.idDireccion = this.idAddress;
				this.resumenRecurrent.direccionDomicilio = this.addressRecurrentForm.get('name').value;
				this.resumenRecurrent.direccion= addAddress;
				this.resumenRecurrent.comentarios=this.addressRecurrentForm.get('comments').value;
				this.subjectRecurrent.idDireccion = this.idAddress;
				this.getSubjectRecurrent$.next(this.subjectRecurrent);
				this.getResumenRecurrent$.next(this.resumenRecurrent);
				setTimeout(() => {
					this.getFormData();
					this.getAddress$.next(this.order);
				}, 10);

				setTimeout(() => {
					this._router.navigate(['/formulario-recurrente/validar'], {
						relativeTo: this._route
					});
				}, 30);
			}
		);

		this.activate = true
	}

	private updateAddress() {
		const addAddress = `${this.addressRecurrentForm.get('addressNomenclature').value} ${this.addressRecurrentForm.get('numberNomenclature').value} #${this.addressRecurrentForm.get('numberOne').value} - ${this.addressRecurrentForm.get('numberTwo').value}`;
		let address = {
			idDireccion: this.idAddress,
			direccion: addAddress,
			nombre: this.addressRecurrentForm.get('name').value,
			idCiudad: this.addresses.idCity,
			comentarios: this.addressRecurrentForm.get('comments').value,
			barrio: this.addressRecurrentForm.get('district').value,
			canal: environment.CHANNEL,
			tipo: environment.TYPE,
			coordenadas: {
				latitud: null,
				longitud: null
			}
		};

		this._addressService.updateAddress(address).subscribe(
			response => {
				this.idAddress = this.idAddress;

				this.resumenRecurrent.idDireccion = this.idAddress;
				this.resumenRecurrent.direccionDomicilio = this.addressRecurrentForm.get('name').value;
				this.subjectRecurrent.idDireccion = this.idAddress;

				this.getSubjectRecurrent$.next(this.subjectRecurrent);
				this.getResumenRecurrent$.next(this.resumenRecurrent);

				setTimeout(() => {
					this.getFormData();
					this.getAddress$.next(this.order);
				}, 10);

				setTimeout(() => {

					this._router.navigate(['/formulario-recurrente/validar'], {
						relativeTo: this._route
					});

				}, 30);
			}
		);

		this.activate = true
	}

	public getFormData() {
		this.order = {
			idAddress: this.idAddress,
			name: this.addressRecurrentForm.get('name').value,
			idCity: this.addresses.idCity,
			comments: this.addressRecurrentForm.get('comments').value,
			channel: environment.CHANNEL,
			city: this.addresses.city,
			idDepartment: this.addresses.idDepartment,
			department: this.addresses.department,
			addressNomenclature: this.addressRecurrentForm.get('addressNomenclature').value,
			numberNomenclature: this.addressRecurrentForm.get('numberNomenclature').value,
			numberOne: this.addressRecurrentForm.get('numberOne').value,
			numberTwo: this.addressRecurrentForm.get('numberTwo').value,
			district: this.addressRecurrentForm.get('district').value,
		}
	}

	public toReturn() {
		this._location.back();
	}

	/*INFORMATION BETWEEN COMPONENTS BEHAVIOR*/
	nextSubject() {
		const addAddress = `${this.addressRecurrentForm.get('addressNomenclature').value} ${this.addressRecurrentForm.get('numberNomenclature').value} #${this.addressRecurrentForm.get('numberOne').value} - ${this.addressRecurrentForm.get('numberTwo').value}`;
		this.tagContinue(this.subjectRecurrent.identificacion);
		localStorage.setItem('direccion', addAddress);		

		if (this.selectedAddress != null) {
			if (this.selectedAddress.newAddress && localStorage.getItem('updateA') == 'false') {
				this.createAddress();
				localStorage.setItem('updateA', 'true');
			} else {
				this.updateAddress();
			}
		} else if (this.addresses.newAddress == true) {
			if (this.addresses.newAddress && localStorage.getItem('updateA') == 'false') {
				this.createAddress();
				localStorage.setItem('updateA', 'true');
			} else {
				this.updateAddress();
			}
		}
	}

	getEnableTimeZone() {
		if (localStorage.getItem('enableSchedule') == 'true') {
			this.enableTimeZone = true;
		} else {
			this.enableTimeZone = false;
		}
	}



	public tagUrl() {
		dataLayer.push({
			'event': 'ga_virtual_Page',
			'titleVp': 'Ingresar Direccion',
			'urlVp': '/vp/formulario-recurrente/direccion'
		});
	}

	public tagContinue(identification : string) {
		dataLayer.push({
			'event': 'ga_event_seldireccion',
			'category': 'Pedidos Recurrentes',
			'action': 'Donde enviaremos tus medicamentos',
			'label': 'Continuar',
			'UES': 'Medicamentos',
			'Identificacion': identification
		});
	}
}
