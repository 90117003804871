<div class="ctn-title">
  <img src="{{icon}}" alt="icon">

  <h1>
    {{title}}
  </h1>

  <h2>
    {{subtitle}}
  </h2>
</div>