import { Component } from '@angular/core';
import { environment } from './configs/environment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionStorageService } from './core/services/session-storage/local-storage.service';
import { LocalStorageService } from './core/services/local-storage/local-storage.service';
//import { filter } from 'rxjs/operators';
//declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Portal medicamentos';

  constructor(private router: Router, private _location: Location, private sessionStorageService: SessionStorageService, private localStorageService: LocalStorageService) { }

  get apiKey(){
    return environment.CIAM_API_KEY;
  }

  get typeMarketing(){
    return environment.CIAM_TYPE_MARKETING;
  }

  get userData(){
    return environment.CIAM_VAR_USER_DATA;
  }

  get screenPrefix(){
    return environment.CIAM_SCREEN_PREFIX;
  }

  get cdnUrl(){
    return environment.CIAM_CDN_URL;
  }

  get countAuth(){
    return environment.CIAM_COUNT_AUTH;
  }

  get ciamLoginUrl(){
    return environment.CIAM_LOGIN_URL;
  }

  redirectUrl(redirect: boolean) {
    console.log('redirect', redirect);
    const baseUrl = window.location.origin;
    if (redirect) {
      const redirectPath = '/gestionar-pedido';
      const fullRedirectUrl = baseUrl + environment.CONTEXT + redirectPath;
      const redirect = this.localStorageService.getItemString('medicamentos_redirect');
      // Compara la URL actual con la URL de redirección
      if (window.location.href !== fullRedirectUrl && this.isLoggedCIAM() && redirect !== '1' ) {
          this.localStorageService.setItem('medicamentos_redirect', '1');
          window.location.href = fullRedirectUrl;
        }
      }else{
        const logOutUrl = baseUrl + environment.CONTEXT + '/inicio';
        const checkUrl = baseUrl + environment.CONTEXT + '/';
        if (window.location.href === checkUrl){
          window.location.href = logOutUrl;
        }
      }
    }


  isLoggedCIAM(): boolean {
    let dataSession= this.sessionStorageService.getItemSessionStorage("sso_persona");
    if(dataSession){
      return true;
    }else{
      return false;
    }

  }

}
