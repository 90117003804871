import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/app/configs/environment';
import { SessionStorageService } from '../../services/session-storage/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class LoggedCIAMInGuard implements CanActivate, CanActivateChild {
  constructor(
                private sessionStorageService: SessionStorageService,
                private route: Router
            ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.isLoggedCIAM()) {
      return true;
    }
   // this.route.navigate([environment.CIAM_REDIRECT_HOME]);
    return false;

  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next,state);
  }

  isLoggedCIAM(): boolean {
    let dataSession= this.sessionStorageService.getItemSessionStorage("sso_persona");
    if(dataSession){
      return true;
    }else{
      const baseUrl = window.location.origin;
      const redirectPath = '/gestionar-pedido';
      const fullRedirectUrl = baseUrl + redirectPath;
      const homeRedirectUrl = baseUrl + '/inicio';
      if(window.location.href === fullRedirectUrl){
        window.location.href = homeRedirectUrl;
        alert('Debe iniciar sesión para acceder a esta opción.');
      }

      return false;
    }

  }



}

