import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { environment } from 'src/app/configs/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EpsService {

  constructor(
    private _HTTP: HttpService
  ) { }

  public getEps(): Observable<any> {
    return this._HTTP.get<any>(`${environment.API_EPS}/eps/listaEps?type=${environment.TYPE}`)
    .pipe(
      map(
        response => response
      )
    );
  }

  public getEpsNit(nit): Observable<any> {
    return this._HTTP.get<any>(`${environment.API_EPS}/eps/pornit?nit=${nit}&tipo=${environment.TYPE}`)
    .pipe(
      map(
        response => response
      )
    );
  }

  getImagesById(params: any){
    const headers = new HttpHeaders().set(
    "ngrok-skip-browser-warning",
    "any value"
  );

    return this._HTTP.get<any>(`${environment.API_EPS}/eps/imagen?idsEps=${params}`, {headers})
      .pipe(
        map(
          respose => respose
        )
      );
  }

}
