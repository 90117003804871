<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div class="ctn-fields">
  <div class="d-flex f-wrap">
    <ng-container *ngFor="let formula of formulas; let i = index">
      <div class="card-f">
        <app-electronic-formula [last]="i" [formula]="formula" (sendFormulaSelected)="sendFormulaSelected($event)" (sendFormulaDeselected)="sendFormulaDeselected($event)"></app-electronic-formula>
      </div>
    </ng-container>
  </div>

  <!--<div class="d-flex f-wrap">
    <ng-container *ngFor="let formula of formulasSea; let i = index">
      <div class="card-f">
        <app-search-formula [last]="i" [formula]="formula" (sendFormulaIndex)="sendFileDeselected($event)"></app-search-formula>
      </div>
    </ng-container>
  </div>-->
  <div class="circle-mobile metricas-atencion" data-actividad="atenciontelefono" (click)="openModal('mobile')" *ngIf="showHelpButton">
    <p class="text">  
    <img src="./assets/icons/formula/phone-outgoing.svg" alt="icon">
    Ayuda
  </p>
  </div>
  <div class="ctn-input b-bottom p-bottom d-flex f-wrap">
    <app-card class="list" *ngFor="let file of files; let i = index">
      <form [formGroup]="attachedForm">
        <label for="{{i}}">
          <input type="checkbox" id="{{i}}" name="{{i}}" value="{{i}}" (change)="sendFileDeselected($event, file)" [checked]="file.check">
    
          <div>
            <div class="d-flex space-between p-20 info-formula" id="{{i}}info">
              <div class="ctn-formula w-100">
                <div class="info-fo-a">
                  <div class="d-flex space-between a-items-center">
                    <img src="./assets/icons/formula/camera-formula.svg" alt="foto">
                    <button (click)="deleteFile(i, file)">
                      <img src="./assets/icons/x.svg" alt="Eliminar">
                    </button>
                  </div>
      
                  <div class="n-formula">
                    Fórmula número {{file.number}}
                  </div>
            
                  <div>
                    <span class="n-authorization">
                      No de autorización {{file.authorization}}
                    </span>
                  </div>
                </div>
  
                <div class="ctn-images">
                  <ng-container *ngFor="let f of file.files; let ia = index">
                    <button class="file-display" (click)="modal(f.type, f.src, f.name)">
                      <div *ngIf="f.type != 'application/pdf'">
                        <img [src]="f.src" alt="image">
                      </div>

                      <div *ngIf="f.type == 'application/pdf'">
                        <div class="f-16 name-pdf">
                          {{f.name}}
                        </div>
                      </div>
                    </button>
                  </ng-container>
                </div>
              </div>
    
              <span class="check c-blue formula-image">
                <img src="./assets/icons/check.svg" alt="check">
              </span>
            </div>
  
            <label class="tab-label p-10" for="{{i}}fa">
              <input type="checkbox" id="{{i}}fa" name="{{i}}fa" class="see-more" formControlName="see" (change)="seeChange(i)">

              <img src="{{imageSee}}" alt="see" id="see-{{i}}">
            </label>
          </div>
        </label>
      </form>
    </app-card>
  </div>

  <div *ngIf="maxSize == true && noPbsApply == true" class="c-red text-center">
    Debido a que cuentas con formulas NO PBS o MIPRES, para poder continuar debes eliminar algunas formulas adjuntas, tamaño máximo {{maxSizeFile}}
  </div>

  <div class="p-20-v b-bottom" *ngIf="!sectionImage || showLoadImage">
    <div class="d-flex f-wrap space-between">
      <div class="question-search">
        <img src="./assets/icons/formula/agregar-formula.svg" alt="img">
  
        ¿No encuentras la fórmulas que necesitas? Adjunta la foto de tus fórmulas o autorizaciones.
      </div>
  
      <div class="ctn-buton-ad">
        <button for="searchOption" class="searchOption" (click)="activateSearch()" [disabled]="sectionForm " [ngClass]="{'activate-search-disable': sectionForm}" >
          <img src="./assets/icons/formula/zoom-in.svg" alt="adjuntar">
        </button>
      </div>
    </div>

    <div *ngIf="maxSize == true" class="c-red text-center">
      El total de los archivos no puede superar las {{maxSizeFile}}
    </div>
    

    <form [formGroup]="formulaForm" *ngIf="sectionForm">
      <div *ngIf="authorizationRadio">
        <div class="d-flex a-items-center m-12-v">
          ¿Tienes vigente un código de autorización para la entrega de tus medicamentos?

          <div class="check p-relative">
            <button (click)="despliegue()">
              <img src="./assets/icons/formula/tooltip.svg" class="des" alt="Info">
            </button>

            <div class="despliegue" [ngClass]="showDes ? 'd-block' : 'd-none'">
              <div class="d-flex space-between">
                <p>
                  El número de autorización lo encuentras aquí
                </p>

                <button (click)="close()" class="close">
                  <img src="./assets/icons/x.svg" alt="close">
                </button>
              </div>
              <div>
                <img style="width: 100%;" src="{{image}}" alt="eps" class="img-eps">
              </div>
            </div>
          </div>
        </div>

        
        <div class="d-flex f-wrap space-between">
          <app-card class="list">
            <label for="si">
              <!--(change)="activar(true)"-->
              <input type="radio" id="si" name="authorization" formControlName="authorization" value="si" (change)="activateFieldsAuth(true)">
    
              <div class="d-flex space-between p-20">
                Si
    
                <span class="check c-blue">
                  <img src="./assets/icons/check.svg" alt="check">
                </span>
              </div>
            </label>
          </app-card>
    
          <app-card class="list">
            <label for="no">
              <!--(change)="activar(false)"-->
              <input type="radio" id="no" name="authorization" formControlName="authorization" value="no" (change)="activateFieldsAuth(false)">
    
              <div class="d-flex space-between p-20">
                No
    
                <span class="check c-blue">
                  <img src="./assets/icons/check.svg" alt="check">
                </span>
              </div>
            </label>
          </app-card>
        </div>
      </div>

      <div class="" *ngIf="fieldsAuth">
        <div class="d-flex f-wrap space-between p-20-v">
          <div class="ctn-data">
            <label for="numberFormula">
              Escribe el número de fórmula
    
              <input (keyup)="onKey($event)" class="i-data formula-attached" type="text" name="numberFormula" id="numberFormula" formControlName="numberFormula" [ngClass]="{'error': formulaForm.get('numberFormula').invalid && formulaForm.get('numberFormula').touched}" >
            </label>
    
            <div class="text-invalid">
              <app-form-errors *ngIf="formulaForm.get('numberFormula').touched"
                              [errors]="formulaForm.get('numberFormula').errors">
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>
    
          <div class="ctn-data" *ngIf="showInput">
            <label for="numberAuthorization">
              Escribe el código de autorización
    
              <input (keyup)="onKey($event)" class="i-data formula-attached" type="text" name="numberAuthorization" id="numberAuthorization" formControlName="numberAuthorization" [ngClass]="{'error':  formulaForm.get('numberAuthorization').invalid && formulaForm.get('numberAuthorization').touched}">
            </label>
            <div class="text-invalid">
              <app-form-errors *ngIf="formulaForm.get('numberAuthorization').touched"
                              [errors]="formulaForm.get('numberAuthorization').errors">
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>
        </div>

        <div *ngIf="duplicateNumber == true" class="c-red">
          {{message}}
        </div>

        <div class="d-flex f-wrap space-evenly a-items-center">
          <div class="ctn-data d-none">
            <button class="search" [disabled]="!formulaForm.valid" [ngClass]="{'search-disable': !formulaForm.valid}" (click)="searchFormula()">
              Buscar
            </button>
          </div>
    
          <div class="ctn-data">
            <p class="c-blue text-center">
              <!--¿Problemas con los datos?-->

              <button class="f-16 search" [disabled]="!formulaForm.valid" [ngClass]="{'search-disable': !formulaForm.valid}" (click)="loadImage()">
                Adjunta una foto
              </button>
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="sectionImage">
    <app-attached-formula (showLoadImage)="attachedFormulaLoaded($event)"></app-attached-formula>
  </div>

  <div class="d-flex f-wrap space-around a-items-center p-20 warning">
    <div>
      <img src="./assets/icons/formula/megafono.svg" alt="">
    </div>
  
    <div>
      <li>Recuerda que tus fórmulas deben estar vigentes al momento de la entrega</li>
      <li>Recuerda que los medicamentos que requieren autorización son los denominados: NO PBS (NO POS) y/o ALTO COSTO</li>
    </div>

  </div>

  <div class="d-flex space-between buttons">
    <button (click)="toReturn()" class="btn-return">
      <img src="./assets/icons/navegacion/atras.svg" alt="icon">
    </button>

    <!--*ngIf="!buttonContinueFoE || enableContinueAttachment && !disabledSelectedFile || enableContinueAttachment && (getFormulaAS$| async)?.length == 0"-->
    <div *ngIf="enableContinueAttachment == false">
      <button
      class="btn-continue-one"
      *ngIf="!buttonContinueFoE && !disabledSelectedFile && (getFormulaAS$| async)?.length == 0"
      type="button"
      >
        <img src="./assets/icons/navegacion/boton-continuar-deshabilitado.svg" alt="icon">
      </button>

      <!---->

      <button
        class="btn-continue-two"
        type="button"
        (click)="nextSubject()"
        *ngIf="buttonContinueFoE || disabledSelectedFile || (getFormulaAS$| async)?.length != 0"
      >
        <div class="btn-next"></div>
      </button>
    </div>

    <div *ngIf="enableContinueAttachment == true">
      <button class="btn-continue-one" type="button">
        <img src="./assets/icons/navegacion/boton-continuar-deshabilitado.svg" alt="icon">
      </button>
    </div>

    <div class="circle-web metricas-atencion" data-actividad="atenciontelefono" (click)="openModal('web')" *ngIf="showHelpButton">
      <p class="text">¿Necesitas ayuda?  
      <img src="./assets/icons/formula/phone.svg" alt="icon">
    </p>
    </div>
  </div>
 
</div>
<app-modal-click-to-call [idModal]="idModalHelp" [modalType]="modalType" [telephone]="telephone"></app-modal-click-to-call>
<app-modal-images [idModal]="idModal" [image]="imageModal" [ext]="ext" [nameFile]="nameFile"></app-modal-images>