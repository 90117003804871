import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { environment } from 'src/app/configs/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  constructor(
    private _HTTP: HttpService
  ) { }

  public getParameters(llave) {
    return this._HTTP.get<any>(`${environment.API_PARAMETERS}/parametros/parametro?llave=${llave}`).pipe(map(res => res));
  }

  public validateStatusFastDelivery(idCity, idFranja, idDispensary) {  
    return this._HTTP.get<any>(`${environment.API_PARAMETERS}/parametros/validateFastDelivary?idCity=${idCity}&idFranja=${idFranja}&idDispensary=${idDispensary}`).pipe(map(res => res));
  }

}
