import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal/modal.service';

@Component({
  selector: 'app-modal-click-to-call',
  templateUrl: './modal-click-to-call.component.html',
  styleUrls: ['./modal-click-to-call.component.scss']
})
export class ModalClickToCallComponent implements OnInit {


  @Input() idModal: string;
  @Input() modalType: string;
  @Input() telephone: string;
  

  constructor(
    private _MODAL: ModalService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this._MODAL.closeModal(this.idModal);
  }

  validModalType(): boolean {
    if (this.modalType == 'web') {
      return true;
    } else {
      return false;
    }

  }


}
