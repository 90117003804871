
<div *ngIf="enabledContinue == true">
  <div class="ctn-start">
    <div class="d-flex space-between a-items-center header">
      <div class="p-10 icon-logo">
        <img src="./assets/icons/start/logo.svg" alt="Logo" />
      </div>
    </div>
  </div>
  <div class="ctn-services">

    <div class="content">

      <div class="ctn-title">
        <h1 class="d-flex j-center a-items-center title">
          <span> Te damos la bienvenida al Portal de Medicamentos </span>
        </h1>

        <h2 class="subtitle">Escoge lo que deseas hacer hoy</h2>
      </div>
      <div class="content__section">
        <div class="content-card">
          <div class="card-r">
            <a class="cursor-hand" (click)="redirectToFormula()">
              <app-card>
                <div class="card-start">
                  <div class="card-start__icon">
                    <img src="{{assetsHomeImages[8].content}}" alt="icon" class="d-block card-start-icon" />
                  </div>
                  <div class="card-start__info">
                    <span class="d-flex aling-items-center link-card">
                      {{getKeyText('PORTAL_SERVICIOS_PRIMER_TITULO')}}
                    </span>

                    <span class="d-block p-v-10 text-card">
                      {{getKeyText('PORTAL_SERVICIOS_PRIMER_TEXTO')}}
                    </span>
                  </div>
                  <div class="card-start__row">
                    <img src="./assets/icons/start/row.svg" alt="icon" class="card-start-row" />
                  </div>
                </div>
              </app-card>
            </a>
          </div>

          <div class="card-r card-middle">
            <a routerLink="{{ orderManager }}" (click)="tagOrderManager()">
              <app-card>
                <div class="card-start">
                  <div class="card-start__icon">
                    <img src="{{assetsHomeImages[9].content}}" alt="icon" class="d-block card-start-icon" />
                  </div>
                  <div class="card-start__info">
                    <span class="d-flex aling-items-center link-card">
                      {{getKeyText('PORTAL_SERVICIOS_SEGUNDO_TITULO')}}
                    </span>

                    <span class="d-block p-v-10 text-card">
                      {{getKeyText('PORTAL_SERVICIOS_SEGUNDO_TEXTO')}}
                    </span>
                  </div>
                  <div class="card-start__row">
                    <img src="./assets/icons/start/row.svg" alt="icon" class="card-start-row" />
                  </div>
                </div>
              </app-card>
            </a>
          </div>

          <div class="card-r card-middle">
            <a href="{{ preagendamiento }}" target="_blank" (click)="tagPrescheduleshift()">
              <app-card>
                <div class="card-start">
                  <div class="card-start__icon">
                    <img src="{{assetsHomeImages[10].content}}" alt="icon" class="d-block card-start-icon" />
                  </div>
                  <div class="card-start__info">
                    <span class="d-flex aling-items-center link-card">
                      {{getKeyText('PORTAL_SERVICIOS_TERCER_TITULO')}}
                    </span>

                    <span class="d-block p-v-10 text-card">
                      {{getKeyText('PORTAL_SERVICIOS_TERCER_TEXTO')}}
                    </span>
                  </div>
                  <div class="card-start__row">
                    <img src="./assets/icons/start/row.svg" alt="icon" class="card-start-row" />
                  </div>
                </div>
              </app-card>
            </a>
          </div>

          <div class="card-r card-last">
            <a href="{{ droguerias }}" target="_blank" (click)="tagBuyMedications()">
              <app-card>
                <div class="card-start">
                  <div class="card-start__icon">
                    <img src="{{assetsHomeImages[11].content}}" alt="icon" class="d-block card-start-icon" />
                  </div>
                  <div class="card-start__info">
                    <span class="d-flex aling-items-center link-card">
                      {{getKeyText('PORTAL_SERVICIOS_CUARTO_TITULO')}}
                    </span>

                    <span class="d-block p-v-10 text-card">
                      {{getKeyText('PORTAL_SERVICIOS_CUARTO_TEXTO')}}
                    </span>
                  </div>
                  <div class="card-start__row">
                    <img src="./assets/icons/start/row.svg" alt="icon" class="card-start-row" />
                  </div>
                </div>
              </app-card>
            </a>
          </div>
        </div>
      </div>
      <div class="content__slider">
        <a href="{{ assetsHomeImages[0].description }}" target="_blank">
              <img src="{{assetsHomeImages[0].content}}" alt="icon" class="d-block card-start-icon" />
        </a>
      </div>
      <div class="clearfix"></div>
      <div class="content__benefits">
        <div class="ctn-title">
          <h1 class="title">
            <span> Beneficios de utilizar el portal </span>
          </h1>
        </div>
        <div class="benefits_section_ppal">
          <div class="benefits_section">
            <div class="section_icon">
              <img src="{{assetsHomeImages[5].content}}" alt="icon" class="d-block card-start-icon" />
            </div>
            <div>
              <h3 class="benefits_section__title">{{getKeyText('PORTAL_PRIMER_TITULO')}}</h3>
              <p class="">
                {{getKeyText('PORTAL_PRIMER_TEXTO')}}
              </p>
            </div>

          </div>
          <div class="benefits_section__middle">
            <div class="section_icon">
              <img src="{{assetsHomeImages[6].content}}" alt="icon" class="d-block card-start-icon" />
            </div>
            <div>
              <h3 class="benefits_section__title">{{getKeyText('PORTAL_SEGUNDO_TITULO')}}</h3>
              <p class="">
                {{getKeyText('PORTAL_SEGUNDO_TEXTO')}}
              </p>
            </div>
          </div>
          <div class="benefits_section">
            <div class="section_icon">
              <img src="{{assetsHomeImages[7].content}}" alt="icon" class="d-block card-start-icon" />
            </div>
            <div>
              <h3 class="benefits_section__title">{{getKeyText('PORTAL_TERCER_TITULO')}}</h3>
              <p class="">
                {{getKeyText('PORTAL_TERCER_TEXTO')}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ctn-eps">
    <div class="content">
      <div class="content__info">
        <div class="eps-info">
          <div class="eps-info__text">
            <h2 class="title-info">
              <span class="d-block"> ¿A quién va dirigido? </span>
            </h2>
            <p class="text-info">
              A todos los usuarios, que se encuentren afiliados a las EPS y tengan convenio con Droguerías
              Colsubsidio.
            </p>
          </div>
          <div class="eps-info__icon">
            <div class="div-eps" *ngFor="let eps of portalEps; let i = index" [ngClass]="{'first-eps': portalEps.length  === 3 && i === 0,'last-eps': (portalEps.length - 1) === i && portalEps.length === 3}">
              <img src="{{eps.content}}" />
            </div>
          </div>
        </div>



        <div class="image-section">
          <a href="{{ assetsHomeImages[1].description }}" target="_blank">
                <img src="{{assetsHomeImages[1].content}}" alt="icon" class="d-block card-start-icon" />
          </a>
        </div>

      </div>
    </div>
  </div>


  <div class="gray-footer">
    <app-footer></app-footer>
  </div>
</div>
<div class="ctn-not-vulnerable" *ngIf="enabledContinue == false">
  <div class="ctn-not-vulnerable__info">
    <div>
      <img src="./assets/icons/warning.svg" alt="aviso" />
    </div>

    <div class="ctn-not-vulnerable__info__title">
      <div>El documento ingresado no cumple con los</div>

      <div>requisitos para realizar este proceso.</div>
    </div>

    <div class="ctn-not-vulnerable__info__parrafo">
      <div>Te invitamos a solicitar tus medicamentos a</div>

      <div>través de la app Medicamentos Colsubsidio.</div>
    </div>

    <div class="p-20">
      <button class="behind" (click)="behind()">Regresar</button>
    </div>
  </div>
</div>
<app-modal-home [idModal]="idModal"></app-modal-home>
