import { Component, OnInit } from '@angular/core';
import { Group } from 'src/app/core/models/order/order-group';
import { OrderService } from 'src/app/core/services/order/order.service';
import { Location } from '@angular/common';
import { JwUser } from 'src/app/core/models/jw-user/jw-user';
import { JwtService } from 'src/app/core/services/jwt.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderConfigurationService } from 'src/app/core/services/order-configuration/order-configuration.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { PatientService } from 'src/app/core/services/patient/patient.service';
import { Formula } from 'src/app/core/models/order/order-detail';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { EpsService } from 'src/app/core/services/eps/eps.service';
import { AddressService } from 'src/app/core/services/address/address.service';
import { Address } from 'src/app/core/models/address/address';
import { environment } from 'src/app/configs/environment';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import { CiamUser } from 'src/app/core/models/jw-user/ciam-user';

declare let dataLayer;

@Component({
	selector: 'app-order-manager',
	templateUrl: './order-manager.component.html',
	styleUrls: ['./order-manager.component.scss']
})
export class OrderManagerComponent implements OnInit {
	icon = './assets/icons/start/check.svg';
	title = 'Tus Pedidos';
	subtitle='';
	imageSee = './assets/icons/formula/ver-mas.svg';
	progress = 16.6;
	showDes: boolean = false;
	iconEdit: boolean = true;
	sessionSSO: JwUser;
  ciamSSO: CiamUser;
	documentSSO: string;
	idModal = 'orderManager';
	idModalImage = 'modalImage'
	tittle: string;
	description: string;
	modalBottoms: boolean;
	orderSelected: any;
	orderFormula: Formula;
	idOrderFormula: string;
	public address: Address;
	public eps: Eps;

	imageModal: string;
	ext: string;
	nameFile: string;

	dropDownForm: FormGroup;

	public ordersInProcess: Group;
	public ordersDeliveried: Group;
	public ordersCanceled: Group;
	public vulnerable: PatientVulnerable;


	/*VARIABLES BEHAVIOR SUBJECT*/
	getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
	getOrder$ = this._behaviorSubjectService.getOrder();
	getEps$ = this._behaviorSubjectService.getEps();
	getAddress$ = this._behaviorSubjectService.getAddress();

	constructor(
		private _orderService: OrderService,
		private _patientService: PatientService,
		private _location: Location,
		private _formBuilder: FormBuilder,
		private _jwtService: JwtService,
		private _orderConfigurationService: OrderConfigurationService,
		private _modal: ModalService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _epsService: EpsService,
		private _addressService: AddressService,
		private _auth: AuthService,
		private _localStorage: LocalStorageService,
	) {
    this.vulnerable = this.getPatientVulnerable$.getValue();
		this.eps = this.getEps$.getValue();

		this.dropDownForm = this._formBuilder.group({
			dropDown: [''],
			see: ['']
		});
	}

	ngOnInit(): void {
    this.getUserSSO();
	}


	public getOrders() {
		this._orderService.getGroupedOrders(this.vulnerable.tipoIdentificacion, this.vulnerable.identificacion, environment.TYPE_ORDER).subscribe(res => {

			if (res.result.code == '100') {
				    res.groups.forEach(obj => {
					  switch (obj.group) {
						case 'En proceso': {
						  this.ordersInProcess = obj;
						  break;
						}
						case 'Entregado': {
						  this.ordersDeliveried = obj;
						  break;
						}
						case 'Cancelado': {
						  this.ordersCanceled = obj;
						  break;
						}
					  }
					});

			} else {
				console.log('error');
			}
		})
	}

	despliegue() {
		this.showDes = !this.showDes;
	}

	toReturn() {
		this._location.back();
	}

	getUserSSO() {
    this.ciamSSO = this._jwtService.getCiamUserSSO();

		this.documentSSO = this.ciamSSO.data.tpIdentificacion;
    this.setVulnerable();
		this.getOrders();
	}

	public dropDown(id) {
		let idValue = 'dropDown-' + id;
		const radio = this.dropDownForm.get('dropDown').value;

		if (radio !== '' && radio == idValue) {
			this.dropDownForm.get('dropDown').setValue('');
		}
	}

	public cancelOrder() {
		this._orderService.modifyOrder(this.orderSelected, '9', this.vulnerable.identificacion + '_' + this.vulnerable.tipoIdentificacion).subscribe(res => {
			if (res.codigo.includes('100')) {
				this.getOrders();
				this.closeModal();
			}
		});

	}

	public validateAction(action: string, order: any) {
		this.orderSelected = order;
		this._orderConfigurationService.getValidationStateAndAction(order.idStatus, action).subscribe(res => {
			if (res.permitido) {
				if (action == 'CANCELAR') {
					this.modalBottoms = true;
					this.openModal('¿Seguro que desea cancelar la orden?', 'Esta acción no se puede deshacer');
				} else if (action == 'EDITAR') {
					this.goToAddressManager(order);
				}
			} else {
				this.modalBottoms = false;
				this.openModal('La acción no está permitida', 'El pedido no puede ser modificado debido al estado actual en el que se encuentra');
			}
		})

	}

	public openModal(title: string, description: string) {
		this.title = title;
		this.description = description;
		this._modal.openModal(this.idModal);
	}

	public closeModal() {
		this._modal.closeModal(this.idModal);
	}

	public getOrderDetail(idOrden: string) {
		this._orderService.getOrderDetail(idOrden, environment.TYPE).subscribe(res => {
			if (res.code == '100') {
				this.orderFormula = res.order.formulas;
				this.idOrderFormula = res.order.id;
			} else {
				console.log('error al consultar las formulas');
			}
		});
	}

	public seeChange(id, idOrder: string) {
		const see = this.dropDownForm.get('see').value;

		let bikeImage = document.getElementById('see-' + id) as HTMLImageElement;
		let height = document.getElementById(id + 'info') as HTMLImageElement;


		if (see == '' || see == false) {
			bikeImage.src = './assets/icons/formula/ver-mas.svg';
			height.style.height = '250px';
		} else {
			this.getOrderDetail(idOrder);
			bikeImage.src = './assets/icons/formula/ver-menos.svg';
			height.style.height = 'auto';
		}
	}

	public modal(ext, src, name) {
		this.imageModal = src;
		this.ext = ext;
		this.nameFile = name;

		setTimeout(() => {
			this._modal.openModal(this.idModalImage);
		}, 60);
	}

	public getFileExtension(path: string): string {
		let extension: string;
		let separation = path.split('.');
		extension = separation.pop();
		extension = `application/${extension}`;
		return extension;

	}

	public goToPatient() {
		this._router.navigate([environment.ROUTE_PATIENT], {
			relativeTo: this._route
		});
	}

	public goToFormula() {
		this.tagOrderManager();
		if (this.eps != undefined || this.eps != null) {
			this._router.navigate([environment.ROUTE_FORMULA], {
				relativeTo: this._route
			});
		} else {
			this._router.navigate([environment.ROUTE_EPS], {
				relativeTo: this._route
			});
		}
	}



	public goToAddressManager(order: any) {
		this.getAddressById(order.idAddress);
		this.getOrder$.next(order);

	}

	public getAddressById(idAddress: string) {
		this._addressService.getAddressById(idAddress)
			.subscribe(
				response => {
					this.address = response.direcciones[0];
					this.getAddress$.next(this.address);
					this._router.navigate(['/gestionar-direccion'], {
						relativeTo: this._route
					});
				}
			)
	}


	public setVulnerable() {
		let objVulnerable: PatientVulnerable = {
			tipoIdentificacion: this.documentSSO,
			// Se reemplaza la letra P por vacio debido al manejo del sso en qa.
			identificacion: this.ciamSSO.data.numeroDocumento.replace('P', ''),
			nombres: this.ciamSSO.profile.firstName,
			apellidos: this.ciamSSO.profile.lastName,
			eps: '',
			correoElectronico: this.ciamSSO.profile.email,
			celular: this.ciamSSO.phoneNumber
		}

		this.vulnerable = objVulnerable;
		this.getPatientVulnerable$.next(this.vulnerable);
		this.subtitle=objVulnerable.nombres+" "+objVulnerable.apellidos

	}

	public tagOrderManager() {
		dataLayer.push({
			'event': 'ga_event_nuevopedido',
			'category': 'Gestionar pedido',
			'action': 'Clic-boton',
			'label': 'Nuevo pedido',
			'UES': 'Medicamentos'
		});
	}

	public tagCancelButton() {
		dataLayer.push({
			'event': 'ga_event_botoncancelar',
			'category': 'PopUp',
			'action': 'Clic-boton',
			'label': 'Cancelar',
			'UES': 'Medicamentos'
		});

	}

	public tagAgreeButton() {
		dataLayer.push({
			'event': 'ga_event_botonaceptar',
			'category': 'PopUp',
			'action': 'Clic-boton',
			'label': 'Aceptar cancelación',
			'UES': 'Medicamentos'
		});
	}

	public detailsContact() {
		this.getPatientVulnerable$.next(this.vulnerable);
		this._router.navigate(['/gestionar-pedido/contacto'], {
			relativeTo: this._route
		});
	}
}

