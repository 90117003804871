<app-page-progress [progress]="progress"></app-page-progress>
<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>
<div>

  
  <form [formGroup]="scheduleDeliveryForm">
    <div class="ctn-fields">
      <div *ngIf="!showLoadSpinner">

        <div class="ctn-input-schedule">
          <ng-container *ngIf="fastDaliveryEnabled">
            <app-card class="list">
              <label for="fastDeliveryTitle">
                <input
                type="radio"
                id="fastDeliveryTitle"
                name="schedule"
                value="{{ this.fastDeliveryTitle }}"
                [disabled]="!this.fastDaliveryisActive"
                (click)="prepareOrderFastDelivery()"
              />
              <div class="space-between p-10">
                <div class="f-26 d-flex j-center p-v-10">
                  {{ this.fastDeliveryTitle }}
                </div>
                <div class="f-14 d-flex j-center" style="color: red;" *ngIf="!fastDaliveryisActive">
                  {{ this.fastDeliveryMessage }}
                </div>
                <div class="f-14 d-flex j-center" *ngIf="fastDaliveryisActive">
                  {{ this.fastDeliveryMessage }}
                </div>
              </div>
              </label>
            </app-card>
          </ng-container>
        </div>

        <div class="ctn-input-schedule">
          <ng-container *ngFor="let timeDelivery of timeDeliveryList; let i = index">
            <app-card class="list-schedule">
              <label for="schedule{{ i }}">
                <input
                  type="radio"
                  id="schedule{{ i }}"
                  name="schedule"
                  formControlName="schedule"
                  value="{{ timeDelivery.date }}"
                  (click)="setTimeZoneList(timeDelivery.timeZones)"
                />

                <div class="space-between p-10">
                  <div class="f-26 d-flex j-center p-v-10">
                    {{ timeDelivery.date | date: "d" }}
                  </div>
                  <div class="f-18 d-flex j-center">
                    {{ timeDelivery.date | date: "E" | titlecase }}
                  </div>
                </div>
              </label>
            </app-card>
          </ng-container>
        </div>
        
        <div class="ctn-input" *ngIf="this.dateEnable">
          <ng-container *ngFor="let timeZone of timeZoneList; let i = index">
            <app-card class="list-schedule-timezone">
              <label for="date{{ i }}">
                <input
                  type="radio"
                  id="date{{ i }}"
                  name="date"
                  formControlName="date"
                  value="{{ timeZone.id }}"
                />

                <div class="space-between p-10">
                  <div class="f-14 d-flex j-center p-v-10">
                    {{ timeZone.timeZone }}
                  </div>
                </div>
              </label>
            </app-card>
          </ng-container>
        </div>
      </div>
      <div *ngIf="showLoadSpinner" class="text-center">
        <div id="progressbar">
          <div [style.width]="progressLoadBar + '%'"></div>
        </div>
        <div>
          <label>Consultando Disponibilidades...</label>
        </div>
      </div>

      <div class="d-flex space-between buttons">
        <button (click)="toReturn()" class="btn-return">
          <img src="./assets/icons/navegacion/atras.svg" alt="icon" />
        </button>

        <button type="button" *ngIf="this.editar !== 'gestionaDireccion'">
          <div
            class="btn-next"
            *ngIf="scheduleDeliveryForm.valid"
            (click)="nextSubject()"
          ></div>
          <div class="btn-next-init" *ngIf="!scheduleDeliveryForm.valid"></div>
        </button>

        <button
        type="button"
        class="btn-save"
        *ngIf="this.editar === 'gestionaDireccion' && scheduleDeliveryForm.valid"
        (click)="updateAddress()"
      >
        Guardar
      </button>

      </div>
    </div>
  </form>
</div>
<app-modal-generic
  [idModal]="idModal"
  [title]="ModalTitle"
  [description]="ModalDescription"
>
  <button class="w-50 btn btn2" (click)="closeModal()">Aceptar</button>
</app-modal-generic>