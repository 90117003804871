export class RegularExpressions {
  public static ONLY_NUMBERS = '^[0-9]*$';
  public static ONLY_NUMBER_CELLPHONE = '3[0-9]{9}';
  public static ONLY_LETTERS = '^[a-zA-Z]([\s]?[a-zA-Z])*$';
  public static VALIDATE_EMAIL = '[a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$';
  public static LETTERS_SPACES = '^[a-zA-Z_][a-zA-Z_ ]*[a-zA-Z_]$';
  public static NUMBERS_ONLY_START_ZERO = '^[1-9][0-9]+';
  public static ONLY_NUMBERS_AND_LETTERS = '^[a-zA-Z0-9]*$';
  public static ONLY_OTROS = '^[a-zA-Z0-9]([\^s]?[a-zA-Z0-9])+$';
  public static VALIDATE_ADDRESS = '^((?!CALLE)(?!CARRERA)[A-Z]{2,8}[\ ])+((([0-9]{1,3})?([\ ]?[a-zA-Z\ ]*)?([\ ]?[0-9]{1,3})([\ ]?[a-zA-Z]{1})?([\ ]?)([\ ]?[0-9]{1,3})([\ ]?[a-zA-Z]{2,5})?([\ ]?[0-9]{1,3})?)?([A-Z\ ]{2,5}([A-Za-z\ ][0-9]*){1,60})?)$';
  public static VALIDATE_EXT = '^.*\.(xls|xlsx)$';
  public static LETTERS_SPACES_SPECIALS = '^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$';
  public static VALIDATE_AT_LEAST_ONE_NUMBER = '^(?:[a-zA-Z]*[0-9])+([a-zA-Z0-9])*';
}