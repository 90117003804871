export class Formula {
  number: string;
  mipres: string;
  dateService: string;
  status: string;
  treatment: Array<Treatment>;
  dispensation: Dispensing;
  authorization: string;
}

export class Dispensing {
  branchEnlistment: string;
  branchOffice: string;
  domicile: string;
  code: string;
  subCode: string;
  city: string;
  phone: string;
  mobile: string;
  email: string;
  timeZone: string;
  receptionManager: string;
}

export class Treatment {
  idSubscriptionManager: string;
  order: string;
  deliveryNumber: string;
  product: Product;
  quantity: number;
  value: string;
  paymentValue: string;
  paymentPercentage: string;
  dose: Dose;
  expirationDate: string;
  preauthorization: string;
  status: string;
}

export class Product {
  mapis: string;
  description: string;
  cum: string;
  typeTechnology: string;
  technologyCode: string;
  concentration: string;
  numbeDeliveries: number;
}

export class Dose {
  quantity: number;
  unitCode: string;
  unitName: string;
  periodQuantity: string;
  periodCode: string;
  periodName: string;
  durationQuantity: string;
  durationCode: string;
  durationName: string;
  specialIndications: string;
}

export interface IResponseFormula{
  codigo: string;
  descripcion: string;
  obtenerPrescripcion: IObtenerPrescripcion;
}

export interface IObtenerPrescripcion{
  paciente: IPaciente;
  formula: Array<IFormula>;
}

export interface IPaciente{
  documento: IDocumento;
  nombre: INombre;
  fechaNacimiento: string;
  edad: string;
  genero: string;
  telefono: string;
  celular: string;
  direccionPrincipal: string;
  correoElectronico: string;
  regimen: IRegimen;
  clasificacion: IClasificacion;
  ips: IIps;
}

export interface IRegimen{
  tipo: string;
  nivelSisben: string;
}

export interface IClasificacion{
  codigo: string;
  nombre: string;
}

export interface IFormula{
  numero: string;
  mipres: string;
  autorizacion: string;
  numeroSistemaOrigen: string;
  canal: string;
  fechaServicio: string;
  fechaEntrega: string;
  fechaSolicitud: string;
  fechaPreaprobacion: string;
  fechaVencimiento: string;
  status: string;
  categoria: ICategoria;
  cobro: ICobro;
  diagnostico: IDiagnostico;
  servicio: IServicio;
  ips: IIps;
  prestador: IPrestador;
  dispensacion: IDispensacion;
  tratamiento: Array<ITratamiento>;
}

export interface ICategoria{
  sigla: string;
  codigo: string;
  nombre: string;
}

export interface ICobro{
  tipo: string;
  nombre: string;
  valor: number;
}

export interface IDiagnostico{
  codigo: string;
  descripcion: string;
}

export interface IServicio{
  causa: ICausa;
  codigo: string;
  nombre: string;
}

export interface ICausa{
  codigo: string;
  nombre: string;
}

export interface IIps{
  codigo: string;
  sucursal: string;
  nombre: string;
  direccion: IDirecccion;
}

export interface IPrestador{
  documento: IDocumento;
  nombre: INombre;
  codigo: string;
}

export interface IDocumento{
  tipo: string;
  numero: string;
}

export interface INombre{
  razonSocial: string;
  primero: string;
  segundo: string;
  primerApellido: string;
  segundoApellido: string;
  completo: string;
}

export interface IDispensacion{
  alistamientoSucursal: string;
  sucursal: string;
  domicilio: string;
  codigo: string;
  subCodigo: string;
  direccion: IDirecccion;
  telefono: string;
  celular: string;
  correoElectronico: string;
  franjaHoraria: string;
  encargadoRecepcion: string;
}

export interface IDirecccion{
  departamento: ICiudad;
  ciudad: ICiudad;
  municipio: ICiudad;
}

export interface ICiudad{
  id: string;
  nombre: string;
}

export interface ITratamiento{
  id: string;
  orden: string;
  numeroEntrega: string;
  producto: IProducto;
  cantidad: number;
  valor: string;
  pago: IPago;
  dosis: IDosis;
  preautorizacion: string;
  fechaVencimiento: string;
  fechaServicio: string;
  estado: string;
}

export interface IProducto{
  mapis: string;
  descripcion: string;
  cum: string;
  tecnologia: ITecnologia;
  concentracion: string;
  cantidadDeEntregas: number;
}

export interface ITecnologia{
tipo: string;
codigo: string;
}

export interface IPago{
valor: string;
porcentaje: string;
}

export interface IDosis{
cantidad: number;
unidad: IUnidad;
periodo: IPeriodo;
duracion: IDuracion;
indicacionesEspeciales: string;
}

export interface IUnidad{
  codigo: string;
  nombre: string;
}

export interface IPeriodo{
  cantidad: string;
  unidad: IUnidad;
}

export interface IDuracion{
  cantidad: string;
  unidad: IUnidad;
}

