import { Carousel } from '../models/carousel/carousel';

export const CAROUSEL_DATA_ITEMS: Carousel[] = [
    {
        id: 1,
        title: {
            first: 'TITULO',
            second: 'Principal'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: 'assets/images/carousel/1.jpg'
    },
    {
        id: 2,
        title: {
            first: 'TITULO',
            second: 'Segundo'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: 'assets/images/carousel/2.jpg'
    },
    {
        id: 3,
        title: {
            first: 'TITULO',
            second: 'Tercero'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: 'assets/images/carousel/3.jpg'
    },
    {
        id: 4,
        title: {
            first: 'TITULO',
            second: 'Cuarto'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: 'assets/images/carousel/4.jpg'
    },
    {
        id: 5,
        title: {
            first: 'TITULO',
            second: 'Quinto'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: 'assets/images/carousel/5.jpg'
    }
];