export class Order {
  idCity: string;
  idEps: string;
  idAddress: string;
  identificationType: string;
  identification: string;
  idFranja: string;
  channel: string;
  deliveryDate: string;
  dispensary: string;
  formulas: any;
  type: string;
  usrCreation: string;
  domicileApply?: number;
  timeZoneApply?: string;

}
