<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div>
	<form [formGroup]="validateInfoRecurrentForm">
		<div class="ctn-fields">
			<div class="ctn-input">
				<div class="dircs">
					<ng-container>
						<div class="space-between">
							<div></div>
							<app-card class="list">
								<label *ngIf="name != null">
									<div class="d-flex space-between p-20">
										<span>
											<span class="d-block name">
												Nombre
											</span>

											<span class="address">
												{{name}}
											</span>
										</span>
									</div>
								</label>
								<label>
									<div class="d-flex space-between p-20">
										<span>
											<span class="d-block name">
												{{documentType}}
											</span>

											<span class="address">
												{{documentNumber}}
											</span>
										</span>
									</div>
								</label>
								<label>
									<div class="d-flex space-between p-20">
										<span>
											<span class="d-block name">
												EPS
											</span>

											<span class="address">
												{{nameEps}}
											</span>
										</span>
									</div>
								</label>
								<label>
									<div class="d-flex space-between p-20">
										<span>
											<span class="d-block name">
												Direccion
											</span>

											<span class="address">
												{{address}}
											</span>
										</span>
									</div>
								</label>
								<label>
									<div class="d-flex space-between p-20">
										<span>
											<span class="d-block name">
												Información adicional
											</span>

											<span class="address">
												{{addressAditionalInformation}}
											</span>
										</span>
									</div>
								</label>
							</app-card>
						</div>
						<div class="d-flex space-between buttons">
							<button (click)="toReturn()" class="btn-return">
								<img src="./assets/icons/navegacion/atras.svg" alt="icon">
							</button>
							<button (click)="nextSubject()" class="btn-continue-one">
								<img src="./assets/icons/navegacion/boton-continuar-habilitado.svg" alt="icon">
							</button>

						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</form>
</div>

<app-modal-generic [idModal]="idModal" [title]="titlebox" [description]="subTitleBox">
	<button class="w-50 btn btn1" (click)="closeModal(); ">
		Aceptar
	</button>
</app-modal-generic>