import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { environment } from 'src/app/configs/environment';
import { Router, ActivatedRoute } from '@angular/router';

import { AddressService } from 'src/app/core/services/address/address.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { PatientService } from 'src/app/core/services/patient/patient.service';

import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { Department } from 'src/app/core/models/department/department';
import { City } from 'src/app/core/models/city/city';
import { Address } from 'src/app/core/models/address/address';
import { Abbreviation } from 'src/app/core/models/abbreviation/abbreviation';
import { Order } from 'src/app/core/models/order/order-group';
import { OrderService } from 'src/app/core/services/order/order.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { JwUser } from 'src/app/core/models/jw-user/jw-user';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { OrderConfigurationService } from 'src/app/core/services/order-configuration/order-configuration.service';
import { IScheduleDelivery } from 'src/app/core/models/schedule-delivery/schedule-delivery';
import { FormulaService } from 'src/app/core/services/formula/formula.service';

declare let dataLayer;
@Component({
  selector: 'app-address-manager',
  templateUrl: './address-manager.component.html',
  styleUrls: ['./address-manager.component.scss']
})
export class AddressManagerComponent implements OnInit {

  icon = './assets/icons/arrow-maps.svg';
  title = 'Ingresa la dirección';
  subtitle = 'Donde enviaremos tus medicamentos.';
  idPatient: string;
  progress = 100;
  public idAddress: any;
  idModal = 'addressManager';
  ModalTitle: string;
  ModalDescription: string;

  addressForm: FormGroup;
  newAddress: boolean;
  updaAddress: Address;
  activate: boolean = false;
  disabled: boolean = false;
  enabledContinue: boolean = false;
  enableSchedule: boolean = false;
  enableButton: boolean = true;
  sessionSSO: JwUser;
  documentSSO: string;

  /*VARIBALES SERVICES*/
  departments: Department[] = [];
  cities: City[] = [];
  abreviatura: Abbreviation[] = [];
  address: Address[] = [];
  idDe: string;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getOrder$ = this._behaviorSubjectService.getOrder();
  getEps$ = this._behaviorSubjectService.getEps();
  getAddress$ = this._behaviorSubjectService.getAddress();
  getAddresses$ = this._behaviorSubjectService.getAddresses();
  getFormulaAS$ = this._behaviorSubjectService.getAttachedFormulaSelected();
  order: any;
  addressObj: any;

  public vulnerable: PatientVulnerable;
  public eps: Eps;
  public orderAddress: Address;
  public orderSelected: Order;
  public selectedAddress: any;
  public addresses: any;
  public timeZoneList: any;
  public timeDeliveryList: any;
  public showLoadSpinner: boolean = true;
  public scheduleDelivery: IScheduleDelivery;
  public idDispensary: string;
  public dateExpedition: string;
  public formulas = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _addressService: AddressService,
    private _behaviorSubjectService: BehaviorSubjectService,
    private _CHANGEDECTOR: ChangeDetectorRef,
    private _patientService: PatientService,
    private _location: Location,
    private _orderService: OrderService,
    private _jwtService: JwtService,
    private _modal: ModalService,
    private _orderConfigurationService: OrderConfigurationService,
    private _order: OrderService,
    private _formula: FormulaService,
  ) {
    this.vulnerable = this.getPatientVulnerable$.getValue();
    this.addressForm = this._formBuilder.group({
      addressNomenclature: ['', [Validators.required]],
      numberNomenclature: ['', [Validators.required]],
      numberOne: ['', [Validators.required]],
      numberTwo: ['', [Validators.required]],
      name: ['', [Validators.required]],
      district: [''],
      comments: [''],
      department: ['', [Validators.required]],
      city: ['', [Validators.required]]
    });

    /*SUBSCRIBE BEHAVIOR SUBJECT */
    this.eps = this.getEps$.getValue();
    this.orderAddress = this.getAddress$.getValue();
    this.orderSelected = this.getOrder$.getValue();

    this._CHANGEDECTOR.markForCheck();
    this.getUserSSO();
    this.getOrderDetailById();
  }

  ngOnInit(): void {
    if (this.orderAddress == null || this.orderAddress == undefined) {
      this._router.navigate(['/gestionar-pedido'], {
        relativeTo: this._route
      });
    }

    this.getDepartamentsEps();
    this.getCitiesDepartaments();
    this.selectAddress();


  }

  public getDepartamentsEps() {
    this._addressService.getDepartamentsEps(environment.TYPE, this.orderSelected.idEps).subscribe(
      response => {
        this.departments = response.departamentos;
      }
    );
  }

  public getCitiesDepartaments() {
    this.idDe = this.addressForm.get('department').value;
    if (this.idDe == '') {
      this.idDe = this.orderAddress.idDepartamento;
    }
    this._addressService.getCitiesDepartaments(environment.TYPE, this.idDe, this.orderSelected.idEps).subscribe(
      response => {
        this.cities = response.ciudades;
      }
    );
  }



  public setValue() {
    this.addressForm.setValue({
      addressNomenclature: this.order.addressNomenclature,
      numberNomenclature: this.order.numberNomenclature,
      numberOne: this.order.numberOne,
      numberTwo: this.order.numberTwo,
      name: this.order.name,
      district: this.order.district,
      comments: this.order.comments,
    });

    this.idAddress = this.order.idAddress;
  }

  public ngAfterContentChecked(): void {
    this._CHANGEDECTOR.markForCheck();
  }

  public getPatient() {
    this._patientService.getPatient(this.vulnerable.tipoIdentificacion, this.vulnerable.identificacion).subscribe(
      response => {
        this.idPatient = response.patient[0].id;
      }
    );
  }

  public selectAddress() {
    this.getAbreviatura();
    this.updaAddress = this.getAddress$.getValue();
    this.addressForm.get('department').setValue(this.orderAddress.idDepartamento);
    this.addressForm.get('city').setValue(this.orderAddress.idCiudad);
    this.addressForm.get('name').setValue(this.updaAddress.nombre);
    this.addressForm.get('district').setValue(this.updaAddress.barrio);
    this.addressForm.get('comments').setValue(this.updaAddress.comentarios);

    let separationNumeral = this.updaAddress.direccion.split('#');
    let separationSpaces = separationNumeral[0].split(' ');
    let abbreviationNumber = ''
    var i;
    for (i = 1; i < separationSpaces.length; i++) {
      abbreviationNumber = abbreviationNumber + ' ' + separationSpaces[i];
    }
    let separationHyphen = separationNumeral[1].split('-')

    let secondPartAddress = ''
    var i;
    for (i = 1; i < separationHyphen.length; i++) {
      secondPartAddress = secondPartAddress + ' ' + separationHyphen[i];
    }
    this.addressForm.get('addressNomenclature').setValue(separationSpaces[0]);
    this.addressForm.get('numberNomenclature').setValue(abbreviationNumber);
    this.addressForm.get('numberOne').setValue(separationHyphen[0]);
    this.addressForm.get('numberTwo').setValue(secondPartAddress);

    this.idAddress = this.updaAddress.idDireccion;


  }

  public resetValue() {
    this.addressForm.get('addressNomenclature').setValidators([Validators.required]);
    this.addressForm.get('numberNomenclature').setValidators([Validators.required]);
    this.addressForm.get('numberOne').setValidators([Validators.required]);
    this.addressForm.get('numberTwo').setValidators([Validators.required]);
    this.addressForm.get('name').setValidators([Validators.required]);

    this.addressForm.get('addressNomenclature').setValue('');
    this.addressForm.get('numberNomenclature').setValue('');
    this.addressForm.get('numberOne').setValue('');
    this.addressForm.get('numberTwo').setValue('');
    this.addressForm.get('name').setValue('');
    this.addressForm.get('district').setValue('');
    this.addressForm.get('comments').setValue('');
  }

  public getAbreviatura() {
    this._addressService.getAbreviatura().subscribe(
      response => {
        this.abreviatura = response.nomenclaturas;
      }
    );
  }

  public updateAddress() {
    let addAddress = `${this.addressForm.get('addressNomenclature').value} ${this.addressForm.get('numberNomenclature').value} #${this.addressForm.get('numberOne').value} - ${this.addressForm.get('numberTwo').value}`;
    let idFranjaAux = null;
    let fechaEntregaAux = null;
    if (this.addressForm.get('city').value == this.orderSelected.idCity) {
      idFranjaAux = this.orderSelected.idTimeZone;
      fechaEntregaAux = this.orderSelected.deliveryDate;
    }
    this.orderSelected;
    let request = {
      idOrden: this.orderSelected.id,
      tipo: environment.TYPE,
      usuarioActualizaDireccion: this.sessionSSO.customer.documentNumber + '_' + this.documentSSO,
      idFranja: idFranjaAux,
      fechaEntrega: fechaEntregaAux,
      calcularDispensario: true,
      direccion: {
        idDireccion: this.idAddress,
        direccion: addAddress,
        nombre: this.addressForm.get('name').value,
        idCiudad: this.addressForm.get('city').value,
        ciudad: this.getNameCityById(this.addressForm.get('city').value),
        comentarios: this.addressForm.get('comments').value,
        barrio: this.addressForm.get('district').value,
        canal: environment.CHANNEL,
        tipo: environment.TYPE,
        tipoIdentificacion: this.order.identificationType,
        identificacion: this.order.identification
      }
    };
    this._orderService.modifyAddressOrder(request).subscribe(
      response => {
        if (response.resultado.codigo == '100') {
          this.idAddress = this.idAddress;
          setTimeout(() => {
            this._router.navigate(['/gestionar-pedido'], {
              relativeTo: this._route
            });
          }, 30);
        } else {
          this.openModal("Error", "No se pudo actualizar la dirección,por favor intentelo más tarde \n Error: " + response.resultado.descripcion);
        }

      },
      err => {

      }
    );

    this.activate = true
    this.tagContinue(this.getNameDepartmentById(this.addressForm.get('department').value),this.getNameCityById(this.addressForm.get('city').value),addAddress);
  }

  public getFormData() {
    this.order = {
      idAddress: this.idAddress,
      name: this.addressForm.get('name').value,
      idCity: this.addresses.idCity,
      comments: this.addressForm.get('comments').value,
      channel: environment.CHANNEL,
      city: this.addresses.city,
      idDepartment: this.addresses.idDepartment,
      department: this.addresses.department,
      addressNomenclature: this.addressForm.get('addressNomenclature').value,
      numberNomenclature: this.addressForm.get('numberNomenclature').value,
      numberOne: this.addressForm.get('numberOne').value,
      numberTwo: this.addressForm.get('numberTwo').value,
      district: this.addressForm.get('district').value,
    }
  }

  public toReturn() {
    this._location.back();
  }

  public tagUrl() {
    dataLayer.push({
      'event': 'ga_virtual_Page',
      'titleVp': 'Datosgeograficos',
      'urlVp': '/vp/datosgeograficos'
    });
  }

  public tagContinue(department, city, address) {
    dataLayer.push({
      'event':'ga_event_editardireccion',
      'category':'Tus Pedidos',
      'action':'Clic-boton',
      'label':'Editar direccion',
      'UES':'Medicamentos',
      'Departamento':department,
      'Ciudad':city,
      'Direccion':address
    });

  }


  getUserSSO() {
    this.sessionSSO = this._jwtService.getJwtUserSSO();
    this._patientService.getTypeDocumentsById('', this.sessionSSO.customer.documentType, 'SSO').subscribe(res => {
      this.documentSSO = res.TipoDocumento[0].id;
    });
  }

  public openModal(title: string, description: string) {
    this.title = title;
    this.ModalDescription = description;
    this._modal.openModal(this.idModal);
  }

  public closeModal() {
    this._router.navigate(['/gestionar-pedido'], {
      relativeTo: this._route
    });
    this._modal.closeModal(this.idModal);
  }

  public validateStatusOrderTimeZoneConfiguration() {
    this.enableButton = false;
    this._orderConfigurationService.validateStatusOrderTimeZoneConfiguration(this.addressForm.get('city').value, environment.CHANNEL).subscribe(
      response => {
        if (response.configuracion != null && response.configuracion.estado == 'ACTIVO') {
          this.enableSchedule = true;
        } else {
          this.enableSchedule = false;
        }
        this.enableButton = true;
      }
    );
  }

  fillAddressRequest() {
    const addAddress = `${this.addressForm.get('addressNomenclature').value} ${this.addressForm.get('numberNomenclature').value} #${this.addressForm.get('numberOne').value} - ${this.addressForm.get('numberTwo').value}`;
    let request = {
      idOrden: this.orderSelected.id,
      tipo: environment.TYPE,
      usuarioActualizaDireccion: this.vulnerable.identificacion + '_' + this.vulnerable.tipoIdentificacion,
      idCiudad: this.addressForm.get('city').value,
      direccion: {
        idDireccion: this.idAddress,
        direccion: addAddress,
        nombre: this.addressForm.get('name').value,
        tipoIdentificacion: this.documentSSO,
        identificacion: this.sessionSSO.customer.documentNumber,
        idCiudad: this.addressForm.get('city').value,
        ciudad: this.getNameCityById(this.addressForm.get('city').value),
        comentarios: this.addressForm.get('comments').value,
        barrio: this.addressForm.get('district').value,
        canal: environment.CHANNEL,
        tipo: environment.TYPE
      }

    }
    this.addressObj = request;
    this.order.addressPatient = this.addressObj.direccion.direccion;
    this.order.idCity = this.addressForm.get('city').value;
    this.order.cityName = this.getNameCityById(this.addressForm.get('city').value);
    this.getOrder$.next(this.order);
    this.getIdDispensaryByGeoreferenceAndAddress();

  }

  public getOrderDetailById() {
    this._orderService.getOrderDetail(this.orderSelected.id, environment.TYPE).subscribe(response => {
      if (response.order != null) {
        this.order = response.order;
        this.formulas = this.order.formulas;
        this.getFormulaAS$.next(response.order.formulas);
      }
    }, err => {

    });
  }

  public getIdDispensaryByGeoreferenceAndAddress() {
    this._orderService.getIdDispensaryByGeoreferenceAndAddress(this.order).subscribe(response => {
      this.addressObj.direccion.coordenadas = response.coordinates;
      this.addressObj.idDispensario = response.id;
      this.getAddresses$.next(this.addressObj);
      localStorage.setItem('editar', 'gestionaDireccion');
      this._router.navigate(['/programar-entrega'], {
        relativeTo: this._route
      });
    });
  }

  public getNameCityById(id: string): string {
    let cityName: string;
    this.cities.forEach(element => {
      if (element.idCiudad === id) {
        cityName = element.nombreCiudad;
      }
    });
    return cityName;
  }


  public getNameDepartmentById(id: string): string {
    let departmentName: string;
    this.departments.forEach(element => {
      if (element.id === id) {
        departmentName = element.name;
      }
    });
    return departmentName;
  }




}

