import { Component, OnInit } from '@angular/core';
import { ParameterService } from 'src/app/core/services/parameter/parameter.service';
import { environment } from 'src/app/configs/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-habeas-data',
  templateUrl: './habeas-data.component.html',
  styleUrls: ['./habeas-data.component.scss']
})
export class HabeasDataComponent implements OnInit {

  public habeas: any;

  constructor(
    private _parameterService: ParameterService,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.getParameterHabeas();
  }

  getParameterHabeas() {
    this._parameterService.getParameters(environment.KEY_HABEAS_DATA).subscribe(response => {
      this.habeas = this._sanitizer.bypassSecurityTrustResourceUrl(response.parametro.valor);
    });
  }


}
