import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/app/configs/environment';
import { HttpService } from '../../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {


  constructor(
    private _HTTP: HttpService
  ) { }

  public sendSmsByKey(request: any) {

    return this._HTTP.post<any>(`${environment.API_NOTIFIER}/notificar/sms/gestor`, request)
      .pipe(
        map(
          respose => respose
        )
      );
  }

}
