<div class="d-flex space-between a-items-center header">
  <a [routerLink]="['/inicio']" class="p-15 icon-logo">
    <img src="./assets/icons/logo.svg" alt="logo" (click)="this.nextSubject()">
  </a>



  <button (click)="modal()" class="p-15" *ngIf="disableClose == false">
    <img src="./assets/icons/x.svg" alt="cerrar">
  </button>

</div>
