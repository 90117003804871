import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  public dropActive = false;
  public dropActiveAside = false;
  public maxHight: any;
  public ifClickShadow = false;
  idModal = 'inicio'

  constructor() { }

  ngOnInit() {
  }

}
