<div [id]="idModal" aria-hidden="true" class="c-modal metricas-atencion" data-actividad=atenciontelefono>
  <div tabindex="-1" data-micromodal-close class="c-modal_overlay" *ngIf="validModalType()">
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-1-title"
      class="c-modal_container c-modal_container--info"
    >
      <div class="c-modal_cont">
      <div class="f-right">
        <button (click)="close()" class="close">
            <img src="./assets/icons/x.svg" alt="close">
        </button>
      </div>
        <div class="c-modal_cont_text">
          <div>
            <img
              src="./assets/icons/formula/frecuency-questions.svg"
              alt="icon"
              class="d-block card-start-icon image-center"
            />
          </div>

          <div class="cont-title-sec text-center">
            <h3 class="color-blue-dark">Te acompañamos en tu solicitud</h3>
          </div>

          <div class="d-flex justify-content-center">
            <div class="w-100 text-center c-modal_cont_text_info">
              <div class="text-center">Llámanos si requieres ayuda {{telephone}}</div>
              <br>
              <div class="info_c">Horario de 8:00 am a 8:00 pm</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  <div tabindex="-1" data-micromodal-close class="c-modal_overlay" *ngIf="!validModalType()">
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-1-title"
      class="c-modal_container c-modal_container--info"
    >
      <div class="c-modal_cont">
        <div class="c-modal_cont_text">
          <div>
            <img
              src="./assets/icons/formula/help-order.svg"
              alt="icon"
              class="d-block card-start-icon image-center"
            />
          </div>

          <div class="cont-title-sec text-center">
            <h3 class="color-blue-dark">Te apoyamos en tu solicitud</h3>
          </div>

          <div class="d-flex justify-content-center">
            <div class="w-100 text-center c-modal_cont_text_info">
              <div class="text-center">Llámanos si requieres ayuda {{telephone}}</div>
              <br>
              <div class="info_c">Horario de 8:00 am a 8:00 pm</div>
            </div>
          </div>
        </div>
        <div class="c-modal_cont__buttons">
          <button class="w-50 btn btn1" (click)="close()">
            Cancelar
          </button>

          <a href="tel:{{telephone}}" class="w-50 btn btn2">Llamar</a>
        </div>
      </div>
    </div>
  </div>
</div>