import { Component, OnInit } from '@angular/core';
import { Group } from 'src/app/core/models/order/order-group';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { environment } from 'src/app/configs/environment';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { PatientService } from 'src/app/core/services/patient/patient.service';
import { Patient } from 'src/app/core/models/patient/patient';
import { Location } from '@angular/common';
import { RegularExpressions } from 'src/app/core/helpers/regular-expressions/regular-expressions';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { RecurrentService } from 'src/app/core/services/recurrent/recurrent.services';
import { NotifierService } from 'src/app/core/services/notifier/notifier.service';
import { constants } from 'buffer';


@Component({
	selector: 'app-order-manager',
	templateUrl: './contactOrder-manager.component.html',
	styleUrls: ['./contactOrder-manager.component.scss']
})
export class ContactOrderManagerComponent implements OnInit {

	icon = './assets/icons/documento.svg';
	title = 'Actualización de Datos de Contacto';
	subtitle = '';
	descripcionRecurrentes ='Actualmente, te encuentras afiliado al programa de pedidos recurrentes. Para desafiliarte haz clic al siguiente botón.';
	titlebox ="¿Seguro que desea desafiliarse?";
	subTitleBox="Si en algún momento desea recuperar la afiliciación solicite un pedido recurrente.";
	
	contactOrderForm: FormGroup;
	patient: Patient;
	documentType: string;
	telefono : string;
	description : string;
	idModal : string;
	titleWindows: string;
	afiliado : boolean;

	/*VARIABLES BEHAVIOR SUBJECT*/
	getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();

	public vulnerable: PatientVulnerable;

	constructor(
		private _formBuilder: FormBuilder,
		private _patientService: PatientService,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _router: ActivatedRoute,
		private _location: Location,
		private _modal: ModalService,
		private _recurrent: RecurrentService,
		private _notifier: NotifierService,
	) {
		this.contactOrderForm = this._formBuilder.group({
			telefono: [
				'',
				[
					Validators.minLength(10),
					Validators.maxLength(10),
					Validators.pattern(RegularExpressions.ONLY_NUMBER_CELLPHONE)
				]
			]
		});
		this.vulnerable = this.getPatientVulnerable$.getValue();
	}

	ngOnInit(): void {
		this.findPatient();
	}

	findPatient() {
		this._patientService.getPatient(this.vulnerable.tipoIdentificacion, this.vulnerable.identificacion).subscribe(
			response => {
				let objPatient: Patient = {
					id: response.patient[0].id,
					typeIdentification: response.patient[0].typeIdentification,
					identification: response.patient[0].identification,
					name: response.patient[0].name,
					surname: response.patient[0].surname,
					email: response.patient[0].email==""||response.patient[0].email==null?"No Registra":response.patient[0].email,
					phone: response.patient[0].phone==""||response.patient[0].phone==null?"No Registra":response.patient[0].phone,
					phoneNumber: response.patient[0].phoneNumber==""||response.patient[0].phoneNumber==null?"No Registra":response.patient[0].phoneNumber,
					channel: response.patient[0].channel,
					birthdate: response.patient[0].birthdate
				}
				this.patient = objPatient;
				this.findDocumentTypeDesc();
				this.verifyAffiliate();
			}
		);
	}

	findDocumentTypeDesc() {
		this._patientService.getTypeDocuments().subscribe(
			response => {
				response.TipoDocumento.forEach(documento => {
					if (documento.id == this.patient.typeIdentification) {
						this.documentType = documento.nombre;
					}
				}
				)
			}
		);
	}
	
	verifyAffiliate(){
		this.afiliado=false;
		this._recurrent.getStatusAffiliate(this.patient.typeIdentification, this.patient.identification).subscribe(
			response=>{
				this.afiliado=response.datos.aceptaRecurrencia==1?true:false;
			}
		)
	}

	updatePhone() {
		const previousPhone = this.patient.phone;
		this.patient.phone=this.contactOrderForm.get("telefono").value;
		this._patientService.updatePatient(this.patient).subscribe(response=>{
			if(response.codigo == '100'){
				let request = {
					llaveGestor: environment.KEY_SMS_UPDATE_PHONE,
					celular: previousPhone,
					tipoIdentificacion: this.patient.typeIdentification,
					identificacion:  this.patient.identification
				}
				this.sendSmsKey(request);
				
			}
			this.findPatient();		
		});
		
	}

	sendSmsKey(request: any){
		
		this._notifier.sendSmsByKey(request).subscribe(response =>{
		})
	}

	toReturn() {
		this._location.back();
	}
	
	public openModal() {
		this._modal.openModal(this.idModal);
	}

	public cancelAfilliate() {
		this._recurrent.UpdateAffiliate(this.patient.typeIdentification, this.patient.identification, false).subscribe(
			response=>{
				if(response.resultado.codigo == '200'){
					let request = {
						llaveGestor: environment.DISENROLLED_RECURRENT_PATIENT,
						celular: this.patient.phone,
						tipoIdentificacion: this.patient.typeIdentification,
						identificacion:  this.patient.identification
					}
					this.sendSmsKey(request);
				}
			}
		)
		this.closeModal();
		this.toReturn();
	}
	public closeModal() {
		this._modal.closeModal(this.idModal);		
	} 	
}