<colsubsidio-ciam-active-directory
  ngClass="w-50"
  [api_key]=apiKey
  [type_marketing]=typeMarketing
  [var_userdata]=userData
  [screen_prefix]=screenPrefix
  [cdn_url]=cdnUrl
  [count_auth]=countAuth
  [url_authentication_ciam] = ciamLoginUrl
  (result_auth)="redirectUrl($event)"
>
</colsubsidio-ciam-active-directory>
<router-outlet></router-outlet>
