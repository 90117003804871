import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal/modal.service';

@Component({
  selector: 'app-modal-home',
  templateUrl: './modal-home.component.html',
  styleUrls: ['./modal-home.component.scss']
})
export class ModalHomeComponent implements OnInit {

  @Input() idModal: string;

  constructor(
    private _MODAL: ModalService
  ) { }

  ngOnInit(): void {
  }

  close() {
    this._MODAL.closeModal(this.idModal);
  }
}
