<app-title [icon]="icon" [title]="title"></app-title>

<div>
	<form [formGroup]="epsForm">
		<div class="ctn-fields">
			<div class="ctn-input">
				<ng-container *ngFor="let epsItem of eps">
          <ng-container *ngFor="let imageEpsItem of imageEps">
            <!-- Verifica si el id coincide -->
            <ng-container *ngIf="imageEpsItem.idEps === epsItem.id">
              <div class="list card_document">
                <label for="{{ epsItem.id }}">
                  <input
                    type="radio"
                    id="{{ epsItem.id }}"
                    name="eps"
                    formControlName="eps"
                    value="{{ epsItem.id }}"
                    (change)="setEpsObj(epsItem)"
                  />
                  <div class="d-flex space-between div-img">
                    <div class="img-container">
                      <img
                        *ngIf="imageEpsItem.file"
                        [src]="getSanitizedImageUrl(imageEpsItem.file)"
                        alt="icon"
                        class="eps-image"
                      />
                    </div>

                    <span class="check c-blue">
                      <img src="./assets/icons/check.svg" alt="check" />
                    </span>
                  </div>
                </label>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
			</div>

			<div class="d-flex f-wrap space-around a-items-center p-20 warning">
				<div>
					<img src="./assets/icons/formula/megafono.svg" alt="">
				</div>

				<div>
					<p>{{messageDomicile}}</p>
				</div>
			</div>


			<div class="d-flex space-between buttons">
				<button (click)="toReturn()" class="btn-return">
					<img src="./assets/icons/navegacion/atras.svg" alt="icon">
				</button>
				<button (click)="nextSubject()" class="btn-continue-one" [disabled]="!epsForm.valid">
					<img src="./assets/icons/navegacion/boton-continuar-{{epsForm.valid == true ?'habilitado':'deshabilitado'}}.svg"
						alt="icon">
				</button>

			</div>
		</div>

	</form>
</div>
