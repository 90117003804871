import { Component, OnInit, Input, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { slider } from 'src/app/core/helpers/carousel/carousel';
declare let dataLayer;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarouselComponent implements OnInit {

  timeOut = 2000;
  slideIndex = 0;
  autoOn = true;
  images: any;

  screenWidth: number;
  ifScreenWidth: boolean = false;

  constructor(
    private _changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    /*setInterval(() => {
      this.showSlides()
    }, 6000);*/

    //this.autoSlides();

    // set screenWidth on page load
    this.screenWidth = window.innerWidth;

    setTimeout(() => {
      if(this.screenWidth < 768) {
        this.images = slider.imagesMobile;
      } else {
        this.images = slider.imagesDesktop;
      }
      this._changeDetector.markForCheck();
    }, 10);

    setTimeout(() => {
      this.autoSlides()
    }, 20);
  }

  public autoSlides() {
    this.timeOut = this.timeOut - 20;

    if (this.autoOn == true) {
      this.showSlides();
    }
  }

  public showSlides() {
    this.timeOut = 2000;
  
    let slides = document.getElementsByClassName('mySlides') as HTMLCollectionOf<HTMLElement>;
    let dots = document.getElementsByClassName('dot');
  
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
      dots[i].className = dots[i].className.replace(' active', '');
    }
    this.slideIndex++;
  
    if (this.slideIndex > slides.length) {
      this.slideIndex = 1;
    }
    slides[this.slideIndex - 1].style.display = 'block';
    dots[this.slideIndex - 1].className += ' active';
  }

  public prevSlide() { 
    let slides = document.getElementsByClassName('mySlides') as HTMLCollectionOf<HTMLElement>;
    let dots = document.getElementsByClassName('dot');
  
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
      dots[i].className = dots[i].className.replace(' active', '');
    }

    this.slideIndex--;
  
    if (this.slideIndex > slides.length) {
      this.slideIndex = 1;
    }
    if (this.slideIndex == 0) {
      this.slideIndex = 5;
    }

    slides[this.slideIndex - 1].style.display = 'block';
    dots[this.slideIndex - 1].className += ' active';
  }

  public setCurrentPosition(position: number) {
    this.slideIndex = position;

    let slides = document.getElementsByClassName('mySlides') as HTMLCollectionOf<HTMLElement>;
    let dots = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
      dots[i].className = dots[i].className.replace(' active', '');
    }

    slides[this.slideIndex].style.display = 'block';
    dots[this.slideIndex].className += ' active';
  }

  public tag(label) {
    dataLayer.push({
      'event':'ga_event_slider',
      'category':'Home',
      'action':'Clic-slider',
      'label': label,
      'UES':'Medicamentos'
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
    this.calculateIfScreenWidth();
  }

  calculateIfScreenWidth() {
    if(this.screenWidth < 768) {
      this.images = slider.imagesMobile;
      this.autoSlides()
    } else {
      this.images = slider.imagesDesktop;
      this.autoSlides()
    }
    this._changeDetector.markForCheck();
  }
}
