<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div>
  <form [formGroup]="contactForm">
    <div class="ctn-fields">
      <div class="d-flex f-wrap">
        <div class="ctn-data">
          <label for="name">
            Escribe los nombres del paciente
  
            <input class="i-data" type="text" name="name" id="name" formControlName="name">
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="contactForm.get('name').touched"
                            [errors]="contactForm.get('name').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>

        <div class="ctn-data">
          <label for="surname">
            Escribe los apellidos del paciente
  
            <input class="i-data" type="text" name="surname" id="surname" formControlName="surname">
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="contactForm.get('surname').touched"
                            [errors]="contactForm.get('surname').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>
      </div>

      <div class="d-flex f-wrap">
        <div class="ctn-data">
          <label for="phone">
            Escribe tu número de celular
  
            <input class="i-data" name="phone" id="phone" formControlName="phone" (focus)="focus('phone')" (blur)="focusOut('phone')">
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="contactForm.get('phone').touched"
                            [errors]="contactForm.get('phone').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>

        <div class="ctn-data">
          <label for="email">
            Ingresa tu correo electrónico
  
            <input  class="i-data" type="text" name="email" id="email" formControlName="email" (focus)="focus('email')" (blur)="focusOut('email')">
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="contactForm.get('email').touched"
                            [errors]="contactForm.get('email').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="ctn-data">
          <label for="tel">
            Escribe un teléfono de contacto alterno
  
            <input class="i-data" type="text" name="tel" id="tel" formControlName="tel" (focus)="focus('tel')" (blur)="focusOut('tel')">
          </label>
          <div class="text-invalid">
            <app-form-errors *ngIf="contactForm.get('tel').touched"
                            [errors]="contactForm.get('tel').errors">
            </app-form-errors>
          </div>
        </div>
      </div>

      <div class="d-flex space-between buttons">
        <button (click)="toReturn()" class="btn-return">
          <img src="./assets/icons/navegacion/atras.svg" alt="icon">
        </button>

        <button *ngIf="!contactForm.valid" type="button">
          <img src="./assets/icons/navegacion/boton-continuar-deshabilitado.svg" alt="icon">
        </button>

        <button type="button" (click)="nextSubject()" *ngIf="contactForm.valid">
          <div class="btn-next"></div>
        </button>
      </div>
    </div>
  </form>
</div>
