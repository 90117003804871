<app-page-progress [progress]="progress"></app-page-progress>

<div *ngIf="enabledContinue == true">
  <app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

  <div>
    <form [formGroup]="patientForm">
      <div class="ctn-fields">
        <div class="ctn-input">
          <div class="f-16">
            Selecciona el tipo de documento
          </div>

          <ng-container *ngFor="let document of listIdentifications">
            <app-card class="list">
              <label for="{{document.id}}">
                <input type="radio" id="{{document.id}}" name="typeIdentification" formControlName="typeIdentification"
                  value="{{document.id}}" (change)="identificationValidations($event.target.id)">

                <div class="d-flex space-between p-20">
                  {{document.nombre}}

                  <span class="check c-blue">
                    <img src="./assets/icons/check.svg" alt="check">
                  </span>
                </div>
              </label>
            </app-card>
          </ng-container>

          <div class="text-invalid">
            <app-form-errors *ngIf="patientForm.get('typeIdentification').touched"
              [errors]="patientForm.get('typeIdentification').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>

        <div class="ctn-data p-relative" *ngIf="showFields">
          <label for="identification">
            <span class="n-doc">
              Escribe el número de documento
            </span>

            <span class="icon-error"
              *ngIf="patientForm.get('identification').invalid && patientForm.get('identification').touched">
              <img src="./assets/icons/campoerror.svg" alt="error">
            </span>

            <input class="i-data" [type]="identification" name="identification" id="identification"
              formControlName="identification"
              [ngClass]="{'error': patientForm.get('identification').invalid && patientForm.get('identification').touched}"
              (keyup)="reset()">
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="patientForm.get('identification').touched"
              [errors]="patientForm.get('identification').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>

        <div *ngIf="showFields">
          <div class="ctn-terms">
            <input type="checkbox" class="input-terms" name="termsHabeas1" id="termsHabeas1"
              formControlName="termsHabeas1" (change)="fillTermsHabeas1()">

            <label for="termsHabeas1" class="label-terms">
              Al continuar, aceptas los

              <a [routerLink]="['/terminos-y-condiciones']" target="_blank" class="c-blue-medium">
                términos y condiciones
              </a>

            </label>

            <div class="check-terms"></div>
          </div>

          <div class="text-invalid">
            <app-form-errors *ngIf="patientForm.get('termsHabeas1').touched"
              [errors]="patientForm.get('termsHabeas1').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>

        <div *ngIf="showFields">
          <div class="ctn-terms">
            <input type="checkbox" class="input-terms" name="termsHabeas2" id="termsHabeas2"
              formControlName="termsHabeas2" (change)="fillTermsHabeas2()">

            <label for="termsHabeas2" class="label-terms">
              ¿Aceptas la
              <a [routerLink]="['/habeas-data']" target="_blank" class="c-blue-medium">
                política y tratamiento de datos
              </a>
              para recibir información a través de nuestros canales de Colsubsidio?
            </label>
            <div class="check-terms"></div>
          </div>
        </div>

        <div class="d-flex space-between buttons">
          <button (click)="toReturn()" class="btn-return">
            <img src="./assets/icons/navegacion/atras.svg" alt="icon">
          </button>

          <button *ngIf="!patientForm.valid" type="button">
            <div class="btn-next-init"></div>
          </button>

          <button type="button" (click)="nextSubject()" *ngIf="patientForm.valid">
            <div class="btn-next"></div>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="ctn-not-vulnerable" *ngIf="enabledContinue == false">
  <div class="ctn-not-vulnerable__info">
    <div>
      <img src="./assets/icons/warning.svg" alt="aviso">
    </div>

    <div class="ctn-not-vulnerable__info__title">
      <div>
        El documento ingresado no cumple con los
      </div>

      <div>
        requisitos para realizar este proceso.
      </div>
    </div>

    <div class="ctn-not-vulnerable__info__parrafo">
      <div>
        Te invitamos a solicitar tus medicamentos a
      </div>

      <div>
        través de la app Medicamentos Colsubsidio.
      </div>
    </div>

    <div class="p-20">
      <button class="behind" (click)="behind()">
        Regresar
      </button>
    </div>
  </div>
</div>

<app-modal-generic [idModal]="idModal" [title]="modalTitle" [description]="modalDescription">
  <button class="w-50 btn btn1" (click)="continueNoVulnerable()">
    Continuar
  </button>
  <button class="w-50 btn btn2" (click)="goToPreagendamiento()">
    Entrega presencial
  </button>
</app-modal-generic>