<div [id]="idModal" aria-hidden="true" class="c-modal">
  <div tabindex="-1" data-micromodal-close class="c-modal_overlay">
    <div role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
          class="c-modal_container c-modal_container--info">

      <div class="c-modal_cont">
        <div class="close">
          <button class="btn btn1" (click)="close()">
            <img src="./assets/icons/x.svg" alt="cerrar">
          </button>
        </div>

        <div *ngIf="ext != 'application/pdf'" class="d-flex j-center p-10">
          <img [src]="image" alt="image">
        </div>

        <div *ngIf="ext == 'application/pdf'">
          <iframe [src]="image" class="embed" *ngIf="iframe"></iframe>

          <a [href]="image" [download]="nameFile" *ngIf="!iframe" class="download" (click)="close()">
            Descargar archivo
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
