import {Injectable, NgZone} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { environment } from 'src/app/configs/environment';
import { AppConfig } from 'src/app/configs/app-config';
import { AuthService } from '../../services/auth/auth.service';
import { JwtService } from '../../services/jwt.service';
@Injectable({
  providedIn: 'root'
})
export class NoLoggedGuard implements CanActivate {
  constructor(private _LOGIN: AuthService,
              private ngZone: NgZone,
              private router: Router,
              private _localStorage: LocalStorageService,
              private _JTWSERVICE: JwtService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this._JTWSERVICE.getAndStoreJWTValue(next.params.idToken);
    if (next.params.idToken) {
      return this._LOGIN.authJwt()
        .pipe(
          map(  result => {
            if (result) {
              this.ngZone.run(async () => {
                await this.router.navigateByUrl(`/gestionar-pedido`);
              });
              return false;
            } else {
              location.href = environment.SSO_ENDPOINT;
              return  false;
            }
          })
        );
    } else {
      location.href = environment.SSO_ENDPOINT;
      return  false;
    }
  }
}
