<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div>
  <form [formGroup]="addressRecurrentForm">
    <div class="ctn-fields">
      <div class="d-flex f-wrap space-between p-v-10">
        <div class="d-flex a-items-center dir">
          <div class="select">
            <label for="addressNomenclature" class="t-doc">
              Dirección
          
              <select id="addressNomenclature" name="addressNomenclature" formControlName="addressNomenclature">
                <option *ngFor="let abr of abreviatura" [value]="abr.abreviacion">
                  {{abr.nomenclatura}}
                </option>
              </select>
            </label>

            <div class="text-invalid">
              <app-form-errors *ngIf="addressRecurrentForm.get('addressNomenclature').touched"
                              [errors]="addressRecurrentForm.get('addressNomenclature').errors">
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>
  
          <div class="ctn-data">
            <label for="numberNomenclature">
              <input class="i-data" type="text" name="numberNomenclature" id="numberNomenclature" formControlName="numberNomenclature" >
            </label>

            <div class="text-invalid">
              <app-form-errors *ngIf="addressRecurrentForm.get('numberNomenclature').touched"
                              [errors]="addressRecurrentForm.get('numberNomenclature').errors">
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>
        </div>

        <div class="d-flex a-items-center dir">
          <span>
            #
          </span>
  
          <div class="ctn-data ctn-data-one">
            <label for="numberOne">
              <input class="i-data" type="text" name="numberOne" id="numberOne" formControlName="numberOne" >
            </label>

            <div class="text-invalid">
              <app-form-errors *ngIf="addressRecurrentForm.get('numberOne').touched"
                              [errors]="addressRecurrentForm.get('numberOne').errors">
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>
  
          <span>
            -
          </span>
  
          <div class="ctn-data ctn-data-two">
            <label for="numberTwo">
              <input class="i-data" type="text" name="numberTwo" id="numberTwo" formControlName="numberTwo" >
            </label>

            <div class="text-invalid">
              <app-form-errors *ngIf="addressRecurrentForm.get('numberTwo').touched"
                              [errors]="addressRecurrentForm.get('numberTwo').errors">
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex f-wrap space-between p-v-10">
        <div class="ctn-data dir">
          <label for="name">
            Dale un nombre a tu dirección
            <input class="i-data" type="text" name="name" id="name" formControlName="name" maxlength="190">
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="addressRecurrentForm.get('name').touched"
                            [errors]="addressRecurrentForm.get('name').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>

        <div class="ctn-data dir">
          <label for="district">
            Barrio

            <input class="i-data" type="text" name="district" id="district" formControlName="district">
          </label>
        </div>
      </div>

      <div class="d-flex">
        <div class="ctn-data in-dir">
          <label for="comments">
            Información adicional

            <textarea class="i-data" type="text" name="comments" id="comments" formControlName="comments"
            placeholder="Ingresa indicaciones especiales como apartamento, bloque, interior, conjunto, edificio, puntos de referencia y otros datos que consideres necesarios"
            cols="30" rows="6" maxlength="490"></textarea>
          </label>
        </div>
      </div>

      <div class="a-items-center p-20 warning">
				<div class="d-flex space-around">
					<img class= "p-20" src="./assets/icons/formula/megafono.svg" alt="">
					<p>{{messageDomicile}}</p>
				</div>
			</div>

      <div class="d-flex space-between buttons">
        <button (click)="toReturn()" class="btn-return">
          <img src="./assets/icons/navegacion/atras.svg" alt="icon">
        </button>

        <button *ngIf="!addressRecurrentForm.valid || activate" type="button">
          <div class="btn-next-init"></div>
        </button>

        <button type="button" (click)="nextSubject()" *ngIf="addressRecurrentForm.valid && !activate">
          <div class="btn-next"></div>
        </button>
      </div>

    </div>
  </form>
</div>
