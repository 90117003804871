import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import {Inject, Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(AuthService) private authService: AuthService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.getJwtToken()) {
      req = this.addToken(req, this.authService.getJwtToken());
    }

    return next.handle(req).pipe(catchError(err => {
      if ((err instanceof HttpErrorResponse && err.status === 401) || (err instanceof HttpErrorResponse && err.status === 403)) {
        return this.handleAuthError(req, next);
      } else {
        return throwError(err);
      }
    })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handleAuthError(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.authJwt().pipe(
      switchMap((token: any) => {
        return next.handle(this.addToken(request, this.authService.getJwtToken()));
      }));
  }
}
