import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { Location } from '@angular/common';
import { Formula, IFormula, Product, Dose, Treatment, ITratamiento, Dispensing } from 'src/app/core/models/electronic-formula/electronic-formula';
import { FormulaService } from 'src/app/core/services/formula/formula.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InformationFormatting } from 'src/app/core/helpers/information-formatting/information-formatting';
import { GetPatient } from 'src/app/core/models/patient/patient';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { environment } from 'src/app/configs/environment';
import { RegularExpressions } from 'src/app/core/helpers/regular-expressions/regular-expressions';
import { ParameterService } from 'src/app/core/services/parameter/parameter.service';

declare let dataLayer;

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormulaComponent implements OnInit {

  idModal = 'ShowFile';
  idModalHelp = 'ShowHelp';
  modalType: string;
  imageModal: string;
  ext: string;
  nameFile: string;
  maxSizeFile = environment.SIZE_FILE_MAX;

  buttonContinueFoE: boolean = false;
  buttonContinueFoESearch: boolean = false;
  viewElectronicsFormala: boolean = false;
  showHelpButton: boolean = false;
  telephone: string;
  progress = 33.2;

  formula: Formula;
  sendFormula: Array<Formula> = [];
  formulas: Array<IFormula>;
  spinner: boolean = true;
  noPbsApply: boolean = false;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatient$ = this._behaviorSubjectService.getPatient();
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getEps$ = this._behaviorSubjectService.getEps();
  getFormulaE$ = this._behaviorSubjectService.getElectronicFormula();
  getFormulaESearch$ = this._behaviorSubjectService.getElectronicSearchFormula();
  getFormulaA$ = this._behaviorSubjectService.getAttachedFormula();
  getFormulaAS$ = this._behaviorSubjectService.getAttachedFormulaSelected();
  //
  getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();

  //
  public objFormulaNoPbs = [];
  public orderFormulaNoPbs: any;

  public vulnerable: PatientVulnerable;
  public eps: Eps;
  public patient: GetPatient;

  icon = './assets/icons/formula/elige-formula.svg';
  title: string;
  subtitle: string;
  description: string;

  formulaForm: FormGroup;
  attachedForm: FormGroup;

  sectionForm: boolean = false;
  authorizationRadio: boolean = false;
  fieldsAuth: boolean = false;
  showInput: boolean = false;
  formulasSea: Array<Formula> = [];
  formulaObjectSe: Object = {};
  sectionImage: boolean = false;
  files = [];
  selectedPhotos = [];
  showDes: boolean = false;
  showLoadImage: boolean = false;
  image: any;
  disabledSelectedFile: boolean = false;
  enableContinueAttachment: boolean = false;
  duplicateNumber: boolean;
  maxSize: boolean = false;
  imageSee = './assets/icons/formula/ver-mas.svg';

  message = 'La fórmula ya existe, debes eliminarla para agregarla nuevamente';

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _behaviorSubjectService: BehaviorSubjectService,
    private _CHANGEDECTOR: ChangeDetectorRef,
    private _location: Location,
    private _formulaService: FormulaService,
    private _formBuilder: FormBuilder,
    private _modal: ModalService,
    private _parameterService : ParameterService
  ) {

    /*SUBSCRIBE BEHAVIOR SUBJECT */
    this.vulnerable = this.getPatientVulnerable$.getValue();
    this.eps = this.getEps$.getValue();
    this.patient = this.getPatient$.getValue();

    if (this.vulnerable != null) {
      let namePatient = this.vulnerable.nombres != null && this.vulnerable.nombres !='' ? this.vulnerable.nombres.split(" ", 1): "Paciente";
      const name = this.vulnerable.nombres == 'No_Registra' ? 'Paciente' : namePatient;
      this.title = `${name}, selecciona las fórmulas médicas`;
    }

    if (this.eps) {
      this.subtitle = `Estas son las fórmulas recientes cargadas de tus últimas consultas en ${this.eps.nombre}.`
    }

    this.formulaForm = this._formBuilder.group({
      authorization: [
        '',
      ],
      numberFormula: [
        '',
        [
          Validators.required,
          Validators.maxLength(22),
          Validators.pattern(RegularExpressions.VALIDATE_AT_LEAST_ONE_NUMBER)
        ]
      ],
      numberAuthorization: [
        '',
        [
          Validators.maxLength(22),
          Validators.pattern(RegularExpressions.VALIDATE_AT_LEAST_ONE_NUMBER)
        ]
      ],
    });

    this.attachedForm = this._formBuilder.group({
      see: ['']
    });

    this._CHANGEDECTOR.markForCheck();
  }

  ngOnInit(): void {
    this.getParameterKey(environment.KEY_ENABLE_CLICK_TO_CALL);
    this.getParameterTel(environment.KEY_TEL_CLICK_TO_CALL)
    this.tagUrl();
    this._CHANGEDECTOR.markForCheck();
    this.getFormula();
    this.sendFormula = this.getFormulaE$.getValue();
    this.formulasSea = this.getFormulaESearch$.getValue();
    this.files = this.getFormulaA$.getValue();
    this.objFormulaNoPbs = this.getAttachedFormulaNoPbsPreload$.getValue();
    this.orderFormulaNoPbs = this.getAttachedFormulaNoPbs$.getValue()
    this.selectedPhotos = this.getFormulaAS$.getValue();


    if (this.sendFormula.length > 0) {
      this.buttonContinueFoE = true;
    }

    if (this.formulasSea.length > 0) {
      this.buttonContinueFoESearch = true
    }

    this.image = InformationFormatting.returnImageEps(this.eps.nit);
  }

  toReturn() {
    this._router.navigate(['/inicio'], {
      relativeTo: this._route
    });
  }

  onKey(event) {
    let numberFormula = this.formulaForm.get('numberFormula').value;
    let numberAuthorization = this.formulaForm.get('numberAuthorization').value;

    if (numberFormula.length >= 22) {
      this.formulaForm.get('numberFormula').setValue(numberFormula.slice(0, 22));
    }

    if (numberAuthorization.length >= 22) {
      this.formulaForm.get('numberAuthorization').setValue(numberAuthorization.slice(0, 22));
    }
  }

  /*INFORMATION BETWEEN COMPONENTS BEHAVIOR*/
  nextSubject() {
    this.tagContinue();
    this.getFormulaE$.next(this.sendFormula);
    this.getFormulaESearch$.next(this.formulasSea);
    this.getNoPbsApply();
    this.redirect(this.noPbsApply);
  }

  getNoPbsApply() {
    this.sendFormula.forEach(formula => {
      if ((formula.authorization != null && formula.authorization != '') || (formula.mipres != null && formula.mipres != '')) {
        this.noPbsApply = true;
      }
    });
  }

  redirect(noPbsApply: boolean) {
    let formulaNoPbsPreload: boolean = false;
    if(this.objFormulaNoPbs.length > 0) {
      formulaNoPbsPreload = true;
    }

    this.activateSearch();
    if (!(noPbsApply && this.maxSize) || (noPbsApply && !this.maxSize) || (!noPbsApply) || formulaNoPbsPreload) {
      if (localStorage.getItem('editar') == 'verificacion' && !formulaNoPbsPreload) {
        this._router.navigate(['/verificacion-pedido'], {
          relativeTo: this._route
        });
      } else if (noPbsApply || formulaNoPbsPreload) {
        this._router.navigate(['/formula-no-pbs'], {
          relativeTo: this._route
        });
      } else {
        this.getAttachedFormulaNoPbsPreload$.next([]);
        this.getAttachedFormulaNoPbs$.next(null)
        this._router.navigate(['/localizar-direccion'], {
          relativeTo: this._route
        });
      }
    }
  }

  ngAfterContentChecked(): void {
    this._CHANGEDECTOR.markForCheck();
  }

  public getFormula() {
    this._formulaService.getFormula(this.vulnerable.identificacion, this.vulnerable.tipoIdentificacion, this.eps.id, 'NO ENTREGADO', '', '').subscribe(res => {
      if (res.codigo === '200') {
        this.formulas = res.obtenerPrescripcion.formula;
        this.viewElectronicsFormala = false;
        this.spinner = false;
      } else {
        this.viewElectronicsFormala = true;
        this.spinner = false;
        this.sendFormula = [];
        this.buttonContinueFoE = false;
      }
    })
  }

  public attachedFormulaLoaded(event) {
    this.showLoadImage = event;
    this.formulaForm.get('authorization').setValue('');
    this.formulaForm.get('numberFormula').setValue('');
    this.formulaForm.get('numberAuthorization').setValue('');
    this.buttonContinueFoESearch = true;
    this.fieldsAuth = false;
    this.sectionForm = false;
    this.sectionImage = false;
    this.enableContinueAttachment = false;
  }

  public sendFormulaSelected(event) {
    this.sendFormula.push(this.fillFormulas(event));
    this.buttonContinueFoE = true;
  }

  public sendFormulaDeselected(event) {
    const index: number = this.sendFormula.indexOf(this.sendFormula.find(x => x.number === event.formula.numero));
    if (index !== -1) {
      this.sendFormula.splice(index, 1);
    }

    if (this.sendFormula.length == 0) {
      this.buttonContinueFoE = false;
      /*this.orderFormulaNoPbs = null;
      this.objFormulaNoPbs = [];*/
    }
  }

  public fillFormulas(event): Formula {
    var formula = new Formula();
    formula.number = event.formula.numero;
    formula.mipres = event.formula.mipres;
    formula.dateService = event.formula.fechaServicio;
    formula.status = event.formula.status;
    formula.authorization = event.formula.autorizacion;
    if (event.formula.dispensacion !== null && event.formula.dispensacion.codigo !== null && event.formula.dispensacion.codigo !== '') {
      var dispensacion = new Dispensing();
      dispensacion.branchEnlistment = event.formula.dispensacion.alistamientoSucursal;
      dispensacion.branchOffice = event.formula.dispensacion.alistamientoSucursal;
      dispensacion.domicile = event.formula.dispensacion.domicilio;
      dispensacion.code = event.formula.dispensacion.codigo;
      dispensacion.subCode = event.formula.dispensacion.subCodigo;
      dispensacion.city = event.formula.dispensacion.domicilio;
      dispensacion.phone = event.formula.dispensacion.telefono;
      dispensacion.mobile = event.formula.dispensacion.celular;
      dispensacion.email = event.formula.dispensacion.correoElectronico;
      dispensacion.timeZone = event.formula.dispensacion.franjaHoraria;
      dispensacion.receptionManager = event.formula.dispensacion.encargadoRecepcion;
      formula.dispensation = dispensacion;
    }
    var list: Array<Treatment> = [];
    event.formula.tratamiento.forEach((tratamiento: ITratamiento) => {
      var treatment = new Treatment();
      treatment.idSubscriptionManager = tratamiento.id;
      treatment.order = tratamiento.orden;
      treatment.deliveryNumber = tratamiento.numeroEntrega;
      treatment.quantity = tratamiento.cantidad;
      treatment.value = tratamiento.valor;
      treatment.expirationDate = tratamiento.fechaVencimiento;
      treatment.preauthorization = tratamiento.preautorizacion;
      treatment.status = tratamiento.estado;
      if (tratamiento.pago != null) {
        treatment.paymentValue = tratamiento.pago.valor;
        treatment.paymentPercentage = tratamiento.pago.porcentaje;
      }
      if (tratamiento.dosis != null) {
        var dose = new Dose();
        dose.quantity = tratamiento.dosis.cantidad;
        if (tratamiento.dosis.unidad != null) {
          dose.unitCode = tratamiento.dosis.unidad.codigo;
          dose.unitName = tratamiento.dosis.unidad.nombre;
        }
        if (tratamiento.dosis.periodo != null) {
          dose.periodQuantity = tratamiento.dosis.periodo.cantidad;
          if (tratamiento.dosis.periodo.unidad != null) {
            dose.periodCode = tratamiento.dosis.periodo.unidad.codigo;
            dose.periodName = tratamiento.dosis.periodo.unidad.nombre;
          }
        }
        if (tratamiento.dosis.duracion != null) {
          dose.durationQuantity = tratamiento.dosis.duracion.cantidad;
          if (tratamiento.dosis.duracion.unidad != null) {
            dose.durationCode = tratamiento.dosis.duracion.unidad.codigo;
            dose.durationName = tratamiento.dosis.duracion.unidad.nombre;
          }
        }
        dose.specialIndications = tratamiento.dosis.indicacionesEspeciales;
        treatment.dose = dose;
      }
      var product = new Product();
      product.mapis = tratamiento.producto.mapis;
      product.description = tratamiento.producto.descripcion;
      product.cum = tratamiento.producto.cum;
      if (tratamiento.producto.tecnologia != null) {
        product.typeTechnology = tratamiento.producto.tecnologia.tipo;
        product.technologyCode = tratamiento.producto.tecnologia.codigo;
      }
      product.concentration = tratamiento.producto.concentracion;
      product.numbeDeliveries = tratamiento.producto.cantidadDeEntregas;
      treatment.product = product;
      list.push(treatment);
    });
    formula.treatment = list;

    return formula;
  }

  public activateSearch() {
    this.authorizationRadio = true;
    this.duplicateNumber = false;

    let suma = 0;

    this.files.forEach(res => {
      suma += res.sizeTotal
    })

    if(this.orderFormulaNoPbs !== null) {
      this.orderFormulaNoPbs.files.forEach(res => {
        suma += res.size
      })
    }

    if (suma <= environment.SIZE_TOTAL_FILES) {
      this.sectionForm = true;
      this.maxSize = false;
    } else {
      this.sectionForm = false;
      this.maxSize = true;
    }
  }

  public activateFieldsAuth(mostrar: boolean) {
    this.fieldsAuth = true;

    if (mostrar == true) {
      this.formulaForm.get('numberAuthorization').setValidators([Validators.required, Validators.pattern(RegularExpressions.VALIDATE_AT_LEAST_ONE_NUMBER)]);
      this.formulaForm.get('numberAuthorization').setValue('');
      this.showInput = true;
    } else {
      this.formulaForm.get('numberAuthorization').clearValidators();
      this.formulaForm.get('numberAuthorization').updateValueAndValidity();
      this.formulaForm.get('numberAuthorization').setValue('');
      this.showInput = false;
    }
  }

  public searchFormula() {
    /*this._formulaService.getFormula(
      this.vulnerable.identificacion,
      this.vulnerable.tipoIdentificacion,
      this.eps.id, 'NO ENTREGADO',
      this.formulaForm.get('numberFormula').value,
      this.formulaForm.get('numberAuthorization').value
      ).subscribe(res => {
        if (res.codigo === '200') {
          this.formulaObjectSe['formula'] = res.obtenerPrescripcion.formula[0];
          this.formulasSea.push(this.fillFormulas(this.formulaObjectSe));
          this.formulaForm.get('authorization').setValue('');
          this.formulaForm.get('numberFormula').setValue('');
          this.formulaForm.get('numberAuthorization').setValue('');
          this.buttonContinueFoESearch = true;
          this.fieldsAuth = false;
          this.sectionForm = false;
        } else {
          console.log('No se encontro ninguna fórmula');
        }
    })*/
  }

  public loadImage() {
    this.enableContinueAttachment = false;
    this.attachedForm.get('see').setValue(false)

    if (this.files.length > 0) {
      this.files.every(res => {
        let number = this.formulaForm.get('numberFormula').value;
        if (res.number.trim() == number.trim()) {
          this.sectionImage = false;
          this.showLoadImage = true;
          this.enableContinueAttachment = false;
          this.duplicateNumber = true;
          return false
        } else {
          this.sectionImage = true;
          this.enableContinueAttachment = true;
          this.showLoadImage = false;
          localStorage.setItem('numberFormula', this.formulaForm.get('numberFormula').value);
          localStorage.setItem('numberAuthorization', this.formulaForm.get('numberAuthorization').value);
        }
        return true
      });
    } else {
      this.sectionImage = true;
      this.showLoadImage = false;
      this.enableContinueAttachment = true;

      localStorage.setItem('numberFormula', this.formulaForm.get('numberFormula').value);
      localStorage.setItem('numberAuthorization', this.formulaForm.get('numberAuthorization').value);
    }
  }

  public sendFileDeselected(event, file?) {
    if (event.target.checked) {
      this.files.forEach(e => {
        if (e.number == file.number) {
          e.check = true;
        }
      });

      this.selectedPhotos.push(file);
    } else {
      this.files.forEach(e => {
        if (e.number == file.number) {
          e.check = false;
        }
      });

      this.selectedPhotos = this.selectedPhotos.filter(x => {
        return x.number != file.number;
      })
    }

    this.getFormulaA$.next(this.files);
    this.getFormulaAS$.next(this.selectedPhotos);

    if (this.selectedPhotos.length > 0) {
      this.disabledSelectedFile = true;
    } else {
      this.disabledSelectedFile = false;
    }
  }

  public deleteFile(index: number, file) {
    this.files.splice(index, 1);

    this.selectedPhotos = this.selectedPhotos.filter(x => {
      return x.number != file.number;
    })

    this.getFormulaA$.next(this.files);
    this.getFormulaAS$.next(this.selectedPhotos);

    if (this.selectedPhotos.length > 0) {
      this.disabledSelectedFile = true;
    } else {
      this.disabledSelectedFile = false;
    }
  }

  public despliegue() {
    this.showDes = !this.showDes;
  }

  public close() {
    this.showDes = false;
  }

  public tagUrl() {
    dataLayer.push({
      'event': 'ga_virtual_Page',
      'titleVp': 'Cargaformula',
      'urlVp': '/vp/cargaformulamedica'
    });
  }

  public tagContinue() {
    dataLayer.push({
      'event': 'ga_event_formula',
      'category': 'carga_formula',
      'action': 'Clic-boton',
      'label': 'continuar_carga',
      'UES': 'Medicamentos'
    });
  }

  public tagButtonClickToCall(){
    dataLayer.push({
      'event':'ga_virtual_Page',
      'titleVp':'Selección formula',
      'urlVp':'/vp/seleccionformula'
    });
  }

  public tagPopUpClickToCall(){
    dataLayer.push({
      'event':'ga_virtual_Page',
      'titleVp':'PopUpAtención Telefonica',
      'urlVp':'/vp/atenciontelefonica'
    });
  }


  public seeChange(id) {
    const see = this.attachedForm.get('see').value;

    let bikeImage = document.getElementById('see-' + id) as HTMLImageElement;
    let height = document.getElementById(id + 'info') as HTMLImageElement;


    if (see == '' || see == false) {
      bikeImage.src = './assets/icons/formula/ver-mas.svg';
      height.style.height = '112px';
    } else {
      bikeImage.src = './assets/icons/formula/ver-menos.svg';
      height.style.height = 'auto';
    }
  }

  public modal(ext, src, name) {
    this.imageModal = src;
    this.ext = ext;
    this.nameFile = name;

    setTimeout(() => {
      this._modal.openModal(this.idModal);
    }, 60);
  }
  public openModal(modalType) {
    this.tagButtonClickToCall();
    this.tagPopUpClickToCall();
    this.modalType = modalType;
    this._modal.openModal(this.idModalHelp);
  }

  getParameterKey(key: string) {
    this._parameterService.getParameters(key).subscribe(
      response => {
        if(response.parametro != null && response.parametro != undefined){
          if(response.parametro.valor != null && response.parametro.valor != undefined && response.parametro.valor =='1' ){
            this.showHelpButton = true;
          }else{
            this.showHelpButton = false;
          }
        }else{
          this.showHelpButton = false;
        }
      }, err =>{
        this.showHelpButton = false;
      }

    );
  }


  getParameterTel(key: string) {
    this._parameterService.getParameters(key).subscribe(
      response => {
        if(response.parametro != null && response.parametro != undefined){
          if(response.parametro.valor != null && response.parametro.valor != undefined ){
            this.telephone = response.parametro.valor;
          }else{
            this.telephone = environment.DEFAULT_NUMBER_CLICK_TO_CALL;
          }
        }else{
          this.telephone = environment.DEFAULT_NUMBER_CLICK_TO_CALL;
        }
      }, err =>{
        this.telephone = environment.DEFAULT_NUMBER_CLICK_TO_CALL;
      }

    );
  }

}
