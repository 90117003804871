import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-continue',
  templateUrl: './btn-continue.component.html',
  styleUrls: ['./btn-continue.component.scss']
})
export class BtnContinueComponent implements OnInit {

  @Input() continue: any;
  @Input() form: any;

  constructor() { }

  ngOnInit(): void {
  }

}
