import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { CardComponent } from './utils/card/card.component';
import { TitleComponent } from './utils/title/title.component';
import { BtnContinueComponent } from './utils/btn-continue/btn-continue.component';
import { BtnBehindComponent } from './utils/btn-behind/btn-behind.component';
import { ModalExitComponent } from './utils/modal-exit/modal-exit.component';
import { SelectComponent } from './utils/select/select/select.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ProgressComponent } from './utils/progress/progress.component';
import { PageProgressComponent } from './utils/page-progress/page-progress.component';
import { ModalImagesComponent } from './utils/modal-images/modal-images.component';
import { ModalGenericComponent } from './utils/modal-generic/modal-generic.component';
import { ModalHomeComponent } from './utils/modal-home/modal-home.component';
import { ModalClickToCallComponent } from './utils/modal-click-to-call/modal-click-to-call.component';

@NgModule({
  declarations: [
    FormErrorsComponent,
    CardComponent,
    TitleComponent,
    BtnContinueComponent,
    BtnBehindComponent,
    ModalExitComponent,
    ModalHomeComponent,
    SelectComponent,
    CarouselComponent,
    ProgressComponent,
    PageProgressComponent,
    ModalImagesComponent,
    ModalGenericComponent,
    ModalClickToCallComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FormErrorsComponent,
    CardComponent,
    TitleComponent,
    BtnContinueComponent,
    BtnBehindComponent,
    ModalExitComponent,
    ModalHomeComponent,
    SelectComponent,
    CarouselComponent,
    ProgressComponent,
    PageProgressComponent,
    ModalImagesComponent,
    ModalGenericComponent,
    ModalClickToCallComponent,
  ]
})
export class SharedModule { }
