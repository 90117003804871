import { Component,OnInit } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import { environment } from 'src/app/configs/environment';
import { AppConfig } from 'src/app/configs/app-config';
import { JwUser } from 'src/app/core/models/jw-user/jw-user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getPatient$ = this._behaviorSubjectService.getPatient();
  getEps = this._behaviorSubjectService.getEps();
  getElectronicFormula = this._behaviorSubjectService.getElectronicFormula();
  getAttachedFormulaSelected = this._behaviorSubjectService.getAttachedFormulaSelected();
  getAttachedFormula = this._behaviorSubjectService.getAttachedFormula();
  getSelectAddress = this._behaviorSubjectService.getSelectAddress();
  getAddress = this._behaviorSubjectService.getAddress();
  getContact = this._behaviorSubjectService.getContact();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
  getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();

  disableClose= false;

  constructor(
    private _modal: ModalService,
    private _behaviorSubjectService: BehaviorSubjectService,
    private _auth :AuthService,
    private _localStorage: LocalStorageService,
    private router: Router
  ) {
  }

  ngOnInit() {
    console.log(this.router.url);
    this.disableClose = this.router.url.includes('gestionar-pedido');
  }

  modal() {
    this._modal.openModal('inicio');
  }

  nextSubject() {
    this.getPatientVulnerable$.next(null);
    this.getPatient$.next(null);
    this.getEps.next(null);
    this.getElectronicFormula.next([]);
    this.getAttachedFormula.next([]);
    this.getAttachedFormulaSelected.next([]);
    this.getSelectAddress.next(null);
    this.getAddress.next(null);
    this.getContact.next(null);
    this.getAttachedFormulaNoPbs$.next(null);
    this.getAttachedFormulaNoPbsPreload$.next([]);
  }

  async logout() {
    this._auth.logout(this._localStorage.parseItem<JwUser>(AppConfig.TOKEN_NAME_JTWT()).customer.tokenId);
  }

}
