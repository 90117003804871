import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { environment } from 'src/app/configs/environment';
import { Router, ActivatedRoute } from '@angular/router';

import { AddressService } from 'src/app/core/services/address/address.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { PatientService } from 'src/app/core/services/patient/patient.service';

import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { Department } from 'src/app/core/models/department/department';
import { City } from 'src/app/core/models/city/city';
import { Address } from 'src/app/core/models/address/address';
import { Abbreviation } from 'src/app/core/models/abbreviation/abbreviation';

declare let dataLayer;
interface AddressData {id:number, addressParte1: string;  addressParte2: string;  addressParte3: string;  addressParte4: string;  addressParte5: string;  addressParte6: string;  addressParte7: string;  addressParte8: string;  addressParte9: string; generatedAddress:string}

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponent implements OnInit {

  icon = './assets/icons/arrow-maps.svg';
  title = 'Ingresa la dirección';
  subtitle = 'Donde enviaremos tus medicamentos.';
  idPatient: string;
  public idAddress: any;
  progress = 49.8;

  addressForm: FormGroup;
  newAddress: boolean;
  updaAddress: Address;
  activate: boolean = false;
  disabled: boolean = false;
  enableTimeZone: boolean = false;

  addressData: AddressData = { id:0,addressParte1: '', addressParte2: '',    addressParte3: '',    addressParte4: '',    addressParte5: '',    addressParte6: '',    addressParte7: '',    addressParte8: '',    addressParte9: '', generatedAddress:'' };


  /*VARIBALES SERVICES*/
  departments: Department[] = [];
  cities: City[] = [];
  abreviatura: Abbreviation[] = [];
  address: Address[] = [];

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getEps$ = this._behaviorSubjectService.getEps();
  getAddress$ = this._behaviorSubjectService.getAddress();
  getAddresses$ = this._behaviorSubjectService.getAddresses();
  getSelectAddress$ = this._behaviorSubjectService.getSelectAddress();
  order: any;
  generatedAddress: string = '';
  alphabet: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  bis: string[] = ['BIS'];
  cardinalPoints: string[] = ['SUR','ESTE'];

  public vulnerable: PatientVulnerable;
  public eps: Eps;
  public selectedAddress: any;
  public addresses: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _addressService: AddressService,
    private _behaviorSubjectService: BehaviorSubjectService,
    private _CHANGEDECTOR: ChangeDetectorRef,
    private _patientService: PatientService,
    private _location: Location,
  ) {
    this.addressForm = this._formBuilder.group({
      addressNomenclature: ['',],
      numberNomenclature: ['',],
      numberOne: ['',],
      numberTwo: ['',],
      name: ['', ],
      district: [''],
      comments: [''],
      adressParte1: ['',[Validators.required,Validators.pattern('[0-9]{2}')]],
      adressParte2: [''],
      adressParte3: [''],
      adressParte4: [''],
      adressParte5: [''],
      adressParte6: [''],
      adressParte7: [''],
      adressParte8: [''],
      adressParte9: ['']
    });

    
    /*SUBSCRIBE BEHAVIOR SUBJECT */
    this.vulnerable = this.getPatientVulnerable$.getValue();
    this.eps = this.getEps$.getValue();
    this.selectedAddress = this.getSelectAddress$.getValue();
    this.addresses = this.getAddresses$.getValue();
    this._CHANGEDECTOR.markForCheck();
    this.getEnableTimeZone();

  }

  ngOnInit(): void {
    this.tagUrl();

    this.selectAddress();
    this._CHANGEDECTOR.markForCheck();

    this.order = this.getAddress$.getValue();

    if (this.order) {
      this.setValue();
    }
   
  }

  generateAddress() {
   
    const addressNomenclature = this.addressForm.get('addressNomenclature')?.value;
    const part1 = this.addressForm.get('adressParte1')?.value;
    const part2 = this.addressForm.get('adressParte2')?.value;
    const part3 = this.addressForm.get('adressParte3')?.value;
    const part4 = this.addressForm.get('adressParte4')?.value;
    const part5 = this.addressForm.get('adressParte5')?.value;
    const part6 = this.addressForm.get('adressParte6')?.value;
    const part7 = this.addressForm.get('adressParte7')?.value;
    const part8 = this.addressForm.get('adressParte8')?.value;
    const part9 = this.addressForm.get('adressParte9')?.value;
    let buildAddress = '';

    if (addressNomenclature) {
      buildAddress += addressNomenclature+" ";
    }
    if (part1) {
      buildAddress += part1;
      this.addressData.addressParte1=part1;
    }
    if (part2) {
      buildAddress += part2+" ";
      this.addressData.addressParte2=part2;
    }
    if (part3) {
      buildAddress += part3+" ";
      this.addressData.addressParte3=part3;
    }
    if (part4) {
      buildAddress += part4+" ";
      this.addressData.addressParte4=part4;
    }
    if (part5) {
      buildAddress += part5+" ";
      this.addressData.addressParte5=part5;
    }
    if (part6) {
      buildAddress += " #"+part6+"";
      this.addressData.addressParte6=part6;
    }
    if (part7) {
      buildAddress += part7+" ";
      this.addressData.addressParte7=part7;
    }
    if (part8) {
      buildAddress += "-"+part8+" ";
      this.addressData.addressParte8=part8;
    }
    if (part9) {
      buildAddress += part9+" ";
      this.addressData.addressParte9=part9;
    }

    this.generatedAddress = buildAddress;
  }


  public setValue() {
    this.addressForm.setValue({
      addressNomenclature: this.order.addressNomenclature,
      numberNomenclature: this.order.numberNomenclature,
      numberOne: this.order.numberOne,
      numberTwo: this.order.numberTwo,
      name: this.order.name,
      district: this.order.district,
      comments: this.order.comments,
    });

    this.idAddress = this.order.idAddress;
  }

  public ngAfterContentChecked(): void {
    this._CHANGEDECTOR.markForCheck();
  }

  public getPatient() {
    this._patientService.getPatient(this.vulnerable.tipoIdentificacion, this.vulnerable.identificacion).subscribe(
      response => {
        this.idPatient = response.patient[0].id;
      }
    );
  }

  public selectAddress() {
    this.getAbreviatura();
    this.getPatient();

    if (this.selectedAddress != null && this.selectedAddress.address == 'nueva') {
      this.resetValue();
    } else if (this.addresses.newAddress == true) {
      if (this.addresses.newAddress) {
        this.resetValue();
      }
    } else {
      this.addresses.addresses.forEach(e => {

        if (e.idDireccion == this.selectedAddress.address) {
          this.updaAddress = e;
          this.addressForm.get('name').setValue(this.updaAddress.nombre);
          this.addressForm.get('district').setValue(this.updaAddress.barrio);
          this.addressForm.get('comments').setValue(this.updaAddress.comentarios);

          const savedData = sessionStorage.getItem('addressData');
          if (savedData) {
            this.addressData = JSON.parse(savedData);
            const id = Number(this.addressData.id);
            if(id === e.idDireccion){
              this.addressForm.get('adressParte1').setValue(Number(this.addressData.addressParte1));
              this.addressForm.get('adressParte2').setValue(this.addressData.addressParte2);
              this.addressForm.get('adressParte3').setValue(this.addressData.addressParte3);
              this.addressForm.get('adressParte4').setValue(this.addressData.addressParte4);
              this.addressForm.get('adressParte5').setValue(this.addressData.addressParte5);
              this.addressForm.get('adressParte6').setValue(Number(this.addressData.addressParte6));
              this.addressForm.get('adressParte7').setValue(this.addressData.addressParte7);
              this.addressForm.get('adressParte8').setValue(Number(this.addressData.addressParte8));
              this.addressForm.get('adressParte9').setValue(this.addressData.addressParte9);
              this.generatedAddress = this.addressData.generatedAddress;
            }
          }

          let separationNumeral = this.updaAddress.direccion.split('#');
          let separationSpaces = separationNumeral[0].split(' ');
          let abbreviationNumber = ''
          var i;
          for (i = 1; i < separationSpaces.length; i++) {
            abbreviationNumber = abbreviationNumber + ' ' + separationSpaces[i];
          }
          let separationHyphen = separationNumeral[1].split('-')

          let secondPartAddress = ''
          var i;
          for (i = 1; i < separationHyphen.length; i++) {
            secondPartAddress = secondPartAddress + ' ' + separationHyphen[i];
          }

          this.addressForm.get('addressNomenclature').setValue(separationSpaces[0]);
          this.addressForm.get('numberNomenclature').setValue(abbreviationNumber);
          this.addressForm.get('numberOne').setValue(separationHyphen[0]);
          this.addressForm.get('numberTwo').setValue(secondPartAddress);

          this.idAddress = this.updaAddress.idDireccion;
        }


      });


    }
  }

  public resetValue() {
    this.addressForm.get('addressNomenclature').setValidators([Validators.required]);
    this.addressForm.get('addressNomenclature').setValue('');
    this.addressForm.get('adressParte1').setValidators([Validators.required]);
    this.addressForm.get('adressParte1').setValue('');
    this.addressForm.get('adressParte2').setValue('');
    this.addressForm.get('adressParte3').setValue('');
    this.addressForm.get('adressParte4').setValue('');
    this.addressForm.get('adressParte5').setValue('');
    this.addressForm.get('adressParte6').setValidators([Validators.required]);
    this.addressForm.get('adressParte6').setValue('');
    this.addressForm.get('adressParte7').setValue('');
    this.addressForm.get('adressParte8').setValidators([Validators.required]);
    this.addressForm.get('adressParte8').setValue('');
    this.addressForm.get('adressParte9').setValue('');
    

    
    //this.addressForm.get('numberNomenclature').setValidators([Validators.required]);
    //this.addressForm.get('numberOne').setValidators([Validators.required]);
   //this.addressForm.get('numberTwo').setValidators([Validators.required]);
    this.addressForm.get('name').setValidators([Validators.required]);
    this.addressForm.get('name').setValue('');
    
    // this.addressForm.get('numberNomenclature').setValue('');
    // this.addressForm.get('numberOne').setValue('');
    // this.addressForm.get('numberTwo').setValue('');

    this.addressForm.get('district').setValue('');
    this.addressForm.get('comments').setValue('');
  }

  public getAbreviatura() {
    this._addressService.getAbreviatura().subscribe(
      response => {
        this.abreviatura = response.nomenclaturas;
      }
    );
  }

  private createAddress() {
    const addAddress = `${this.addressForm.get('addressNomenclature').value} ${this.addressForm.get('numberNomenclature').value} #${this.addressForm.get('numberOne').value} - ${this.addressForm.get('numberTwo').value}`;
    const addAddressnew = this.generatedAddress+", "+ this.addresses.city+", "+"Colombia";
   
    let address = {
      direccion: addAddressnew,
      nombre: this.addressForm.get('name').value,
      idPaciente: this.idPatient,
      tipoIdentificacion: this.vulnerable.tipoIdentificacion,
      identificacion: this.vulnerable.identificacion,
      idCiudad: this.addresses.idCity,
      comentarios: this.addressForm.get('comments').value,
      canal: environment.CHANNEL,
      disponibilidad: '1',
      barrio: this.addressForm.get('district').value,
      tipo: environment.TYPE,
      coordenadas: {
        latitud: null,
        longitud: null
      }
    }

    this._addressService.createAddress(address).subscribe(
      response => {
        this.idAddress = response['idDireccion'];
        this.saveToSessionStorage(this.idAddress);
        setTimeout(() => {
          this.getFormData();
          this.getAddress$.next(this.order);
        }, 10);

        setTimeout(() => {
          if (localStorage.getItem('editar') == 'verificacion') {
            this._router.navigate(['/verificacion-pedido'], {
              relativeTo: this._route
            });
          } else {
            if (this.enableTimeZone) {
              this._router.navigate(['/programar-entrega'], {
                relativeTo: this._route
              });
            } else {
              this._router.navigate(['/contacto'], {
                relativeTo: this._route
              });
            }
          }
        }, 30);
      }
    );

    this.activate = true
  }

  saveToSessionStorage(id:any): void {
    this.addressData.id=id;
    sessionStorage.setItem('addressData', JSON.stringify(this.addressData));
  }

  private updateAddress() {
    const addAddress = `${this.addressForm.get('addressNomenclature').value} ${this.addressForm.get('numberNomenclature').value} #${this.addressForm.get('numberOne').value} - ${this.addressForm.get('numberTwo').value}`;
    const addAddressnew = this.generatedAddress+", "+ this.addresses.city+", "+"Colombia";

    let address = {
      idDireccion: this.idAddress,
      direccion: addAddressnew,
      nombre: this.addressForm.get('name').value,
      idCiudad: this.addresses.idCity,
      comentarios: this.addressForm.get('comments').value,
      barrio: this.addressForm.get('district').value,
      canal: environment.CHANNEL,
      tipo: environment.TYPE,
      coordenadas: {
        latitud: null,
        longitud: null
      }
    };

    this._addressService.updateAddress(address).subscribe(
      response => {
        this.idAddress = this.idAddress;
        this.saveToSessionStorage(this.idAddress);
        setTimeout(() => {
          this.getFormData();
          this.getAddress$.next(this.order);
        }, 10);

        setTimeout(() => {
          if (this.enableTimeZone) {
            this._router.navigate(['/programar-entrega'], {
              relativeTo: this._route
            });
          } else {
            this._router.navigate(['/contacto'], {
              relativeTo: this._route
            });
          }
        }, 30);
      }
    );

    this.activate = true
  }

  public getFormData() {
    this.order = {
      idAddress: this.idAddress,
      name: this.addressForm.get('name').value,
      idCity: this.addresses.idCity,
      comments: this.addressForm.get('comments').value,
      channel: environment.CHANNEL,
      city: this.addresses.city,
      idDepartment: this.addresses.idDepartment,
      department: this.addresses.department,
      addressNomenclature: this.addressForm.get('addressNomenclature').value,



      
      numberNomenclature: this.addressForm.get('numberNomenclature').value,
      numberOne: this.addressForm.get('numberOne').value,
      numberTwo: this.addressForm.get('numberTwo').value,
      district: this.addressForm.get('district').value,
    }
  }

  public toReturn() {
    this._location.back();
  }

  /*INFORMATION BETWEEN COMPONENTS BEHAVIOR*/
  nextSubject() {
    const addAddress = `${this.addressForm.get('addressNomenclature').value} ${this.addressForm.get('numberNomenclature').value} #${this.addressForm.get('numberOne').value} - ${this.addressForm.get('numberTwo').value}`;
    const addAddressNew = this.generatedAddress+", "+ this.addresses.city+", "+"Colombia";
  
    localStorage.setItem('direccion', addAddressNew)

    this.tagContinue(this.addresses.department, this.addresses.city, addAddressNew);

    if (this.selectedAddress != null) {
      if (this.selectedAddress.newAddress && localStorage.getItem('updateA') == 'false') {
        this.createAddress();
        localStorage.setItem('updateA', 'true');
      } else {
        this.updateAddress();
      }
    } else if (this.addresses.newAddress == true) {
      if (this.addresses.newAddress && localStorage.getItem('updateA') == 'false') {
        this.createAddress();
        localStorage.setItem('updateA', 'true');
      } else {
        this.updateAddress();
      }
    }
  }

  getEnableTimeZone(){
    if(localStorage.getItem('enableSchedule') == 'true'){
      this.enableTimeZone = true;
    }else{
      this.enableTimeZone = false;
    }
  }



  public tagUrl() {
    dataLayer.push({
      'event': 'ga_virtual_Page',
      'titleVp': 'Datosgeograficos',
      'urlVp': '/vp/datosgeograficos'
    });
  }

  public tagContinue(department, city, address) {
    dataLayer.push({
      'event': 'ga_event_datosgeograficos',
      'category': 'datos_geograficos',
      'action': 'Clic- boton',
      'label': 'continuar_geográficos',
      'UES': 'Medicamentos',
      'Departamento': department,
      'Ciudad': city,
      'Direccion': address
    });
  }

  restrictInput(event: any) {
    const keyCode = event.keyCode || event.which;
    const validKeys = [8, 9, 37, 39]; 
    const isNumber = (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
  
    if (!isNumber && !validKeys.includes(keyCode)) {
      event.preventDefault();
    }
  }
}
