import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Location } from '@angular/common';

import { PatientService } from 'src/app/core/services/patient/patient.service';
import { EpsService } from 'src/app/core/services/eps/eps.service';
import { ParameterService } from 'src/app/core/services/parameter/parameter.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';

import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { TypeDocument } from 'src/app/core/models/type-document/type-document';
import { Patient, GetPatient } from 'src/app/core/models/patient/patient';


import { Eps } from 'src/app/core/models/eps/eps';
import { Router, ActivatedRoute } from '@angular/router';
import { Parameter } from 'src/app/core/models/parameter/parameter';
import { RegularExpressions } from 'src/app/core/helpers/regular-expressions/regular-expressions';
import { InformationFormatting } from 'src/app/core/helpers/information-formatting/information-formatting';
import { JwUser } from 'src/app/core/models/jw-user/jw-user';
import { JwtService } from 'src/app/core/services/jwt.service';
import { environment } from 'src/app/configs/environment';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { ContentManagerService } from 'src/app/core/services/content-manager/content-manager.service';

declare let dataLayer;

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {
  icon = './assets/icons/documento.svg';
  title = 'Para iniciar, necesitamos algunos datos';
  subtitle = 'Relaciona los datos básicos del paciente.';

  patientForm: FormGroup;
  enabledContinue: boolean = true;
  showFields: boolean = false;
  progress = 16.6;
  public listIdentifications: TypeDocument[];
  document: any;
  sessionSSO: JwUser;
  documentSSO: string;
  modalTitle: String = "";
	modalDescription: String = "";
	idModal = "modalDomicileCost";

  /*VARIABLES SERVICES*/
  public identificationType: string;
  public identification: string;
  public patientClass: Patient;
  public patient: GetPatient;
  public vulnerable: PatientVulnerable;
  public eps: Eps;
  public parameter: Parameter;
  public recentToken: string = '';

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  //getPatient$ = this._behaviorSubjectService.getPatient();
  getEps$ = this._behaviorSubjectService.getEps();
  getElectronicFormula$ = this._behaviorSubjectService.getElectronicFormula();
  getElectronicFormulaS$ = this._behaviorSubjectService.getElectronicSearchFormula();
  getAttachedFormula$ = this._behaviorSubjectService.getAttachedFormula();
  getAttachedFormulaS$ = this._behaviorSubjectService.getAttachedFormulaSelected();
  getSelectAddress$ = this._behaviorSubjectService.getSelectAddress();
  getAddress$ = this._behaviorSubjectService.getAddress();
  getContact$ = this._behaviorSubjectService.getContact();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
  getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _patientService: PatientService,
    private _epsService: EpsService,
    private _parameterService: ParameterService,
    private _behaviorSubjectService: BehaviorSubjectService,
    private _recaptchaV3Service: ReCaptchaV3Service,
    private _location: Location,
    private _jwtService: JwtService,
    private _modal: ModalService,
    private _yiiService: ContentManagerService
  ) {
    this.loadDomicileMessages();
    this.patientForm = this._formBuilder.group({
      typeIdentification: [
        '',
        [
          Validators.required
        ]
      ],
      identification: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15)
        ]
      ],
      termsHabeas1: [
        '',
        [Validators.required]
      ],
      termsHabeas2: [
        '',
        []
      ]
    });
  }

  ngOnInit(): void {
    this.tagUrl();
    this.fillDocumentTypes();
    this.getParameterKey(environment.KEY_WHITE_LIST_PORTAL);

    if (this.vulnerable != undefined) {
      this.patientForm.get('typeIdentification').setValue(this.vulnerable.tipoIdentificacion);
      this.patientForm.get('identification').setValue(this.vulnerable.identificacion);
      this.patientForm.get('termsHabeas1').setValue(this.vulnerable.terminos);
    } else if (this.vulnerable == null) {
      this.identificationValidations('CC');
      this.patientForm.get('typeIdentification').setValue('CC')
    }
  }

  /*SERVICES*/
  public fillDocumentTypes() {
    this._patientService.getTypeDocuments().subscribe(
      response => {
        this.listIdentifications = response.TipoDocumento;
        this.showFields = true;
      }
    );
  }

  public patientVulnerable() {
    this._patientService.getPatientVulnerable(this.patientForm.get('typeIdentification').value, this.patientForm.get('identification').value).subscribe(
      response => {
        if (response.pacientes) {
          this.vulnerable = response.pacientes[0];
          this.enabledContinue = true;
          this.epsNit();
        } else {
          this.vulnerable = null;
          this.scenariosContinue()
        }
      }
    );
  }

  public epsNit() {
    this._epsService.getEpsNit(this.vulnerable.eps).subscribe(
      response => {
        this.eps = response.eps[0];

        this.scenariosContinue();
      }
    );
  }

  getParameterKey(key: string) {
    this._parameterService.getParameters(key).subscribe(
      response => {
        this.parameter = response.parametro;
      }

    );
  }


  identificationValidations(tid) {
    this.patientForm.get('identification').setValue('');
    if (tid == 'CC' || tid == 'RC') {
      this.identification = 'number';
      this.patientForm.get('identification').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(10), Validators.pattern(RegularExpressions.ONLY_NUMBERS)]);
      this.patientForm.get('identification').updateValueAndValidity();
    } else if (tid == 'CE' || tid == 'PE') {
      this.identification = 'number';
      this.patientForm.get('identification').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(15), Validators.pattern(RegularExpressions.ONLY_NUMBERS)]);
      this.patientForm.get('identification').updateValueAndValidity();
    } else if (tid == 'TI') {
      this.identification = 'number';
      this.patientForm.get('identification').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(11), Validators.pattern(RegularExpressions.ONLY_NUMBERS)]);
      this.patientForm.get('identification').updateValueAndValidity();
    } else if (tid == 'PA') {
      this.identification = 'text';
      this.patientForm.get('identification').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(15), Validators.pattern(RegularExpressions.ONLY_NUMBERS_AND_LETTERS)]);
      this.patientForm.get('identification').updateValueAndValidity();
    }
  }

  fillTermsHabeas1() {
    if (!this.patientForm.get('termsHabeas1').value) {
      this.patientForm.get('termsHabeas1').setValue('');
    }
  }

  fillTermsHabeas2() {
    if (!this.patientForm.get('termsHabeas2').value) {
      this.patientForm.get('termsHabeas2').setValue('');
    }
  }

  toReturn() {
    this._router.navigate(['/inicio'], {
      relativeTo: this._route
    });
  }

  /*INFORMATION BETWEEN COMPONENTS BEHAVIOR*/
  nextSubject() {
    localStorage.setItem('editar', '');
    this.tagContinue();

    this._recaptchaV3Service.execute('ingreso')
      .subscribe((token) => {
        if (token.length) {
          return true
        } else {
          return false
        }
      });

    this.patientVulnerable();
  }

  scenariosContinue() {
    if (this.vulnerable == null) {
      this.continueNoVulnerable();
   //  this.openModal("Apreciado Usuario",this.modalDescription);
    } else {
      this.vulnerable.terminos = this.patientForm.get('termsHabeas1').value;

      if (this.eps.nit == '800088702') {
        this._router.navigate(['/redireccion-sura'], {
          relativeTo: this._route
        });
      } else {
        setTimeout(() => {
          this.getPatientVulnerable$.next(this.vulnerable);
          this.getEps$.next(this.eps);

          this._router.navigate(['/formula'], {
            relativeTo: this._route
          });
        }, 30);
      }
    }
  }

  completeSubject() {
    this.getPatientVulnerable$.complete();
  }

  behind() {
    this.enabledContinue = true;
  }

  reset() {
    //this.getPatientVulnerable$.next(null);
    //this.getPatient$.next(null);
    this.getEps$.next(null);
    this.getElectronicFormula$.next([]);
    this.getElectronicFormulaS$.next([]);
    this.getAttachedFormula$.next([]);
    this.getAttachedFormulaS$.next([]);
    this.getSelectAddress$.next(null);
    this.getAddress$.next(null);
    this.getContact$.next(null);
    this.getAttachedFormulaNoPbs$.next(null);
    this.getAttachedFormulaNoPbsPreload$.next([]);
  }

  public tagUrl() {
    dataLayer.push({
      'event': 'ga_virtual_Page',
      'titleVp': 'Inicio',
      'urlVp': '/vp/inicio'
    });
  }

  public tagContinue() {
    dataLayer.push({
      'event': 'ga_event_continuarinicio',
      'category': 'inicio',
      'action': ' Clic-boton',
      'label': 'continuar_inicio',
      'UES': 'Medicamentos'
    });
  }

  public tagNotVulnerable() {
    dataLayer.push({
      'event': 'ga_virtual_Page',
      'titleVp': 'Documentonoaplica',
      'urlVp': '/vp/documentonoaplica'
    });
  }

  getUserSSO() {
    this.sessionSSO = this._jwtService.getJwtUserSSO();
  }

  public setVulnerable() {
    let objVulnerable: PatientVulnerable;
    if (this.sessionSSO != null) {

      objVulnerable = {
        tipoIdentificacion: this.patientForm.get('typeIdentification').value,
        identificacion: this.patientForm.get('identification').value,
        nombres: this.sessionSSO.customer.firstName,
        apellidos: this.sessionSSO.customer.lastName,
        eps: '',
        correoElectronico: this.sessionSSO.customer.mail,
        celular: this.sessionSSO.customer.telephoneNumber

      }
    } else {

      objVulnerable = {
        tipoIdentificacion: this.patientForm.get('typeIdentification').value,
        identificacion: this.patientForm.get('identification').value,
        nombres: '',
        apellidos: '',
        eps: '',
        correoElectronico: '',
        celular: ''

      }
    }
    this.vulnerable = objVulnerable;
  }

  public openModal(title: String, description: String) {
		this.modalTitle = title;
		this.modalDescription = description;
		this._modal.openModal(this.idModal);
	  }
	
	  public closeModal() {

		this._modal.closeModal(this.idModal);
	  }


  public continueNoVulnerable(){
    if (this.parameter != null && this.parameter.valor == '1') {
      this.enabledContinue = false;
    } else {
     // this.getUserSSO();
      this.setVulnerable();
      this.getPatientVulnerable$.next(this.vulnerable);
      this._router.navigate(['/eps'], {
        relativeTo: this._route
      });
    }
    this.tagNotVulnerable();
  }

  public goToPreagendamiento(){
    location.href = environment.PREAGENDAMIENTO;
  }

  
  loadDomicileMessages() {
    this._yiiService.getContentYiiMessageByIdName(environment.MSG_DOMICILE_NON_VULNERABLE_POPULATION).subscribe(res => {
      this.modalDescription = res.content;
    });

  }
}
