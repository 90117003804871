import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AddressService } from 'src/app/core/services/address/address.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { Location } from '@angular/common';
import { environment } from 'src/app/configs/environment';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { Address } from 'src/app/core/models/address/address';

declare let dataLayer;

@Component({
	selector: 'app-select-address',
	templateUrl: './select-address.component.html',
	styleUrls: ['./select-address.component.scss']
})
export class SelectAddressRecurrentComponent implements OnInit {

	icon = './assets/icons/arrow-maps.svg';
	title = '¿A qué dirección llevamos los medicamentos?';
	subtitle = 'Crea una dirección o selecciona una ya creada.';
	messageDomicile = environment.MESSAGE_ADDRESS_RECURRENT;

	progress = 57;
	addressRecurrentForm: FormGroup;
	newAddress: boolean;
	updaAddress: Address;
	update: boolean;
	public idAddress: any;
	departmentName: any;
	cityName: any;
	iconEdit: boolean = false;
	idDe: string;
	addressCoverage: string;
	showDes: boolean = false;
	enableTimeZone: boolean = false;

	adresses: any;

	/*VARIABLES BEHAVIOR SUBJECT*/
	getEps$ = this._behaviorSubjectService.getEps();
	getAddresses$ = this._behaviorSubjectService.getAddresses();
	getSelectAddress$ = this._behaviorSubjectService.getSelectAddress();
	getAddress$ = this._behaviorSubjectService.getAddress();
	getResumenRecurrent$ = this._behaviorSubjectService.getResumenRecurrent();
	getSubjectRecurrent$ = this._behaviorSubjectService.getSubjectRecurrent();

	order: any;
	orderA: any;

	public eps: Eps;
	public subjectRecurrent: any;
	public resumenRecurrent: any;

	address: Address[] = [];

	constructor(
		private _formBuilder: FormBuilder,
		private _router: Router,
		private _route: ActivatedRoute,
		private _addressService: AddressService,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _CHANGEDECTOR: ChangeDetectorRef,
		//private _patientService: PatientService,
		private _location: Location,
	) {
		this.addressRecurrentForm = this._formBuilder.group({
			address: ['', [Validators.required]]
		});

		/*SUBSCRIBE BEHAVIOR SUBJECT */
		this.eps = this.getEps$.getValue();
		this.order = this.getSelectAddress$.getValue();
		this.adresses = this.getAddresses$.getValue();
		this._CHANGEDECTOR.markForCheck();
		this.getEnableTimeZone();
		this.subjectRecurrent = this._behaviorSubjectService.getSubjectRecurrent();
		this.resumenRecurrent = this._behaviorSubjectService.getResumenRecurrent();
	}

	ngOnInit(): void {
		this.tagUrl();

		this.getAddress$.next(null)



		this.address = this.adresses.addresses;

		if (this.order !== null) {
			this.idAddress = this.order.idAddress;

			this.setValue();

			this.addressCoverage = this.order.addressDir;
		}
	}

	setValue() {
		if (this.order.address == 'nueva') {
			this.order.address = ''
		}

		this.addressRecurrentForm.setValue({
			address: this.order.address,
		});

		this.iconEdit = true;
	}

	selectAddress(addres?: string) {
		this.tagContinue(this.subjectRecurrent.identificacion);
		localStorage.setItem('direccion', addres);
		if (this.addressRecurrentForm.get('address').value != 'nueva') {
			this.newAddress = false;
			this.update = true;
			this.iconEdit = true;
			this.address.forEach(e => {
				if (e.idDireccion == this.addressRecurrentForm.get('address').value) {
					this.updaAddress = e;
					this.idAddress = this.updaAddress.idDireccion;
					this.addressCoverage = this.updaAddress.direccion;
				}
			});
		} else {
			this.newAddress = true;
			this.update = false;
			localStorage.setItem('updateA', 'false');

			
			this.getFormData();

			this.getSelectAddress$.next(this.order);
			this.getAddress$.next(this.order);

			this._router.navigate(['/formulario-recurrente/direccion'], {
				relativeTo: this._route
			});
		}
	}

	getFormData() {
		this.order = {
			idAddress: this.idAddress,
			address: this.addressRecurrentForm.get('address').value,
			channel: environment.CHANNEL,
			available: '1',
			newAddress: this.newAddress,
			addresses: this.address,
			addressDir: this.addressCoverage
		}

		this.orderA = {
			idAddress: this.idAddress,
		}
	}

	despliegue() {
		this.showDes = !this.showDes;
	}

	toReturn() {
		this._location.back();
	}

	nextSubject() {
		this.tagNextAddress(this.subjectRecurrent.identificacion);
		this.getFormData();

		this.getSelectAddress$.next(this.order);
		this.getAddress$.next(this.order);
		

		if (this.addressRecurrentForm.get('address').value == 'nueva') {
			this._router.navigate(['/formulario-recurrente/direccion'], {
				relativeTo: this._route
			});
		} else {
			this.subjectRecurrent.idDireccion=this.addressRecurrentForm.get('address').value;
			this.resumenRecurrent.idDireccion=this.addressRecurrentForm.get('address').value;
			this.loadAddressName(this.addressRecurrentForm.get('address').value);
			this._router.navigate(['/formulario-recurrente/validar'], {
				relativeTo: this._route
			});
		}
		this.getSubjectRecurrent$.next(this.subjectRecurrent);
		this.getResumenRecurrent$.next(this.resumenRecurrent);
	}

	loadAddressName(addressId: any){
		for(let one of this.address){
			if(one.idDireccion==addressId){
				this.resumenRecurrent.direccionDomicilio=one.nombre;
				this.resumenRecurrent.direccion=one.direccion;
				this.resumenRecurrent.comentarios=one.comentarios;
			}
		}
	}
	
	edit() {
		this.tagContinue(this.subjectRecurrent.identificacion);
		this.getFormData();

		this.getSelectAddress$.next(this.order);

		this._router.navigate(['/formulario-recurrente/direccion'], {
			relativeTo: this._route
		});
	}

	getEnableTimeZone() {
		if (localStorage.getItem('enableSchedule') == 'true') {
			this.enableTimeZone = true;
		} else {
			this.enableTimeZone = false;
		}
	}

	tagUrl() {
		dataLayer.push({
			'event': 'ga_virtual_Page',
			'titleVp': 'Creacion y seleccion de direccion',
			'urlVp': '/vp/formulario-recurrente/seleccionar-direccion'
		});
	}

    tagContinue(documentNumber: string) {
		dataLayer.push({
			'event': 'ga_event_creardireccion',
			'category': 'Pedidos Recurrentes',
			'action': 'Crea una dirección o selecciona una ya creada',
			'label': 'Continuar',
			'UES': 'Medicamentos',
			'identificacion': documentNumber
		});
	}
	
	tagNextAddress(documentNumber : string){
		dataLayer.push({
			'event': 'ga_event_seldireccion',
			'category': 'Pedidos Recurrentes',
			'action': 'Crea una dirección o selecciona una ya creada',
			'label': 'Continuar',
			'UES': 'Medicamentos',
			'identificacion': documentNumber
		});	
	}
}
