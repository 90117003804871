import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  getItemSessionStorage(key: string) {
    return sessionStorage.getItem(key);
  }

  parseItem<T>(key: string) {

    return JSON.parse(sessionStorage.getItem(key)) as T;
  }

}
