import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';

import { PatientService } from 'src/app/core/services/patient/patient.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';

import { Patient, GetPatient } from 'src/app/core/models/patient/patient';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/app/configs/environment';
import { RegularExpressions } from 'src/app/core/helpers/regular-expressions/regular-expressions';
import { JwUser } from 'src/app/core/models/jw-user/jw-user';
import { JwtService } from 'src/app/core/services/jwt.service';
import { CellphonePipe } from 'src/app/core/pipe/cellphone.pipe';
import { EmailPipe } from 'src/app/core/pipe/email.pipe';

declare let dataLayer;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  icon = './assets/icons/contacto.svg';
  title: string;
  subtitle = 'Aquí recibirás actualizaciones sobre tu solicitud.';

  progress = 66.4;
  contactForm: FormGroup;

  /*VARIBALES SERVICES*/
  public patientClass: Patient;
  public patient: GetPatient;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getPatient$ = this._behaviorSubjectService.getPatient();

  public vulnerable: PatientVulnerable;

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location,
    private _patientService: PatientService,
    private _behaviorSubjectService: BehaviorSubjectService
  ) {
    this.contactForm = this._formBuilder.group({
      name: ['',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(RegularExpressions.LETTERS_SPACES_SPECIALS)
        ]
      ],
      surname: ['',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(RegularExpressions.LETTERS_SPACES_SPECIALS)
        ]
      ],
      phone: ['', []],
      email: ['', []],
      tel: ['', []],
    });

    /*SUBSCRIBE BEHAVIOR SUBJECT */
    this.vulnerable = this.getPatientVulnerable$.getValue();
    this.patient = this.getPatient$.getValue();

    if (this.vulnerable != null) {
      let namePatient = this.vulnerable.nombres != null && this.vulnerable.nombres != '' ? this.vulnerable.nombres.split(" ", 1) : "Paciente";
      const name = this.vulnerable.nombres == 'No_Registra' ? 'Paciente' : namePatient;
      this.title = `${name}, dinos cómo podemos contactarte`;
    }

  }

  ngOnInit(): void {
    this.tagUrl();
    if (this.vulnerable != null) {
      this.getPatient();
    }
  }

  /*SERVICES*/
  public getPatient() {
    this._patientService.getPatient(this.vulnerable.tipoIdentificacion, this.vulnerable.identificacion).subscribe(
      response => {
        if (response.patient) {
          this.patient = response.patient[0];
          this.patientClass = {
            id: null,
            typeIdentification: this.patient.typeIdentification,
            identification: this.patient.identification,
            name: this.patient.name,
            surname: this.patient.surname,
            email: this.patient.email,
            phone: this.patient.phone,
            phoneNumber: this.patient.phoneNumber,
            channel: environment.CHANNEL,
            birthdate: null,
          };
        } else {
          this.patientClass = {
            id: null,
            typeIdentification: this.vulnerable.tipoIdentificacion,
            identification: this.vulnerable.identificacion,
            name: this.vulnerable.nombres,
            surname: this.vulnerable.apellidos,
            email: this.vulnerable.correoElectronico,
            phone: this.vulnerable.celular,
            phoneNumber: this.vulnerable.celular,
            channel: environment.CHANNEL,
            birthdate: null,
          };
        }
        this.loadForm();
      }
    );
  }

  loadForm() {
    this.contactForm.get('name').setValue(this.patientClass.name);
    this.contactForm.get('surname').setValue(this.patientClass.surname);
    this.contactForm.get('phone').setValue(new CellphonePipe().transform(this.patientClass.phone));
    this.contactForm.get('email').setValue(new EmailPipe().transform(this.patientClass.email));
    this.contactForm.get('tel').setValue(new CellphonePipe().transform(this.patientClass.phoneNumber));
  }

  public createPatient() {
    this.getPatient$.next(this.patientClass);


    this._patientService.createPatient(this.patientClass).subscribe(
      response => {
        this._router.navigate(['/verificacion-pedido'], {
          relativeTo: this._route
        });
      }
    );
  }

  updatePatient() {

    this.getPatient$.next(this.patientClass);
    this._patientService.updatePatient(this.patientClass).subscribe(
      response => {
        this._router.navigate(['/verificacion-pedido'], {
          relativeTo: this._route
        });
      }
    );
  }

  /*INFORMATION BETWEEN COMPONENTS BEHAVIOR*/
  nextSubject() {
    if (this.patient == null) {
      this.createPatient();
    } else {
      this.updatePatient();
    }

    this.tagContinue();
  }

  toReturn() {
    this._location.back();
  }

  public tagUrl() {
    dataLayer.push({
      'event': 'ga_virtual_Page',
      'titleVp ': 'Datospaciente',
      'urlVp': '/vp/datospaciente'
    });
  }

  public tagContinue() {
    dataLayer.push({
      'event': 'ga_event_datospaciente',
      'category': 'datos_paciente',
      'action': 'Clic-boton',
      'label': 'continuar_datospaciente',
      'UES': 'Medicamentos'
    });
  }

  focus(inputKey: string) {
    this.contactForm.get(inputKey).setValue('');
  }

  focusOut(inputKey: string) {
    switch (inputKey) {
      case 'phone':
        if (this.contactForm.get(inputKey).value != null && this.contactForm.get(inputKey).value != '') {
          this.patientClass.phone = this.contactForm.get(inputKey).value;
        }
        if (this.customValidator(10, 10, RegularExpressions.ONLY_NUMBER_CELLPHONE, this.patientClass.phone)) {
          this.cleanValidations(inputKey);
          this.contactForm.get('phone').setValue(new CellphonePipe().transform(this.patientClass.phone));
        } else {
          this.contactForm.controls[inputKey].setValidators([
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern(RegularExpressions.ONLY_NUMBER_CELLPHONE)
          ]);
          this.contactForm.controls[`${inputKey}`].updateValueAndValidity();
        }
        break;
      case 'tel':
        if (this.contactForm.get(inputKey).value != null && this.contactForm.get(inputKey).value != '') {
          this.patientClass.phoneNumber = this.contactForm.get(inputKey).value;
        }
        if (this.customValidator(6, 10, RegularExpressions.ONLY_NUMBERS, this.patientClass.phoneNumber)) {
          this.cleanValidations(inputKey);
          this.contactForm.get('tel').setValue(new CellphonePipe().transform(this.patientClass.phoneNumber));
        } else {
          this.contactForm.controls[inputKey].setValidators([
            Validators.minLength(6),
            Validators.maxLength(10),
            Validators.pattern(RegularExpressions.ONLY_NUMBERS)
          ]);
          this.contactForm.controls[`${inputKey}`].updateValueAndValidity();
        }
        break;
      case 'email':
        if (this.contactForm.get(inputKey).value != null && this.contactForm.get(inputKey).value != '') {
          this.patientClass.email = this.contactForm.get(inputKey).value;
        }
        if (this.customValidator(0, -1, RegularExpressions.VALIDATE_EMAIL, this.patientClass.email)) {
          this.cleanValidations(inputKey);
          this.contactForm.get('email').setValue(new EmailPipe().transform(this.patientClass.email));
        } else {
          this.contactForm.controls[inputKey].setValidators([
            Validators.required,
            Validators.pattern(RegularExpressions.VALIDATE_EMAIL)
          ]);
          this.contactForm.controls[`${inputKey}`].updateValueAndValidity();
        }
        break;
    }

  }

  cleanValidations(keyForm: string) {
    this.contactForm.get(keyForm).clearValidators();
    this.contactForm.get(keyForm).updateValueAndValidity();
  }

  customValidator(min: number, max: number, exp: string, value: string): boolean {
    const regex = new RegExp(exp);
    const valid: boolean = regex.test(value);
    if (value.length >= min && (value.length <= max || max === -1) && valid) {
      return true;
    } else {
      return false;
    }
  }
}
