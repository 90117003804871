<div [id]="idModal" aria-hidden="true" class="c-modal">
  <div tabindex="-1" data-micromodal-close class="c-modal_overlay">
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-1-title"
      class="c-modal_container c-modal_container--info"
    >
      <div class="c-modal_cont">
        <div class="c-modal_cont_text">
          <div class="cont-title-sec text-center">
            <h3 class="color-blue-dark">
              <b>PARA TODOS NUESTROS USUARIOS Y CLIENTES</b>
            </h3>
          </div>

          <div class="d-flex justify-content-center">
            <div>
              <div class="w-100 text-center c-modal_cont_text_info">
        
                  Medicamentos Colsubsidio, trabaja día a día para brindar la
                  mejor atención y bienestar a todos nuestros usuarios y
                  clientes. Frente a la situación actual que vive el país a
                  causa de las movilizaciones y protestas, nos permitimos
                  informar:
              </div>

              <div class="c-modal_cont">
                <ol>
                  <li>
                    Los horarios de atención en los servicios farmacéuticos y
                    droguerías pueden no coincidir con nuestros horarios
                    habituales. Las ciudades que se verán afectadas serán
                    Bogotá, Soacha, Medellín, Cali y Bucaramanga. El horario de
                    cierre será la 1 p.m. En las demás ciudades los horarios se
                    mantienen con normalidad, sujeto a cambios.
                  </li>
                  <li>
                    Demoras en la atención en algunos establecimientos
                    farmacéuticos, por la ausencia de personal dispuesto para la
                    atención.
                  </li>
                  <li>
                    Retraso en la entrega de domicilios, debido a las
                    limitaciones existentes por desplazamiento de nuestros
                    operadores logísticos.
                  </li>
                  <li>
                    Desabastecimientos en algunos productos, debido a las
                    limitaciones en desplazamiento por los bloqueos existentes a
                    nivel nacional.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div class="c-modal_cont__buttons">
          <button class="w-50 btn" (click)="close()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</div>

