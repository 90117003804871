<app-page-progress [progress]="progressComponent"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div class="ctn-fields">
<div class="ctn-image">
    <app-card class="list">
      <div class="list__info">
        <div class="d-flex space-between p-relative">
          <span class="title-card-img">
            Adjuntar fotos.
          </span>
        </div>
    
        <form [formGroup]="deploymentForm">
          <div class="load">
            <app-card class="list" *ngFor="let f of fileMultiple; let i = index">
              <div class="d-flex ctn-load">
                <img src="./assets/icons/formula/imagen-iconos.svg" alt="adjuntar">
    
                <div class="w-100 p-l">
                  <div>
                    <ng-container *ngFor="let file of f">

                      <span class="name-file">
                        {{file?.name}}
                      </span>
                    </ng-container>
    
                    <div *ngIf="f.progress == 100">
                      <p class="size">
                        {{ f.size }}
                      </p>
                    </div>
                  </div>
    
                  <ng-container *ngIf="f.progress !== 100">
                    <app-progress [progress]="newFile?.progress"></app-progress>
    
                    <div class="text-center">
                      {{newFile?.progress}}%
                    </div>
                  </ng-container>
                </div>
    
                <div class="ctn-deployment" *ngIf="loadingBar == true">
                  <label for="deployment-{{i}}">
                    <input type="radio" id="deployment-{{i}}" name="deployment" formControlName="deployment" value="deployment-{{i}}" (click)="deployment(i)" class="check p-relative">
                    
                    <img src="./assets/icons/more-horizontal.svg" alt="Opcion">
    
                    <div class="deployment">
                      <div>
                        <button (click)="notImage(i)" class="delete">
                          <img src="./assets/icons/trash-2.svg" alt="Eliminar">
                          Eliminar
                        </button>
                      </div>
                  </div>
                  </label>
                </div>
              </div>
            </app-card>
          </div>
        </form>
    
        <div *ngIf="showLoad == false || showMultiple == true">
          <div class="list__info__container" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" name="file" #fileDropRef id="fileDropRef" (change)="handleFileInput($event.target.files)">
          
            <div id="zona" class="zone d-flex a-items-center">
              <img src="./assets/icons/formula/adjuntar-imagen.svg" alt="adjuntar">
               <span class="p-l">
                Arrastra tu imagen aquí o
                <label class="pointer" for="fileDropRef">
                  adjúntala
                </label>
               </span>
            </div>
          </div>
        </div>
    
        <div *ngIf="showMessage == true" class="c-red text-center">
          No se puede cargar el archivo, por favor revisa el tamaño y/o  tipo de archivo.
        </div>
    
        <div *ngIf="showSizeTotal == true" class="c-red text-center">
          El total de los archivos no puede superar las {{maxSize}}
        </div>
    
        <div class="text-center">
          JPEG, JPG, PNG Y PDF permitidos
        </div>
      </div>
    </app-card>
  </div>
  <div class="d-flex space-between buttons">
    <button (click)="toReturn()" class="btn-return">
      <img src="./assets/icons/navegacion/atras.svg" alt="icon">
    </button>

    <button *ngIf="!createFormulaImage" type="button">
      <div class="btn-next-init"></div>
    </button>

    <button *ngIf="createFormulaImage" type="button" (click)="continue()">
      <div class="btn-next"></div>
    </button>
  </div>
</div>