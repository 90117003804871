<div class="ctn-input">
  <app-card class="list">
    <form [formGroup]="formulaForm">
      <label for="{{formula.numero}}">

        <input type="checkbox" id="{{formula.numero}}" name="{{formula.numero}}" value="{{formula.numero}}" formControlName="formu" (change)="sendInformation($event)" checked="formula.check == true">

        <div>
          <input type="checkbox" id="{{see}}" class="see-more" formControlName="see">

          <span class="d-flex space-between p-20 info-formula">
            <div class="ctn-formula">
              <div class="n-formula">
                Fórmula número {{formula.numero}}
              </div>
        
              <div class="ctn-treatment">
                <ul>
                  <li *ngFor="let itemsF of formula.tratamiento" class="treatment">
                    {{itemsF.producto.descripcion}}
                  </li>
                </ul>
              </div>
            </div>

            <span class="check c-blue w-check">
              <img src="./assets/icons/check.svg" alt="check">
            </span>
          </span>

          <label class="tab-label" for="{{see}}" (click)="seeChange()">
            <img src="{{imageSee}}" alt="see">
          </label>
        </div>
      </label>
    </form>
  </app-card>
</div>
