import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal/modal.service';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent implements OnInit {

  @Input() idModal: string;
  @Input() title: string;
  @Input() description: string;

  constructor( private _MODAL: ModalService) { }

  ngOnInit(): void {
  }

  close() {
    this._MODAL.closeModal(this.idModal);
  }


}
