import { Injectable } from '@angular/core';
import { LocalStorageService } from "./local-storage/local-storage.service";
import { AppConfig } from "../../configs/app-config";
import { JwtHelperService } from "@auth0/angular-jwt";
import { JwUser } from '../models/jw-user/jw-user';
import { SessionStorageService } from './session-storage/local-storage.service';
import { CiamUser } from '../models/jw-user/ciam-user';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  constructor(private jwtHelper: JwtHelperService,
    private _localStorageService: LocalStorageService,
    private _sessionStorageService: SessionStorageService) { }

  getAndStoreJWTValue(token: string): JwUser | null {
    try {
      const decodedToken = this.jwtHelper.decodeToken(token);
      this._localStorageService.setItem(AppConfig.SSO_TOKEN(), token);
      this._localStorageService.stringifyItem(AppConfig.TOKEN_NAME_JTWT(), decodedToken);
      return decodedToken as JwUser;
    } catch (e) {
      console.log(e);
    }
  }

  getJwtUserSSO() {
    return this._localStorageService.parseItem<JwUser>(AppConfig.TOKEN_NAME_JTWT());
  }

  getCiamUserSSO() {
    return this._sessionStorageService.parseItem<CiamUser>(AppConfig.SSO_CIAM());
  }

  validateToken():boolean{
    const sso_token = this._localStorageService.getItemString(AppConfig.SSO_TOKEN());
    if (sso_token) {
      if(!this.jwtHelper.isTokenExpired()) {
        return  true;
      } else {
        location.href = '';
        return false;
      }
    } else {
      this._localStorageService.clear();
      location.href = '';
      return false;
    }
  }
}
