<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div>
  <form [formGroup]="addressRecurrentForm">
    <div class="ctn-fields">
      <div class="ctn-input">
        <div class="dircs">
          <ng-container *ngFor="let ad of address">
            <div>
              <app-card class="list">
                <label for="{{ad.idDireccion}}">
                  <input class="input-prueba" type="radio" id="{{ad.idDireccion}}" name="address" formControlName="address" value="{{ad.idDireccion}}" (change)="selectAddress(ad.direccion)">
        
                  <div class="d-flex space-between p-20">
                    <span>
                      <span class="d-block name">
                        {{ad.nombre}}
                      </span>
  
                      <span class="address">
                        {{ad.direccion}}
                      </span>
                    </span>
  
                    <button (click)="despliegue()" class="check p-relative" [disabled]="!iconEdit">
                      <img src="./assets/icons/more-horizontal.svg" alt="Opcion">

                      <div class="despliegue" [ngClass]="showDes ? 'd-block' : 'd-none'">
                        <div>
                          <button (click)="edit()" class="edit">
                            <img src="./assets/icons/edit-2.svg" alt="Editar">
                            Editar
                          </button>
                        </div>
                      </div>
                    </button>
                  </div>
                </label>
              </app-card>
            </div>
          </ng-container>
        </div>

        <div class="text-invalid">
          <app-form-errors *ngIf="addressRecurrentForm.get('address').touched"
                          [errors]="addressRecurrentForm.get('address').errors">
            Campo obligatorio
          </app-form-errors>
        </div>
      </div>

      <div class="ctn-input">
        <div class="list new-dir">
          <label for="nueva">
            <input type="radio" id="nueva" name="address" formControlName="address" value="nueva" (change)="selectAddress()">
  
            <div class="d-flex j-center space-between p-20">
              Crear nueva dirección
            </div>
          </label>
        </div>
      </div>

      <div class="a-items-center p-20 warning">
				<div class="d-flex space-around">
					<img class= "p-20" src="./assets/icons/formula/megafono.svg" alt="">
					<p>{{messageDomicile}}</p>
				</div>
			</div>

      <div class="d-flex space-between buttons">
        <button (click)="toReturn()" class="btn-return">
          <img src="./assets/icons/navegacion/atras.svg" alt="icon">
        </button>

        <button *ngIf="!addressRecurrentForm.valid" type="button">
          <img src="./assets/icons/navegacion/boton-continuar-deshabilitado.svg" alt="icon">
        </button>

        <button type="button" (click)="nextSubject()" *ngIf="addressRecurrentForm.valid">
          <div class="btn-next"></div>
        </button>
      </div>
    </div>
  </form>
</div>
