export const environment = {
  production: false,
  USER_PASS: 'nidia:1234',
  AUTHORIZATION: 'tokenLanding',
  AUTH_ENDPOINT: 'https://cpd-as-ryt-mscuseridentity-test.azurewebsites.net',
  API_VULNERABLE_PATIENT: 'https://cpd-as-med-mscwhitelist.azurewebsites.net/v1.0',
  API_PATIENT: 'https://cpd-as-ryt-mscpatient-test.azurewebsites.net/paciente',
  API_EPS: 'https://cpd-as-ryt-msceps.azurewebsites.net',
  API_LOCATION_APP: 'https://cpd-as-ryt-msclocation.azurewebsites.net',
  API_ADDRESS: 'https://cpd-as-med-mscaddress.azurewebsites.net/v1.0',
  API_FORMULA: 'https://cpd-as-med-mscformula.azurewebsites.net/v1.0',
  API_ORDER: 'https://cpd-as-ryt-mscorders-test.azurewebsites.net',
  API_ORDER_CONFIGURATION: 'https://cpd-as-med-api-order-configuration.azurewebsites.net/v1.0',
  API_PARAMETERS: 'https://cpd-as-ryt-mscparameter-test.azurewebsites.net',
  API_RECURRENT:'https://cdp-as-med-paciente-recurrente-test.azurewebsites.net',
  //SSO_ENDPOINT: 'https://www.colsubsidio.com/sso/#/ingresar?goto=http://localhost:4200/#',
  SSO_ENDPOINT: 'https://www.colsubsidio.com/sso/#/ingresar?goto=https://cpd-as-med-portalmedicine-test.azurewebsites.net',
  API_USER_IDENTITY: 'https://cpd-as-ryt-mscuseridentity-test.azurewebsites.net/autenticacion/usuarios/logout/token',
  API_GEOLOCATION: 'https://cpd-as-med-mscgeolocation-test.azurewebsites.net',
  API_NOTIFIER: 'https://cpd-as-ryt-mscnotifier-test.azurewebsites.net',
  PREAGENDAMIENTO: 'https://cdp-as-med-preagendamiento-front-test.azurewebsites.net/#/pre-agendamiento',
  DROGUERIAS_COLSUBSIDIO:'https://www.drogueriascolsubsidio.com/',
  SURAREDIRECT: 'https://portaleps.epssura.com/ServiciosUnClick/#/solicitudes/medicamentos?App=tramitesExternos',
  API_YII: 'https://cpd-as-med-cms-front-test.azurewebsites.net/web/index.php?r=medicines',
  KEY_SURA: 'SURA_ORDERS',
  KEY_WHITE_LIST_PORTAL:'WHITE_LIST_PORTAL',
  TYPE: 'PED_DOM_SF',
  TYPE_ORDER: 'PED_DOM_SF,PED_DOM_SF_RECU,PED_DOM_SF_PEND',
  CHANNEL: 'LANDING',
  KEY_TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  KEY_HABEAS_DATA: 'HABEAS_DATA',
  KEY_ENABLE_CLICK_TO_CALL:'ENABLE_CLICK_TO_CALL',
  KEY_TEL_CLICK_TO_CALL:'TEL_CLICK_TO_CALL',
  ROUTE_PATIENT: '/datos-paciente',
  ROUTE_FORMULA: '/formula',
  ROUTE_ORDER_MANAGER: '/gestionar-pedido',
  ROUTE_EPS: '/eps',
  KEYSITE: '6LePZLoZAAAAAFZuZHmwS4NWWDLyK8j35yowBZia',
  SIZE_TOTAL_FILES: 10000000,
  SIZE_FILE: 5000000,
  SIZE_FILE_MAX: '10MB',
  FORMULA_NO_PBS_ID: 'SOPORTE_AUTORIZADO',
  MESSAGE_DOMICILE: 'Para la población NO vulnerable el domicilio tendrá un costo de $ 9.000 pesos, adicionales al pago de la cuota moderadora o copago.',
  MESSAGE_ADDRESS_RECURRENT: 'Recuerda que todas las entregas de pedidos recurrentes serán entregadas en la dirección seleccionada. Si posteriormente deseas modificar esta dirección de entrega, lo puedes hacer ingresando al Portal Medicamentos por la opción "Gestionar mis pedidos".',
	REMEMBER_MESSAGE_DOMICILE: 'Tú domicilio tendrá un costo de $9.000 pesos, adicionales al pago de la cuota moderadora.',
  ALERT_MESSAGE_HOME_PORTAL: 'ALERT_MESSAGE_HOME_PORTAL',
  DEFAULT_NUMBER_CLICK_TO_CALL: '01-8000413624',
  KEY_SMS_UPDATE_PHONE: "ACTUALIZACION_CELULAR_RECURRENTE",
  DISENROLLED_RECURRENT_PATIENT: "PACIENTE_DESAFILIADO_RECURRENTE",
  MSG_DOMICILE_NON_VULNERABLE_POPULATION: 'MSG_VALOR_DOMICILIO_POBLACION_NO_VULNERABLE',
  //CIAM_API_KEY: "4_c3Z4-oHOIeiZiGvrnGibMQ",
  CIAM_API_KEY: '4_FCmVxAIcmPIS_tllYZqjaQ',
  CIAM_TYPE_MARKETING: 'B2C',
  CIAM_VAR_USER_DATA: 'sso_persona',
  CIAM_SCREEN_PREFIX: 'ColsubB2C',
  CIAM_CDN_URL: 'https://login.medicamentosciamqa.colsubsidio.com',
  CIAM_REDIRECT_HOME: '/gestionar-pedido',
  CIAM_REDIRECT_LOGOUT: '/inicio',
  CIAM_COUNT_AUTH: 4,
  CONTEXT: '/ciam',
  CIAM_LOGIN_URL: 'https://app-tra-auth-ciam-api-develop.azurewebsites.net/v1.0/authciam/signin',
};

