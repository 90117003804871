<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div>
  <form [formGroup]="addressForm">
    <div class="ctn-fields">
    <div style="display: inline-block;margin-right: 300px;">
      <label for="adressParte5">Vía Principal</label>
    </div>
    <div style="display: inline-block;margin-right: 20px;">
      <label for="adressParte5">Vía Secundaria</label>
    </div>
    
    <div style="display: inline-block;">
      <label for="adressParte5">Vía Complemento</label>
    </div>
  </div>

    <div class="ctn-fields">
      
      <div style="display: inline-block;white-space: nowrap;">
        <!-- <label for="addressNomenclature" class="t-doc"> -->
                      
          <select id="addressNomenclature" name="addressNomenclature" (change)="generateAddress()" formControlName="addressNomenclature" style="display: block;
          padding: 7px;margin-right: 2px; box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 100px; cursor: pointer; margin: 12px 0px;"> <option *ngFor="let abr of abreviatura" [value]="abr.nomenclatura">
              {{abr.nomenclatura}}
            </option>
          </select>
        <!-- </label> -->
      </div>

      <div style="display: inline-block;white-space: nowrap;">
        <div class="tooltip-container">
          <input class="i-data" type="text" name="adressParte1" id="adressParte1" formControlName="adressParte1" (keydown)="restrictInput($event)" (change)="generateAddress()"
            [ngClass]="{'invalid-input': addressForm.get('adressParte1').invalid && (addressForm.get('adressParte1').pristine || addressForm.get('adressParte1').touched)}"
            style="display: block; padding: 7px; margin-right: 2px; box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 40px; cursor: pointer; margin: 12px 0px;" maxlength="3">
          <div class="tooltip" *ngIf="addressForm.get('adressParte1').invalid && (addressForm.get('adressParte1').pristine || addressForm.get('adressParte1').touched)">
            Campo obligatorio
          </div>
        </div>
      </div>

      
      <div style="display: inline-block;white-space: nowrap;">
        <!-- <label for="addressNomenclature" class="t-doc"> -->
                      
          <select id="adressParte2" formControlName="adressParte2" (change)="generateAddress()" name="adressParte2" style="display: block;
          padding: 7px;margin-right: 2px; box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 45px; cursor: pointer; margin: 12px 0px;">
            <option value="" selected="selected"></option>
            <option *ngFor="let letter of alphabet" [value]="letter">{{ letter }}</option>
          </select>
        <!-- </label> -->
      </div>

      
      <div style="display: inline-block;white-space: nowrap;">
        <!-- <label for="addressNomenclature" class="t-doc"> -->
                      
          <select id="adressParte3" formControlName="adressParte3" (change)="generateAddress()" name="adressParte3" size="1" style="display: block;
          padding: 7px; margin-right: 2px;box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 60px; cursor: pointer; margin: 12px 0px;">
            <option value="" selected="selected"></option>
            <option *ngFor="let bi of bis" [value]="bi">{{ bi }}</option>
          </select>
        <!-- </label> -->
      </div>

      <div style="display: inline-block;white-space: nowrap;">
        <!-- <label for="addressNomenclature" class="t-doc"> -->
                      
          <select id="adressParte4" formControlName="adressParte4" (change)="generateAddress()" name="adressParte4" size="1" style="display: block;
          padding: 7px; margin-right: 2px;box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 50px; cursor: pointer; margin: 12px 0px;">
            <option value="" selected="selected"></option>
            <option *ngFor="let letter of alphabet" [value]="letter">{{ letter }}</option>
          </select>
        <!-- </label> -->
      </div>

      
      <div style="display: inline-block;white-space: nowrap;margin-right: 2px;">
        <!-- <label for="addressNomenclature" class="t-doc"> -->
                      
          <select id="adressParte5" formControlName="adressParte5" (change)="generateAddress()" name="adressParte5" size="1"  style="display: block;
          padding: 7px; margin-right: 2px;box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 70px; cursor: pointer; margin: 12px 0px;">
            <option value="" selected="selected"></option>
            <option *ngFor="let point of cardinalPoints" [value]="point">{{ point }}</option>
          </select>
        <!-- </label> -->
      </div>

      <div style="display: inline-block;">
        <p style="margin-left: 1px;margin-right: 1px;">No.</p>
       </div>

      <div style="display: inline-block;white-space: nowrap;">
        <div class="tooltip-container">
          <input class="i-data" type="text" name="adressParte6" id="adressParte6" formControlName="adressParte6" (keydown)="restrictInput($event)" (change)="generateAddress()"
            [ngClass]="{'invalid-input': addressForm.get('adressParte6').invalid && (addressForm.get('adressParte6').pristine || addressForm.get('adressParte6').touched)}"
            style="display: block; padding: 7px; margin-right: 2px; box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 40px; cursor: pointer; margin: 12px 0px;" maxlength="3">
          <div class="tooltip" *ngIf="addressForm.get('adressParte6').invalid && (addressForm.get('adressParte6').pristine || addressForm.get('adressParte6').touched)">
            Campo obligatorio
          </div>
        </div>
      </div>
      
      <div style="display: inline-block;">
        <!-- <label for="addressNomenclature" class="t-doc"> -->
                      
          <select id="adressParte7" formControlName="adressParte7" (change)="generateAddress()" name="adressParte7" size="1"  style="display: block;
          padding: 7px; box-shadow: 0px 0px 2px #d6d6d6; border-radius: 4px; width: 45px; cursor: pointer; margin: 12px 0px;">
            <option value="" selected="selected"></option>
            <option *ngFor="let letter of alphabet" [value]="letter">{{ letter }}</option>
          </select>
        <!-- </label> -->
      </div>
      
      <div style="display: inline-block;">
       <p style="margin-left: 2px;margin-right: 2px;">-</p>
      </div>

      <div style="display: inline-block;white-space: nowrap;">
        <div class="tooltip-container">
          <input class="i-data" type="text" name="adressParte8" id="adressParte8" formControlName="adressParte8" (keydown)="restrictInput($event)" (change)="generateAddress()"
            [ngClass]="{'invalid-input': addressForm.get('adressParte8').invalid && (addressForm.get('adressParte8').pristine || addressForm.get('adressParte8').touched)}"
            style="display: block; padding: 7px; margin-right: 2px; box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 40px; cursor: pointer; margin: 12px 0px;" maxlength="3">
          <div class="tooltip" *ngIf="addressForm.get('adressParte8').invalid && (addressForm.get('adressParte8').pristine || addressForm.get('adressParte8').touched)">
            Campo obligatorio
          </div>
        </div>
      </div>
      <div style="display: inline-block;">
        <!-- <label for="addressNomenclature" class="t-doc"> -->
                      
          <select id="adressParte9" formControlName="adressParte9" (change)="generateAddress()" name="adressParte9" size="1" style="display: block;
          padding: 7px; box-shadow: 0px 0px 2px #d6d6d6; border-radius: 8px; width: 65px; cursor: pointer; margin: 12px 0px;">
            <option value="" selected="selected"></option>
            <option *ngFor="let point of cardinalPoints" [value]="point">{{ point }}</option>
          </select>
        <!-- </label> -->
      </div>

      
      <div class="ctn-data dir">
        <label for="name">
         Direccion Generada :
          <input class="i-data" type="text" name="direccionGenerada" id="direccionGenerada" maxlength="190" readonly [value]="generatedAddress">
        </label>
      </div>

      <div class="d-flex f-wrap space-between p-v-10">
        <div class="ctn-data dir">
          <label for="name">
            Dale un nombre a tu dirección
            <input class="i-data" type="text" name="name" id="name" formControlName="name" maxlength="190">
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="addressForm.get('name').touched"
                            [errors]="addressForm.get('name').errors">
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>

        <div class="ctn-data dir">
          <label for="district">
            Barrio

            <input class="i-data" type="text" name="district" id="district" formControlName="district">
          </label>
        </div>
      </div>

      <div class="d-flex">
        <div class="ctn-data in-dir">
          <label for="comments">
            Información adicional

            <textarea class="i-data" type="text" name="comments" id="comments" formControlName="comments"
            placeholder="Ingresa indicaciones especiales como apartamento, bloque, interior, conjunto, edificio, puntos de referencia y otros datos que consideres necesarios"
            cols="30" rows="6" maxlength="490"></textarea>
          </label>
        </div>
      </div>

      <div class="d-flex space-between buttons">
        <button (click)="toReturn()" class="btn-return">
          <img src="./assets/icons/navegacion/atras.svg" alt="icon">
        </button>

        <button *ngIf="!addressForm.valid || activate" type="button">
          <div class="btn-next-init"></div>
        </button>

        <button type="button" (click)="nextSubject()" *ngIf="addressForm.valid && !activate">
          <div class="btn-next"></div>
        </button>
      </div>

    </div>
  </form>
</div>
