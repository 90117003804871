<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon"  [title]="title"></app-title >

<div>
  <form [formGroup]="epsRecurrentForm">
    <div class="ctn-fields">
      <div class="ctn-input">
        <ng-container *ngFor="let eps of eps">
          <app-card class="list">
            <label for="{{eps.id}}">
              <input type="radio" id="{{eps.id}}" name="eps" formControlName="eps" value="{{eps.id}}" (change)="setEpsObj(eps)">
    
              <div class="d-flex space-between p-20">
                <img src="./assets/icons/eps/{{eps.applyElectronicFormula}}-color.svg" alt="icon">

                <span class="check c-blue">
                  <img src="./assets/icons/check.svg" alt="check">
                </span>
              </div>
            </label>
          </app-card>
        </ng-container>
      </div>
      <div class="d-flex space-between buttons">
          <button (click)="toReturn()" class="btn-return">
            <img src="./assets/icons/navegacion/atras.svg" alt="icon">
          </button>
          <button (click)="nextSubject()" class="btn-continue-one" [disabled]="!epsRecurrentForm.valid">
            <img src="./assets/icons/navegacion/boton-continuar-{{epsRecurrentForm.valid == true ?'habilitado':'deshabilitado'}}.svg" alt="icon">
          </button>
       
      </div>
    </div>

  </form>
</div>

