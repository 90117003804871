import { Component, OnInit } from '@angular/core';
import { Parameter } from 'src/app/core/models/parameter/parameter';
import { ParameterService } from 'src/app/core/services/parameter/parameter.service';
import { environment } from 'src/app/configs/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';

declare let dataLayer;

@Component({
  selector: 'app-sura-redirect',
  templateUrl: './sura-redirect.component.html',
  styleUrls: ['./sura-redirect.component.scss']
})
export class SuraRedirectComponent implements OnInit {

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();

  /*VARIABLES SERVICES*/
  public parameter: Parameter;
  public urlSuraRedirect = environment.SURAREDIRECT;

  progress = 16.6;

  constructor(
    private _parameterService: ParameterService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _behaviorSubjectService: BehaviorSubjectService
  ) { }

  ngOnInit(): void {
    this.tagUrl();
    this.userDataReset();
  }

  public tagUrl() {
    dataLayer.push({
      'event':'ga_virtual_Page',
      'titleVp':'Redireccionamientousuario',
      'urlVp':'/ vp/redireccionamientousuario'
    });
  }

  toReturn() {
      this._router.navigate(['/inicio'], {
        relativeTo: this._route
      });
  }


  public userDataReset(){
    this.getPatientVulnerable$.next(null)
  }

}
