import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { HeaderComponent } from './layouts/main-layout/utils/header/header.component';
import { FooterComponent } from './layouts/main-layout/utils/footer/footer.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth/auth-interceptor';
import { SharedModule } from './shared/shared.module';
import { ErrorInterceptor } from './core/interceptors/error/error-interceptor';
import { HomeComponent } from './components/home/home.component';
import { PatientComponent } from './components/patient/patient.component';
import { EpsComponent } from './components/eps/eps.component';
import { FormulaComponent } from './components/formula/formula.component';
import { AddressComponent } from './components/address/address.component';
import { ContactComponent } from './components/contact/contact.component';
import { VerificationComponent } from './components/verification/verification.component';
import { SuccessfulComponent } from './components/successful/successful.component';
import { AttachedFormulaComponent } from './components/formula/attached-formula/attached-formula.component';
import { ElectronicFormulaComponent } from './components/formula/electronic-formula/electronic-formula.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from './configs/environment';
import { SelectAddressComponent } from './components/select-address/select-address.component';
import { HabeasDataComponent } from './components/habeas-data/habeas-data.component';
import { FailedComponent } from './components/failed/failed.component';
import { DndDirective } from './core/directive/dnd/dnd.directive';
import { SearchFormulaComponent } from './components/formula/search-formula/search-formula/search-formula.component';
import { SuraRedirectComponent } from './components/sura-redirect/sura-redirect.component';
import { FindAddressComponent } from './components/find-address/find-address.component';
import { AttachedFormulaNoPbsComponent } from './components/attached-formula-no-pbs/attached-formula-no-pbs.component';
import {JwtModule} from "@auth0/angular-jwt";
import { NoAuthorizedComponent } from './components/no-authorized/no-authorized.component';
import { OrderManagerComponent } from './components/order-manager/order-manager.component';
import { TabsModule } from "../app/shared/components/tabs/tabs.module";
import {registerLocaleData} from '@angular/common';
import localeES from '@angular/common/locales/es';
import { AddressManagerComponent } from './components/address-manager/address-manager.component';
import { ScheduleDeliveryComponent } from './components/schedule-delivery/schedule-delivery.component';
import { PatientRecurrentComponent } from './components/recurrent/patient/patient.component';
import { EpsRecurrentComponent } from './components/recurrent/eps/eps.component';
import { FindAddressRecurrentComponent } from './components/recurrent/find-address/find-address.component';
import { SelectAddressRecurrentComponent } from './components/recurrent/select-address/select-address.component';
import { AddressRecurrentComponent } from './components/recurrent/address/address.component';
import { ValidateInfoRecurrent } from './components/recurrent/validateInfoRecurrent/validateInfoRecurrent.componet';
import { SuscriptionRecurrent } from './components/recurrent/suscription/suscriptionRecurrent.component';
import { ContactOrderManagerComponent } from './components/contactOrder-manager/contactOrder-manager.component';
import { EmailPipe } from './core/pipe/email.pipe';
import { CellphonePipe } from './core/pipe/cellphone.pipe';
import { AddressPipe } from './core/pipe/address.pipe';
import { CiamActiveDirectoryV2Module } from 'ciam-active-directory-ang9x';

registerLocaleData(localeES,'es');

export function tokenGetter() {
  return localStorage.getItem('sso_token');
}

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PatientComponent,
    EpsComponent,
    FormulaComponent,
    AddressComponent,
    ContactComponent,
    VerificationComponent,
    SuccessfulComponent,
    AttachedFormulaComponent,
    ElectronicFormulaComponent,
    TermsConditionsComponent,
    SelectAddressComponent,
    HabeasDataComponent,
    FailedComponent,
    DndDirective,
    SearchFormulaComponent,
    SuraRedirectComponent,
    FindAddressComponent,
    AttachedFormulaNoPbsComponent,
    NoAuthorizedComponent,
    OrderManagerComponent,
    AddressManagerComponent,
    ScheduleDeliveryComponent,
    PatientRecurrentComponent,
    EpsRecurrentComponent,
    FindAddressRecurrentComponent,
    SelectAddressRecurrentComponent,
    AddressRecurrentComponent,
    ValidateInfoRecurrent,
    SuscriptionRecurrent,
    ContactOrderManagerComponent,
    EmailPipe,
    CellphonePipe,
    AddressPipe
  ],
  // ...

    imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      SharedModule,
      FormsModule,
      ReactiveFormsModule,
      RecaptchaV3Module,
      TabsModule,
      CiamActiveDirectoryV2Module,
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          whitelistedDomains: [environment.SSO_ENDPOINT],
        }
      }),
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorInterceptor
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.KEYSITE
    },
    {provide: LOCALE_ID, useValue: 'es-CO'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
