<div class="ctn-start">
	<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>
	<div class="ctn-fields">
		<div class="ctn-input">
			<div class="d-flex f-wrap space-between ctn-contact">
				<div class="ctn-data-one btn">
					<label><b>Actualización <br>datos del Contacto</b></label>
				</div>
				<div class="ctn-data-one btn">
					<button type="button" (click)="detailsContact()">
						<img src="./assets/icons/documento.svg" alt="icon">
					</button>
				</div>
			</div>
		</div>
		<app-tabs>
			<app-tab-item>
				<app-tab-label>
					<h2 class="tap metricas-tuspedidos" data-estado="En Proceso">En proceso</h2>
				</app-tab-label>
				<app-tab-body>
					<form [formGroup]="dropDownForm">
						<div class="ctn-fields">
							<div class="ctn-input">
								<div class="dircs">
									<ng-container *ngFor="let order of ordersInProcess.orders; let i = index">
										<div>
											<app-card class="list">
												<label for="{{ order.id }}">
													<div class="p-20 ctn-order" id="{{ i }}info">
														<div class="d-flex space-between">
															<div class="p-v-10">
																<span>
																	<span class="d-block name">
																		Pedido #{{ order.id }}
																	</span>
																	<span class="d-block date">
																		{{
																		order.creationDate
																		| date: "dd 'de' MMMM 'de' yyyy"
																		}}
																	</span>

																	<span class="d-block address">
																		<b>Dirección:</b> {{ order.address }}
																	</span>

																	<span class="d-block address">
																		<b>Canal:</b> {{ order.channelDescription }}
																	</span>
																</span>
															</div>
															<div class="ctn-dropDown"
																*ngIf="order.channel == 'LANDING' || order.channel == 'RECURRENTE'">
																<label for="dropDown-{{ i }}">
																	<input type="radio" id="dropDown-{{ i }}"
																		name="dropDown" formControlName="dropDown"
																		value="dropDown-{{ i }}" (click)="dropDown(i)"
																		class="check p-relative" />

																	<img src="./assets/icons/more-horizontal.svg"
																		alt="Opcion" />

																	<div class="dropDown">
																		<div>
																			<button
																				class="edit metricas-pedidosenproceso"
																				data-ajuste="editar direccion"
																				(click)="validateAction('EDITAR', order)">
																				<img src="./assets/icons/edit-2.svg"
																					alt="Editar dirección" />
																				Editar dirección
																			</button>
																		</div>
																		<div>
																			<button
																				class="cancel metricas-pedidosenproceso"
																				data-ajuste="cancelar pedido" (click)="
                                        validateAction('CANCELAR', order)
                                      ">
																				<img src="./assets/icons/trash-2.svg"
																					alt="Cancelar pedido" />
																				Cancelar pedido
																			</button>
																		</div>
																	</div>
																</label>
															</div>
														</div>
														<div class="d-flex p-v-5">
															<div>
																<img src="./assets/icons/mail.svg" alt="Opcion" />
															</div>
															<div class="ml-2">
																<div class="oval bg-gray">
																	<label class="p-20 c-white">{{
																		order.status
																		}}</label>
																</div>
															</div>
														</div>
														<div class="ctn-images" *ngIf="idOrderFormula == order.id">
															<ng-container *ngFor="let formula of orderFormula">
																<div class="n-formula">
																	Fórmula número: {{ formula.number }}
																</div>
																<div class="n-formula" *ngIf="formula.authorization">
																	Fórmula Autorizacion:
																	{{ formula.authorization }}
																</div>
																<div class="ctn-treatment"
																	*ngIf="formula.treatment[0].product.description">
																	<ul>
																		<li *ngFor="let itemsF of formula.treatment"
																			class="treatment">
																			{{ itemsF.product.description }}
																		</li>
																	</ul>
																</div>
																<ng-container
																	*ngFor="let f of formula.files; let ia = index">
																	<button class="file-display" (click)="
                                    modal(
                                      getFileExtension(f.path),
                                      f.path,
                                      'algo'
                                    )
                                  ">
																		<div *ngIf="
                                      getFileExtension(f.path) !=
                                      'application/pdf'
                                    ">
																			<img [src]="f.path" alt="image" />
																		</div>

																		<div *ngIf="
                                      getFileExtension(f.path) ==
                                      'application/pdf'
                                    ">
																			<div class="f-16 name-pdf">
																				{{ f.nameFile }}
																			</div>
																		</div>
																	</button>
																</ng-container>
																<ng-container
																	*ngFor="let f of formula.files; let ia = index">
																</ng-container>
															</ng-container>
														</div>
														<div class="p-v-5 ctn-detail">
															<label class="tab-label p-10" for="{{ i }}fa">
																<input type="checkbox" id="{{ i }}fa" name="{{ i }}fa"
																	class="see-more" formControlName="see"
																	(change)="seeChange(i, order.id)" />
																<img src="{{ imageSee }}" alt="see" id="see-{{ i }}" />
															</label>
														</div>
													</div>
												</label>
											</app-card>
										</div>
									</ng-container>
								</div>
								<div class="ctn-button">
									<button class="btn-new-order" (click)="goToFormula()">
										Hacer nuevo pedido
									</button>
								</div>
								<div class="buttons">
									<button (click)="toReturn()" class="btn-return">
										<img src="./assets/icons/navegacion/atras.svg" alt="icon" />
									</button>
								</div>
							</div>
						</div>
					</form>
				</app-tab-body>
			</app-tab-item>
			<app-tab-item>
				<app-tab-label>
					<h2 class="metricas-tuspedidos" data-estado="Entregado">Entregados</h2>
				</app-tab-label>
				<app-tab-body>
					<form [formGroup]="dropDownForm">
						<div class="ctn-fields">
							<div class="ctn-input">
								<div class="dircs">
									<ng-container *ngFor="let order of ordersDeliveried.orders; let i = index">
										<div>
											<app-card class="list">
												<label for="{{ order.id }}">
													<div class="p-20 ctn-order" id="{{ i }}info">
														<div class="d-flex space-between">
															<div class="p-v-10">
																<span>
																	<span class="d-block name">
																		Pedido #{{ order.id }}
																	</span>
																	<span class="d-block date">
																		{{
																		order.creationDate
																		| date: "dd 'de' MMMM 'de' yyyy"
																		}}
																	</span>

																	<span class="d-block address">
																		<b>Dirección:</b> {{ order.address }}
																	</span>

																	<span class="d-block address">
																		<b>Canal:</b> {{ order.channelDescription }}
																	</span>
																</span>
															</div>
														</div>
														<div class="d-flex p-v-5">
															<div>
																<img src="./assets/icons/mail.svg" alt="Opcion" />
															</div>
															<div class="ml-2">
																<div class="oval bg-green">
																	<label class="p-20 c-white">Entregado</label>
																</div>
															</div>
														</div>
														<div class="ctn-images" *ngIf="idOrderFormula == order.id">
															<ng-container *ngFor="let formula of orderFormula">
																<div>
																	<li class="s-inside n-formula">
																		Número: {{ formula.number }}
																	</li>
																	<li class="s-inside n-formula"
																		*ngIf="formula.authorization">
																		Número autorización:
																		{{ formula.authorization }}
																	</li>
																</div>
																<div class="ctn-treatment">
																	<ul>
																		<li *ngFor="let itemsF of formula.treatment"
																			class="treatment">
																			{{ itemsF.product.description }}
																		</li>
																	</ul>
																</div>
																<ng-container
																	*ngFor="let f of formula.files; let ia = index">
																	<button class="file-display" (click)="
                                    modal(
                                      getFileExtension(f.path),
                                      f.path,
                                      'algo'
                                    )
                                  ">
																		<div *ngIf="
                                      getFileExtension(f.path) !=
                                      'application/pdf'
                                    ">
																			<img [src]="f.path" alt="image" />
																		</div>

																		<div *ngIf="
                                      getFileExtension(f.path) ==
                                      'application/pdf'
                                    ">
																			<div class="f-16 name-pdf">
																				{{ f.nameFile }}
																			</div>
																		</div>
																	</button>
																</ng-container>
																<ng-container
																	*ngFor="let f of formula.files; let ia = index">
																</ng-container>
															</ng-container>
														</div>
														<div class="p-v-5 ctn-detail">
															<label class="tab-label p-10" for="{{ i }}fa">
																<input type="checkbox" id="{{ i }}fa" name="{{ i }}fa"
																	class="see-more" formControlName="see"
																	(change)="seeChange(i, order.id)" />
																<img src="{{ imageSee }}" alt="see" id="see-{{ i }}" />
															</label>
														</div>
													</div>
												</label>
											</app-card>
										</div>
									</ng-container>
								</div>
								<div class="ctn-button">
									<button class="btn-new-order" (click)="goToFormula()">
										Hacer nuevo pedido
									</button>
								</div>
								<div class="buttons">
									<button (click)="toReturn()" class="btn-return">
										<img src="./assets/icons/navegacion/atras.svg" alt="icon" />
									</button>
								</div>
							</div>
						</div>
					</form>
				</app-tab-body>
			</app-tab-item>
			<app-tab-item>
				<app-tab-label>
					<h2 class="metricas-tuspedidos" data-estado="Cancelado">Cancelados</h2>
				</app-tab-label>
				<app-tab-body>
					<form [formGroup]="dropDownForm">
						<div class="ctn-fields">
							<div class="ctn-input">
								<div class="dircs">
									<ng-container *ngFor="let order of ordersCanceled.orders; let i = index">
										<div>
											<app-card class="list">
												<label for="{{ order.id }}">
													<div class="p-20 ctn-order" id="{{ i }}info">
														<div class="d-flex space-between">
															<div class="p-v-10">
																<span>
																	<span class="d-block name">
																		Pedido #{{ order.id }}
																	</span>
																	<span class="d-block date">
																		{{
																		order.creationDate
																		| date: "dd 'de' MMMM 'de' yyyy"
																		}}
																	</span>

																	<span class="d-block address">
																		<b>Dirección:</b> {{ order.address }}
																	</span>

																	<span class="d-block address">
																		<b>Canal:</b> {{ order.channelDescription }}
																	</span>
																	<span class="d-block address">
																		<b>Motivo cancelación:</b> {{ order.status | lowercase  }}
																	</span>
																</span>
															</div>
														</div>
														<div class="d-flex p-v-5">
															<div>
																<img src="./assets/icons/mail.svg" alt="Opcion" />
															</div>
															<div class="ml-2">
																<div class="oval bg-red">
																	<label class="p-20 c-white">Cancelado</label>
																</div>
															</div>
														</div>
														<div class="ctn-images" *ngIf="idOrderFormula == order.id">
															<ng-container *ngFor="let formula of orderFormula">
																<div>
																	<li class="s-inside n-formula">
																		Número: {{ formula.number }}
																	</li>
																	<li class="s-inside n-formula"
																		*ngIf="formula.authorization">
																		Número autorización:
																		{{ formula.authorization }}
																	</li>
																</div>
																<div class="ctn-treatment">
																	<ul>
																		<li *ngFor="let itemsF of formula.treatment"
																			class="treatment">
																			{{ itemsF.product.description }}
																		</li>
																	</ul>
																</div>
																<ng-container
																	*ngFor="let f of formula.files; let ia = index">
																	<button class="file-display" (click)="
                                    modal(
                                      getFileExtension(f.path),
                                      f.path,
                                      'algo'
                                    )
                                  ">
																		<div *ngIf="
                                      getFileExtension(f.path) !=
                                      'application/pdf'
                                    ">
																			<img [src]="f.path" alt="image" />
																		</div>

																		<div *ngIf="
                                      getFileExtension(f.path) ==
                                      'application/pdf'
                                    ">
																			<div class="f-16 name-pdf">
																				{{ f.nameFile }}
																			</div>
																		</div>
																	</button>
																</ng-container>
																<ng-container
																	*ngFor="let f of formula.files; let ia = index">
																</ng-container>
															</ng-container>
														</div>
														<div class="p-v-5 ctn-detail">
															<label class="tab-label p-10" for="{{ i }}fa">
																<input type="checkbox" id="{{ i }}fa" name="{{ i }}fa"
																	class="see-more" formControlName="see"
																	(change)="seeChange(i, order.id)" />
																<img src="{{ imageSee }}" alt="see" id="see-{{ i }}" />
															</label>
														</div>
													</div>
												</label>
											</app-card>
										</div>
									</ng-container>
								</div>
								<div class="ctn-button">
									<button class="btn-new-order" (click)="goToFormula()">
										Hacer nuevo pedido
									</button>
								</div>
								<div class="buttons">
									<button (click)="toReturn()" class="btn-return">
										<img src="./assets/icons/navegacion/atras.svg" alt="icon" />
									</button>
								</div>
							</div>
						</div>
					</form>
				</app-tab-body>
			</app-tab-item>
		</app-tabs>
	</div>
	<app-modal-generic [idModal]="idModal" [title]="title" [description]="description">
		<button class="w-50 btn btn1" (click)="closeModal(); tagCancelButton()" *ngIf="modalBottoms">
			Cancelar
		</button>
		<button class="w-50 btn btn2" (click)="cancelOrder(); tagAgreeButton()" *ngIf="modalBottoms">
			Aceptar
		</button>
		<button class="w-50 btn btn2" (click)="closeModal()" *ngIf="!modalBottoms">
			Aceptar
		</button>
	</app-modal-generic>
	<app-modal-images [idModal]="idModalImage" [image]="imageModal" [ext]="ext" [nameFile]="nameFile">
	</app-modal-images>
</div>