import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';


import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { RecurrentService } from 'src/app/core/services/recurrent/recurrent.services';
import { PatientService } from 'src/app/core/services/patient/patient.service';

import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { TypeDocument } from 'src/app/core/models/type-document/type-document';
import { Patient, GetPatient } from 'src/app/core/models/patient/patient';


import { Eps } from 'src/app/core/models/eps/eps';
import { Router, ActivatedRoute } from '@angular/router';
import { Parameter } from 'src/app/core/models/parameter/parameter';
import { RegularExpressions } from 'src/app/core/helpers/regular-expressions/regular-expressions';
import { JwUser } from 'src/app/core/models/jw-user/jw-user';
import { JwtService } from 'src/app/core/services/jwt.service';

declare let dataLayer;

@Component({
	selector: 'app-patient',
	templateUrl: './patient.component.html',
	styleUrls: ['./patient.component.scss']
})
export class PatientRecurrentComponent implements OnInit {

	icon = './assets/icons/documento.svg';
	title = 'Para solicitar tus pedidos recurrentes, necesitamos algunos datos';
	subtitle = 'Confirma los datos básicos del solicientante.';

	patientRecurrentForm: FormGroup;
	showFields: boolean = false;
	enabledContinue: boolean = true;
	control1: boolean;
	control2: boolean;

	public listIdentifications: TypeDocument[];

	sessionSSO: JwUser;
	documentSSO: string;
	token: string;
	recurrent: any;
	patientRecurrent: any;

	/*VARIABLES SERVICES*/
	public identificationType: string;
	public identification: string;
	public patientClass: Patient;
	public patient: GetPatient;
	public vulnerable: PatientVulnerable;
	public eps: Eps;
	public parameter: Parameter;
	public recentToken: string = '';
	public subjectRecurrent: any;
	public resumenRecurrent: any;

	/*VARIABLES BEHAVIOR SUBJECT*/
	getResumenRecurrent$ = this._behaviorSubjectService.getResumenRecurrent();
	getSubjectRecurrent$ = this._behaviorSubjectService.getSubjectRecurrent();
	getEps$ = this._behaviorSubjectService.getEps();

	constructor(
		private _formBuilder: FormBuilder,
		private _router: Router,
		private _route: ActivatedRoute,
		private _recurrentService: RecurrentService,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _recaptchaV3Service: ReCaptchaV3Service,
		private _jwtService: JwtService,
		private _patient: PatientService,
	) {
		this.patientRecurrentForm = this._formBuilder.group({
			typeDocument: [
				'',
				[
					Validators.required
				]
			],
			document: [
				'',
				[
					Validators.required,
				]
			],
			control1: [
				'false',
				[
					Validators.required,
				]
			],
			control2: [
				'false',
				[
					Validators.required,
				]
			]
		});
		this.token = _route.snapshot.paramMap.get('token');
		this.subjectRecurrent = this._behaviorSubjectService.getSubjectRecurrent();
		this.resumenRecurrent = this._behaviorSubjectService.getResumenRecurrent();
		this.control1 = false;
		this.control2 = false;

	}

	ngOnInit(): void {
		this.tagUrl();
		this.loadRecurrentByToken();

	}

	/*SERVICES*/
	public loadRecurrentByToken() {
		this._recurrentService.getRecurrentByToken(this.token).subscribe(
			response => {
				this.recurrent = response;
				this.patientRecurrentForm.get('document').setValue(this.recurrent.identificacion);
				this.patientRecurrentForm.get('typeDocument').setValue(this.recurrent.nombreIdentificacion);
				this.subjectRecurrent.tipoIdentificacion = this.recurrent.tipoIdentificacion;
				this.subjectRecurrent.identificacion = this.recurrent.identificacion;
				this.resumenRecurrent.tipoIdentificacion = this.recurrent.nombreIdentificacion;
				this.resumenRecurrent.identificacion = this.recurrent.identificacion;
				this.resumenRecurrent.recurringKey = this.recurrent.epsKey;

				this.loadPatient();

			}
		);


	}


	public loadPatient() {
		this._patient.getPatient(this.subjectRecurrent.tipoIdentificacion, this.subjectRecurrent.identificacion).subscribe(
			response => {

				this.patientRecurrent = response.patient[0];
				this.resumenRecurrent.nombreCompleto = this.patientRecurrent.name + " " + this.patientRecurrent.surname;
			}
		);

	}

	fillTermsHabeas() {
		this.control1 = !this.control1;
	}

	fillTermsHabeasRecurrent() {
		this.control2 = !this.control2;
	}

	toReturn() {
		this._router.navigate(['/inicio'], {
			relativeTo: this._route
		});
	}

	/*INFORMATION BETWEEN COMPONENTS BEHAVIOR*/
	nextSubject() {
		localStorage.setItem('editar', '');
		//this.tagContinue();

		this._recaptchaV3Service.execute('ingreso')
			.subscribe((token) => {
				if (token.length) {
					return true
				} else {
					return false
				}

			});

		this.scenariosContinue();
	}

	scenariosContinue() {

		this.getSubjectRecurrent$.next(this.subjectRecurrent);
		this.getResumenRecurrent$.next(this.resumenRecurrent);
		this.tagPagina(this.subjectRecurrent.identificacion);
		this._router.navigate(['/formulario-recurrente/eps'], {
			relativeTo: this._route
		});
	}

	public tagUrl() {
		dataLayer.push({
			'event': 'ga_virtual_Page',
			'titleVp': 'Datos Básicos',
			'urlVp': '/vp/formulario-recurrente/paciente'
		});
	}

	public tagPagina(documentNumber: string) {
		dataLayer.push({
			'event': 'ga_event_datosbasicos',
			'category': 'Pedidos Recurrentes',
			'action': 'confirma los datos básicos del solicitante',
			'label': 'Continuar',
			'UES': 'Medicamentos',
			'identificacion': documentNumber
		});
	}

}
