<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title"></app-title>

<div>
  <div class="d-flex j-center">
    <div class="ctn-data">
      <app-card>
        <div class="p-20">
          <div class="border-1 p-10">
            <div class="n-field">
              Nombre
            </div>
  
            <div class="f-300 p-v-10">
              {{patient.name}} {{patient.surname}}
            </div>
  
            <div class="n-field">
              Número de identificación
            </div>
            
            <div class="f-300 p-v-10">
              {{vulnerable.identificacion}}
            </div>
  
            <div class="n-field">
              EPS
            </div>
  
            <div class="f-300 p-v-10">
              {{eps.nombre}}
            </div>
          </div>

          <div class="border-1 p-10">
            <div class="d-flex space-between">
              <div class="n-field">
                Dirección
              </div>

              <div class="edit">
                <a [routerLink]="['/localizar-direccion']" (click)="urlContinue()">
                  Editar
                </a>
              </div>
            </div>
  
            <div class="f-300 p-v-10">
              {{addresLocal | address}}
            </div>

            <div class="n-field">
              Departamento
            </div>
  
            <div class="f-300 p-v-10">
              {{addresses.department}}
            </div>

            <div class="n-field">
              Ciudad
            </div>

            <div class="f-300 p-v-10">
              {{addresses.city}}
            </div>
          </div>

          <div class="border-1 p-10">
            <div class="d-flex space-between">
              <div class="n-field">
                Celular
              </div>

              <div class="edit">
                <a [routerLink]="['/contacto']">
                  Editar
                </a>
              </div>
            </div>
  
            <div class="f-300 p-v-10">
              {{patient.phone | cellphone}}
            </div>

            <div class="n-field">
              Correo
            </div>
  
            <div class="f-300 p-v-10">
             {{patient.email | email }}
            </div>
          </div>

          <div class="border-1 p-10" *ngIf="enableTimeZone">
            <div class="d-flex space-between">
              <div class="n-field">
                Fecha Entrega:
              </div>

              <div class="edit">
                <a [routerLink]="['/programar-entrega']" (click)="urlContinue()">
                  Editar
                </a>
              </div>
            </div>
  
            <div class="f-300 p-v-10">
              {{scheduleDelivery.schedule | date: "dd 'de' MMMM 'de' yyyy"}}, {{scheduleDelivery.franja}}
            </div>

          </div>

          <div class="p-10">
            <div class="d-flex space-between">
              <div class="n-field">
                Fórmulas
              </div>

              <div class="edit">
                <a [routerLink]="['/formula']" (click)="urlContinue()">
                  Editar
                </a>
              </div>
            </div>
  
            <div class="p-v-10" *ngFor="let formuA of formulaAS">
              <div class="number-f">
                #{{formuA.number}}
              </div>

              <ul>
                <li class="s-inside n-formula">
                  Número autorización {{formuA.authorization}}
                </li>
              </ul>
            </div>

            <div class="p-v-10" *ngFor="let formuE of formulaE">
              <div class="number-f">
                #{{formuE.number}}
              </div>

              <ul>
                <li *ngFor="let itemsF of formuE.treatment" class="s-inside n-formula">
                  {{itemsF.product.description}}
                </li>
              </ul>
            </div>

            <div class="p-v-10" *ngIf="attachedFormulaNoPbs !== null" >
              <div class="number-f">
                #{{attachedFormulaNoPbs.number}}
              </div>
            </div>
            
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <div class="d-flex space-between buttons">
    <button (click)="toReturn()" class="btn-return">
      <img src="./assets/icons/navegacion/atras.svg" alt="icon">
    </button>

    <!--<app-btn-behind></app-btn-behind>-->

    <div class="ctn-button">
      <button class="btn-end d-flex j-center" [ngClass]="{'opacity': disabled == true}" [disabled]="disabled == true" (click)="createOrder()">
        Confirmar

        <div class="ctn-spinner" *ngIf="disabled == true">
          <div class="spinner"></div>
        </div>
      </button>
    </div>
  </div>
</div>