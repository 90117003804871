import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';

declare let dataLayer;

@Component({
  selector: 'app-failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss']
})
export class FailedComponent implements OnInit {

  icon = './assets/icons/warning.svg';
  title = 'Ha ocurrido un error';
  subtitle = 'No hemos podido crear tu orden.';

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getPatient$ = this._behaviorSubjectService.getPatient();
  getEps = this._behaviorSubjectService.getEps();
  getElectronicFormula = this._behaviorSubjectService.getElectronicFormula();
  getElectronicFormulaS = this._behaviorSubjectService.getElectronicSearchFormula();
  getAttachedFormula = this._behaviorSubjectService.getAttachedFormula();
  getAttachedFormulaSelected = this._behaviorSubjectService.getAttachedFormulaSelected();
  getSelectAddress = this._behaviorSubjectService.getSelectAddress();
  getAddress = this._behaviorSubjectService.getAddress();
  getContact = this._behaviorSubjectService.getContact();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
  getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _behaviorSubjectService: BehaviorSubjectService
  ) { }

  ngOnInit(): void {
    this.tagUrl();
    this.tagUrlError();
  }
  
  nextSubject() {
    this.getPatientVulnerable$.next(null);
    this.getPatient$.next(null);
    this.getEps.next(null);
    this.getElectronicFormula.next([]);
    this.getElectronicFormulaS.next([]);
    this.getAttachedFormula.next([]);
    this.getAttachedFormulaSelected.next([]);
    this.getSelectAddress.next(null);
    this.getAddress.next(null);
    this.getContact.next(null);
    this.getAttachedFormulaNoPbs$.next(null);
    this.getAttachedFormulaNoPbsPreload$.next([]);
    localStorage.setItem('pedidoLandingDescripcion', '');
    this._router.navigate(['/inicio'], {
      relativeTo: this._route
    });
  }

  public tagUrl() {
    dataLayer.push({
      'event':'ga_virtual_Page',
      'titleVp':'Error',
      'urlVp':'/vp/error'
    });
  }

  public tagUrlError() {
    dataLayer.push({
        'event':'ga_event_error',
        'category':'Final',
        'action':'Error',
        'label': localStorage.getItem('pedidoLandingDescripcion'),
        'UES':'Medicamentos'
      });
  }

}
