import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';

import { FormulaService } from 'src/app/core/services/formula/formula.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
//import { IFormula, ITratamiento, Formula, Dispensing, Treatment, Dose, Product} from 'src/app/core/models/electronic-formula/electronic-formula';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IFormula } from 'src/app/core/models/electronic-formula/electronic-formula';


@Component({
  selector: 'app-electronic-formula',
  templateUrl: './electronic-formula.component.html',
  styleUrls: ['./electronic-formula.component.scss']
})
export class ElectronicFormulaComponent implements OnInit {

  @Input() formula:IFormula;
  @Input() last: number;
  @Output() sendFormulaSelected = new EventEmitter();
  @Output() sendFormulaDeselected = new EventEmitter();
  checked: boolean = false;
  formulaForm: FormGroup;
  see: string;
  formulas: any;
  imageSee = './assets/icons/formula/ver-mas.svg';

    /*VARIABLES BEHAVIOR SUBJECT*/
    getFormulaE$ = this._behaviorSubjectService.getElectronicFormula();

  constructor(
    private _formBuilder: FormBuilder,
    private _CHANGEDECTOR: ChangeDetectorRef,
    private _behaviorSubjectService: BehaviorSubjectService,
  ) {

    this.formulaForm = this._formBuilder.group({
      formu: [''],
      see: ['']
    });
  }

  ngOnInit(): void { 
    this._CHANGEDECTOR.markForCheck();

    this.see = `${this.last}fe`;

    this.formulas = this.getFormulaE$.getValue();

    if(this.formulas) {
      this.formulas.forEach(e => {
        if(e.number == this.formula.numero) {
          this.formulaForm.get('formu').setValue(e.number)
        }
      });
    }

  }

  public sendInformation(event){
    if (event.target.checked){
      this.sendFormulaSelected.emit({formula: this.formula});
    }else{
      this.sendFormulaDeselected.emit({formula: this.formula});
    }
  }

  public seeChange() {
    const see = this.formulaForm.get('see').value;

    if(see == '' || see == false) {
      this.imageSee = './assets/icons/formula/ver-menos.svg'
    } else {
      this.imageSee = './assets/icons/formula/ver-mas.svg'
    }
  }
}
