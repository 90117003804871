import { Component, OnInit, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { RegularExpressions } from 'src/app/core/helpers/regular-expressions/regular-expressions';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent implements OnInit {

  @Input() errors: ValidationErrors;
  @Input() errorPrefix: string;
  numberPattern = RegularExpressions.NUMBERS_ONLY_START_ZERO;
  numberPatternAddBeneficiary = RegularExpressions.ONLY_NUMBERS;
  numberLettersPattern = RegularExpressions.ONLY_NUMBERS_AND_LETTERS;
  onlyLetters = RegularExpressions.ONLY_LETTERS;
  onlyOtros = RegularExpressions.ONLY_OTROS;
  cellPhone = RegularExpressions.ONLY_NUMBER_CELLPHONE;
  validateEmail = RegularExpressions.VALIDATE_EMAIL;
  validateAddress = RegularExpressions.VALIDATE_ADDRESS;
  validateExt = RegularExpressions.VALIDATE_EXT;
  lettersSpaces = RegularExpressions.LETTERS_SPACES;
  lettersSpacesSpecials = RegularExpressions.LETTERS_SPACES_SPECIALS;
  formulaNumber = RegularExpressions.VALIDATE_AT_LEAST_ONE_NUMBER;
  constructor() { }

  ngOnInit() {
  }

  compareRegex(formError: string, regex: string) {
    if (!formError) {
      return;
    }
    return formError.includes(regex);
  }

}
