import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { environment } from 'src/app/configs/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderConfigurationService {

  constructor(
    private _HTTP: HttpService
  ) { }

  public getValidationStateAndAction(idEstado: string, accion: string){
    const params = {
      idEstado: idEstado,
      accion: accion
    };

    return this._HTTP.get<any>(`${environment.API_ORDER_CONFIGURATION}/estado/validacion`, { params })
      .pipe(
        map(
          res => res
        )
      );
  }

  public validateStatusOrderTimeZoneConfiguration(idCity: string, channel: string){
    const params = {
      idCiudad: idCity,
      canal: channel
    };

    return this._HTTP.get<any>(`${environment.API_ORDER_CONFIGURATION}/franja`, { params })
      .pipe(
        map(
          res => res
        )
      );
  }
}
