import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: string): string {
    let maskedSection = value.slice(3,-2);
    let firstSection = value.slice(0,3);
    let lastSection = value.slice(value.length - 2);

    return maskedSection 
      ? `${firstSection}${maskedSection.replace(maskedSection,'****')}${lastSection}` 
      : value;
  }

}
