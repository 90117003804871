<div [id]="idModal" aria-hidden="true" class="c-modal">
  <div tabindex="-1" data-micromodal-close class="c-modal_overlay">
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-1-title"
      class="c-modal_container c-modal_container--info"
    >
      <div class="c-modal_cont">
        <div class="c-modal_cont_text">
          <div class="cont-title-sec text-center">
            <h3 class="color-blue-dark">
              {{ title }}
            </h3>
          </div>

          <div class="d-flex justify-content-center">
            <div class="w-100 c-modal_cont_text_info">
              <div>
                {{ description }}
              </div>
            </div>
          </div>
        </div>

        <div class="c-modal_cont__buttons p-20">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
