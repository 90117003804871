import { Component, OnInit } from '@angular/core';
import { ParameterService } from 'src/app/core/services/parameter/parameter.service';
import { environment } from 'src/app/configs/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Parameter } from 'src/app/core/models/parameter/parameter';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  public terminos: any;

  constructor(
    private _parameterService: ParameterService,
    private _sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.getParameterTerminos();
  }

  getParameterTerminos() {
    this._parameterService.getParameters(environment.KEY_TERMS_AND_CONDITIONS).subscribe(response => {
      this.terminos = this._sanitizer.bypassSecurityTrustResourceUrl(response.parametro.valor);
    });
  }

}
