import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/app/configs/environment';
import { HttpService } from '../../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private _HTTP: HttpService
  ) { }

  public createOrder(order) {
    return this._HTTP.post<any>(`${environment.API_ORDER}/pedido/solicitarPedidoFormulaElectronica`, order)
      .pipe(
        map(
          respose => respose
        )
      );
  }

  public getGroupedOrders(identificationType: string, identification: string, type: string) {
    const params = {
      identification_type: identificationType,
      identification: identification,
      type: type
    };

    return this._HTTP.get<any>(`${environment.API_ORDER}/pedido/consultarPedidosAgrupados`, { params })
      .pipe(
        map(
          res => res
        )
      );
  }

  public modifyOrder(order, state: string, userUpdate: string) {
    const params = {
      status: state,
      statusDate: "2020-10-30T15:59:18.000-0500",
      usrCreacion: order.usrCreacion,
      usrActua: userUpdate,
      pac: "0",
      transporterCode: order.transporter,
      type: order.type,
      channel: order.channel,
      code: order.id,
      action: "modificar"

    }
    return this._HTTP.put<any>(`${environment.API_ORDER}/pedido/modificarPedido`, params)
      .pipe(
        map(
          res => res
        )
      );
  }

  public getOrderDetail(idOrden: string, tipo: string) {
    const params = {
      id: idOrden,
      type: tipo
    };

    return this._HTTP.get<any>(`${environment.API_ORDER}/pedido/consultardetallepedidoporid`, {params})
      .pipe(
        map(
          res => res
        )
      );
  }


  public modifyAddressOrder(request: any) {
    return this._HTTP.put<any>(`${environment.API_ORDER}/pedido/modificardireccionpedido`, request)
      .pipe(
        map(
          res => res
        )
      );
  }

  public getTimeDelivery(params :any) {

    return this._HTTP.get<any>(`${environment.API_ORDER}/pedido/tiempoEntregaPedido`, {params})
      .pipe(
        map(
          res => res
        )
      );
  }


  public getIdDispensaryByGeoreference(order: any){
   
      return this._HTTP.post<any>(`${environment.API_ORDER}/pedido/obtenerdispensarioporgeoreferencia`, order)
        .pipe(
          map(
            respose => respose
          )
        );
    }

    public getIdDispensaryByGeoreferenceAndAddress(order: any){
   
      return this._HTTP.post<any>(`${environment.API_ORDER}/pedido/georeferenciadispensariopordireccion`, order)
        .pipe(
          map(
            respose => respose
          )
        );
    }
}
