import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SubjectRecurrent } from 'src/app/core/models/recurrent/subjectRecurrent';

@Injectable({
  providedIn: 'root'
})
export class BehaviorSubjectService {

  private patient: BehaviorSubject<any> = new BehaviorSubject(null);
  private patientVulnerable: BehaviorSubject<any> = new BehaviorSubject(null);
  private eps: BehaviorSubject<any> = new BehaviorSubject(null);
  private electronicFormula: BehaviorSubject<any> = new BehaviorSubject([]);
  private searchElectronicFormula: BehaviorSubject<any> = new BehaviorSubject([]);
  private attachedFormula: BehaviorSubject<any> = new BehaviorSubject([]);
  private attachedFormulaSelected: BehaviorSubject<any> = new BehaviorSubject([]);
  private addresses: BehaviorSubject<any> = new BehaviorSubject(null);
  private selectAddress: BehaviorSubject<any> = new BehaviorSubject(null);
  private address: BehaviorSubject<any> = new BehaviorSubject(null);
  private contact: BehaviorSubject<any> = new BehaviorSubject(null);
  private attachedFormulaNoPbs: BehaviorSubject<any> = new BehaviorSubject(null);
  private attachedFormulaNoPbsPreload: BehaviorSubject<any> = new BehaviorSubject([]);
  private order: BehaviorSubject<any> = new BehaviorSubject(null);
  private scheduleDelivery: BehaviorSubject<any> = new BehaviorSubject(null);
  private subjectRecurrent: BehaviorSubject<any> = new BehaviorSubject(null);
  private resumenRecurrent: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }

  getPatient(): BehaviorSubject<any> {
    return this.patient;
  }

  getPatientVulnerable(): BehaviorSubject<any> {
    return this.patientVulnerable;
  }

  getEps(): BehaviorSubject<any> {
    return this.eps;
  }

  getElectronicFormula(): BehaviorSubject<any> {
    return this.electronicFormula;
  }

  getElectronicSearchFormula(): BehaviorSubject<any> {
    return this.searchElectronicFormula;
  }

  getAttachedFormula(): BehaviorSubject<any> {
    return this.attachedFormula;
  }

  getAttachedFormulaSelected(): BehaviorSubject<any> {
    return this.attachedFormulaSelected;
  }

  getAddresses(): BehaviorSubject<any> {
    return this.addresses;
  }

  getSelectAddress(): BehaviorSubject<any> {
    return this.selectAddress;
  }

  getAddress(): BehaviorSubject<any> {
    return this.address;
  }

  getContact(): BehaviorSubject<any> {
    return this.contact;
  }

  getAttachedFormulaNoPbs(): BehaviorSubject<any>{
    return this.attachedFormulaNoPbs
  }

  getAttachedFormulaNoPbsPreload(): BehaviorSubject<any>{
    return this.attachedFormulaNoPbsPreload
  }

  getOrder(): BehaviorSubject<any>{
    return this.order
  }

  getScheduleDelivery(): BehaviorSubject<any>{
    return this.scheduleDelivery;
  }
  
  getSubjectRecurrent(): BehaviorSubject<any>{
    return this.subjectRecurrent;
  }
  
   getResumenRecurrent(): BehaviorSubject<any>{
    return this.resumenRecurrent;
  }
}
