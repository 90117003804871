<div>
	<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>
	<div>
		<form [formGroup]="patientRecurrentForm">
			<div class="ctn-fields">
				<div class="ctn-input">
				
					<div class="d-flex f-wrap space-between">
						<div class="ctn-data">
							<label for="typeDocument">
								Tipo de documento 

								<input class="i-data" type="text" name="typeDocument" id="typeDocument" disabled="true"
									formControlName="typeDocument">
							</label>
						</div>

						<div class="ctn-data">
							<label for="document">
								Número de documento 

								<input class="i-data" type="text" name="document" id="document" disabled="true"
									formControlName="document">
							</label>

						</div>
					</div>
					
					<div class="ctn-terms">
						<input type="checkbox" class="input-terms" name="termsHabeas" id="termsHabeas"
							formControlName="termsHabeas" (change)="fillTermsHabeas()">

						<label for="termsHabeas" class="label-terms">
							Al continuar, aceptas 

							<a [routerLink]="['/habeas-data']" target="_blank" class="c-blue-medium">
								la política y tratamiento de datos
							</a>

							y los

							<a [routerLink]="['/terminos-y-condiciones']" target="_blank" class="c-blue-medium">
								términos y condiciones
							</a>
						</label>

						<div class="check-terms"></div>
					</div>
					
					<div class="ctn-terms">
						<input type="checkbox" class="input-terms" name="termsHabeasRecurrent" id="termsHabeasRecurrent"
							formControlName="termsHabeasRecurrent" (change)="fillTermsHabeasRecurrent()">

						<label for="termsHabeasRecurrent" class="label-terms">
							Al continuar, aceptas 

							<a [routerLink]="['/habeas-data']" target="_blank" class="c-blue-medium">
								la política de pedidos recurrentes
							</a>
						</label>
						<div class="check-terms"></div>
					</div>
					
					<div class="d-flex space-between buttons">
					    <div></div>
					    <div></div>
						<button type="button" (click)="nextSubject()" [disabled]="control1!=control2 || control1!=true">
							<img src="./assets/icons/navegacion/boton-continuar-{{control1 && control2 ?'habilitado':'deshabilitado'}}.svg" alt="icon">
						</button>
					</div>
				</div>
			</div>

		</form>
	</div>
</div>