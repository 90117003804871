import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';

@Component({
  selector: 'app-modal-exit',
  templateUrl: './modal-exit.component.html',
  styleUrls: ['./modal-exit.component.scss']
})
export class ModalExitComponent implements OnInit {

  @Input() idModal: string;
  //@Input() textDescription: string;

   /*VARIABLES BEHAVIOR SUBJECT*/
   getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
   getPatient$ = this._behaviorSubjectService.getPatient();
   getEps = this._behaviorSubjectService.getEps();
   getElectronicFormula = this._behaviorSubjectService.getElectronicFormula();
   getAttachedFormula = this._behaviorSubjectService.getAttachedFormula();
   getAttachedFormulaSelected = this._behaviorSubjectService.getAttachedFormulaSelected();
   getSelectAddress = this._behaviorSubjectService.getSelectAddress();
   getAddress = this._behaviorSubjectService.getAddress();
   getContact = this._behaviorSubjectService.getContact();
   getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
   getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();

  constructor(
    private _MODAL: ModalService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _behaviorSubjectService: BehaviorSubjectService
  ) { }

  ngOnInit(): void {
  }

  close() {
    this._MODAL.closeModal(this.idModal);
  }

  cancel() {
    this.getPatientVulnerable$.next(null);
    this.getPatient$.next(null);
    this.getEps.next(null);
    this.getElectronicFormula.next([]);
    this.getAttachedFormula.next([]);
    this.getAttachedFormulaSelected.next([]);
    this.getSelectAddress.next(null);
    this.getAddress.next(null);
    this.getContact.next(null);
    this.getAttachedFormulaNoPbs$.next(null);
    this.getAttachedFormulaNoPbsPreload$.next([]);

    this._router.navigate(['/inicio'], {
      relativeTo: this._route
    });

    this._MODAL.closeModal(this.idModal);
  }
}
