<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div class="ctn-pedido">
  <div class="group-info f-300 text-center">
    Intenta de nuevo, para poder registrar tu pedido de forma exitosa.
  </div>

  <div class="ctn-button">
    <button class="btn-end" (click)="nextSubject()">
      Volver
    </button>
  </div>
</div>
