export const TITLE = 'titles';
export const TEXT = 'texts';
export const IMAGE = 'image';
export const IMAGES_PORTAL = 'PORTAL_MEDICAMENTOS_HOME';
export const MESSAGES = [
    'PORTAL_SERVICIOS_PRIMER_TITULO',
    'PORTAL_SERVICIOS_PRIMER_TEXTO',
    'PORTAL_SERVICIOS_SEGUNDO_TITULO',
    'PORTAL_SERVICIOS_SEGUNDO_TEXTO',
    'PORTAL_SERVICIOS_TERCER_TITULO',
    'PORTAL_SERVICIOS_TERCER_TEXTO',
    'PORTAL_SERVICIOS_CUARTO_TITULO',
    'PORTAL_SERVICIOS_CUARTO_TEXTO',
    'PORTAL_PRIMER_TITULO',
    'PORTAL_PRIMER_TEXTO',
    'PORTAL_SEGUNDO_TITULO',
    'PORTAL_SEGUNDO_TEXTO',
    'PORTAL_TERCER_TITULO',
    'PORTAL_TERCER_TEXTO'
]