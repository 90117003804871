<div>
	<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

	<div>
		<form [formGroup]="contactOrderForm">
			<div class="ctn-fields">
				<div class="d-flex space-between buttons">
					<button (click)="toReturn()" class="btn-return">
						<img src="./assets/icons/navegacion/atras.svg" alt="icon">
					</button>
				</div>
				<div class="ctn-input ">
					<div class="f-16">
						INFORMACIÓN DEL PACIENTE
					</div>
					<div class="d-flex space-between">
						<label>
							<div class="d-flex space-between p-20">
								<span>
									<span class="d-block name">
										{{documentType}}
									</span>

									<span class="address">
										{{patient.identification}}
									</span>
								</span>
							</div>
						</label>
						<label>
							<div class="d-flex space-between p-20">
								<span>
									<span class="d-block name">
										NOMBRE
									</span>

									<span class="address">
										{{patient.name}} {{patient.surname}}
									</span>
								</span>
							</div>
						</label>
					</div>
					<div class="d-flex space-between">
						<label>
							<div class="d-flex space-between p-20">
								<span>
									<span class="d-block name">
										CORREO ELECTRÓNICO
									</span>

									<span class="address">
										{{patient.email}}
									</span>
								</span>
							</div>
						</label>
						<label>
							<div class="d-flex space-between p-20">
								<span>
									<span class="d-block name">
										TELÉFONO
									</span>

									<span class="address">
										{{patient.phone}}
									</span>
								</span>
							</div>
						</label>
					</div>
					<div *ngIf="afiliado">
						<div class="f-16">
							ACTUALIZACIÓN DEL TELÉFONO
						</div>
						<div class="d-flex space-between">
							<label>
								<div class="d-flex space-between p-20">
									<span>
										<span class="d-block name">
											TELÉFONO
										</span>
										<div>
											<input class="i-data"  type="number" name="telefono" id="form1"
												placeholder="Digita el nuevo número 3XXXXXXXXX" formControlName="telefono">
										</div>
									</span>

								</div>
							</label>
							<label>
								<p><button (click)="updatePhone()" class="btn-end d-flex">Actualizar
									</button>
								</p>
							</label>
						</div>
					</div >
					<br><br><br>
					<label>
						<div class="ctn-button" *ngIf="afiliado">
							<label>
								<div class="address">
									{{descripcionRecurrentes}}
								</div>
								<div class="d-flex space-between">
									<div></div>
									<p><button (click)="openModal()" class="btn-disaffiliate">Desafiliar
										</button>
									</p>
									<div></div>
								</div>
							</label>
						</div>
					</label>
				</div>
			</div>
		</form>
	</div>
	<app-modal-generic [idModal]="idModal" [title]="titlebox" [description]="subTitleBox">
		<button class="w-50 btn btn1" (click)="closeModal(); ">
			Cancelar
		</button>
		<button class="w-50 btn btn2" (click)="cancelAfilliate(); ">
			Aceptar
		</button>
	</app-modal-generic>
</div>