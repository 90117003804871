import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/core/services/order/order.service';
import { Order } from 'src/app/core/models/order/order';
import { Location } from '@angular/common';

import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { GetPatient } from 'src/app/core/models/patient/patient';
import { Address } from 'src/app/core/models/address/address';
import { environment } from 'src/app/configs/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { IScheduleDelivery } from 'src/app/core/models/schedule-delivery/schedule-delivery';
import { Parameter } from 'src/app/core/models/parameter/parameter';
import { ParameterService } from 'src/app/core/services/parameter/parameter.service';

declare let dataLayer;

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  icon = './assets/icons/verificar.svg';
  title = 'Verifica que todos los datos sean correctos';

  progress = 83;
  /*Variables de servicios*/
  order: Order;
  
  public parameter: Parameter;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getPatient$ = this._behaviorSubjectService.getPatient();
  getEps$ = this._behaviorSubjectService.getEps();
  getFormulaE$ = this._behaviorSubjectService.getElectronicFormula();
  getFormulaA$ = this._behaviorSubjectService.getAttachedFormula();
  getFormulaAS$ = this._behaviorSubjectService.getAttachedFormulaSelected();
  getFormulaS$ = this._behaviorSubjectService.getElectronicSearchFormula();
  getAddresses$ = this._behaviorSubjectService.getAddresses();
  getAddress$ = this._behaviorSubjectService.getAddress();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
  getScheduleDelivery$ = this._behaviorSubjectService.getScheduleDelivery();


  public vulnerable: PatientVulnerable;
  public eps: Eps;
  public patient: GetPatient;
  public address: any;
  public addresLocal: string;
  public formulaA: any;
  public formulaAS: any;
  public formulaE: any;
  public formulaS: any;
  public formulas = [];
  public addresses: any;
  public disabled: boolean = false;
  public enableTimeZone: boolean = false;
  public attachedFormulaNoPbs: any;
  public scheduleDelivery: IScheduleDelivery;

  constructor(
    private _orderService: OrderService,
    private _behaviorSubjectService: BehaviorSubjectService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location,
    private _parameterService: ParameterService
  ) {
    /*SUBSCRIBE BEHAVIOR SUBJECT */
    this.vulnerable = this.getPatientVulnerable$.getValue();
    this.eps = this.getEps$.getValue();
    this.patient = this.getPatient$.getValue();
    this.address = this.getAddress$.getValue();
    this.formulaA = this.getFormulaA$.getValue();
    this.formulaAS = this.getFormulaAS$.getValue();
    this.formulaE = this.getFormulaE$.getValue();
    this.formulaS = this.getFormulaS$.getValue();
    this.addresses = this.getAddresses$.getValue()
    this.attachedFormulaNoPbs = this.getAttachedFormulaNoPbs$.getValue();
    this.scheduleDelivery = this.getScheduleDelivery$.getValue();
    this.getEnableTimeZone();
  }

  ngOnInit(): void {

    this.tagUrl();
    if(this.attachedFormulaNoPbs != null) {
      this.formulas = this.formulaE.concat(this.formulaAS, this.formulaS, this.attachedFormulaNoPbs);
    }else{
    
    this.formulas = this.formulaE.concat(this.formulaAS, this.formulaS);
    }
    this.addresLocal = localStorage.getItem('direccion');
  }

  fillOrder() {
    this.order = new Order();
    this.order.identificationType = this.vulnerable.tipoIdentificacion;
    this.order.identification = this.vulnerable.identificacion;
    this.order.idEps = this.eps.id;
    this.order.idCity = this.addresses.idCity;
    this.order.channel = environment.CHANNEL;
    this.order.type = environment.TYPE;
    this.order.idAddress = this.address.idAddress;
    this.order.usrCreation = `${this.vulnerable.identificacion}_${this.vulnerable.tipoIdentificacion}`;
    this.order.formulas = this.formulas;
    this.order.domicileApply = this.vulnerable.domicileApply;
    if(this.enableTimeZone){
      this.order.idFranja= this.scheduleDelivery.date;
      this.order.deliveryDate= this.scheduleDelivery.schedule;
      this.order.dispensary= this.scheduleDelivery.idDispensary;
      this.order.timeZoneApply = "1";
    }
  }

  public createOrder() {
    this.tagContinue();
    this.fillOrder();
    this.disabled = true;
    this._orderService.createOrder(this.order).subscribe(
      response => {
        if (response.codigo == '100') {
          localStorage.setItem('pedidoLanding', response.id);
          this.goToSuccessful();
        } else {
          localStorage.setItem('pedidoLandingDescripcion', response.descripcion);
          this.goToError();
        }
      }
    );
  }

  toReturn() {
    this._location.back();
  }

  private goToSuccessful() {
    this._router.navigate(['/pedido-exitoso'], {
      relativeTo: this._route
    });
  }

  private goToError() {
    this._router.navigate(['/pedido-fallido'], {
      relativeTo: this._route
    });
    localStorage.setItem('editar', '');
  }

  getParameterKey(key: string) {
    this._parameterService.getParameters(key).subscribe(
      response => {
        this.parameter = response.parametro;
      }
      
    );
  }

  getEnableTimeZone(){
    if(localStorage.getItem('enableSchedule') == 'true'){
      this.enableTimeZone = true;
    }else{
      this.enableTimeZone = false;
    }
  }

  public tagUrl() {
    dataLayer.push({
      'event':'ga_virtual_Page',
      'titleVp':'Resumendatos',
      'urlVp':'/vp/resumendatos'
    });
  }

  public tagContinue() {
    dataLayer.push({
      'event':'ga_event_resumen',
      'category':'resumen_datos',
      'action':'Clic-boton',
      'label':'confirmar_pedido',
      'UES':'Medicamentos'
    });
  }

  public urlContinue() {
    localStorage.setItem('editar', 'verificacion');
  }

}
