import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() option: string;
  @Input() label: string;
  @Input() id: string;
  @Input() for: string;
  @Input() value: string;

  constructor() { }

  ngOnInit(): void {
  }

}
