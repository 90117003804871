import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/core/services/order/order.service';
import { Location } from '@angular/common';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { Order } from 'src/app/core/models/order/order';
import { environment } from 'src/app/configs/environment';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { GetPatient } from 'src/app/core/models/patient/patient';
import { IScheduleDelivery } from 'src/app/core/models/schedule-delivery/schedule-delivery';
import { FormulaService } from 'src/app/core/services/formula/formula.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { DatePipe } from '@angular/common';
import { ParameterService } from 'src/app/core/services/parameter/parameter.service';

declare let dataLayer;

@Component({
  selector: 'app-schedule-delivery',
  templateUrl: './schedule-delivery.component.html',
  styleUrls: ['./schedule-delivery.component.scss']
})
export class ScheduleDeliveryComponent implements OnInit {

  icon = './assets/icons/programar-entrega.svg';
  title = '¿Para que día programamos la entrega?';
  subtitle = 'Elige la fecha y hora.';
  progress = 16.6;
  progressLoadBar: number = 10;
  idModal = 'addressManager';
  ModalTitle: string;
  ModalDescription: string;
  fastDeliveryTitle = "Entrega rápida";
  fastDeliveryMessage: string;
  fastDaliveryEnabled: boolean;
  fastDaliveryisActive: boolean;
  dateEnable: boolean;

  public scheduleDelivery: IScheduleDelivery;
  public timeDeliveryList: any;
  public timeZoneList: any;
  public idDispensary: string;
  public showLoadSpinner: boolean = true;
  public timeZone: any;

  activate: boolean = false;

  order: Order;

  scheduleDeliveryForm: FormGroup;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getPatient$ = this._behaviorSubjectService.getPatient();
  getEps$ = this._behaviorSubjectService.getEps();
  getFormulaE$ = this._behaviorSubjectService.getElectronicFormula();
  getFormulaA$ = this._behaviorSubjectService.getAttachedFormula();
  getFormulaAS$ = this._behaviorSubjectService.getAttachedFormulaSelected();
  getFormulaS$ = this._behaviorSubjectService.getElectronicSearchFormula();
  getAddresses$ = this._behaviorSubjectService.getAddresses();
  getAddress$ = this._behaviorSubjectService.getAddress();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
  getScheduleDelivery$ = this._behaviorSubjectService.getScheduleDelivery();

  public vulnerable: PatientVulnerable;
  public eps: Eps;
  public patient: GetPatient;
  public address: any;
  public addresLocal: string;
  public formulaA: any;
  public formulaAS: any;
  public formulaE: any;
  public formulaS: any;
  public formulas = [];
  public formulasAux = [];
  public addresses: any;
  public disabled: boolean = false;
  public attachedFormulaNoPbs: any;
  public dateExpedition: string;
  public editar: string = '';

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _order: OrderService,
    private _parameter: ParameterService,
    private _formula: FormulaService,
    private _formBuilder: FormBuilder,
    private _location: Location,
    private _behaviorSubjectService: BehaviorSubjectService,
    private _localStorage: LocalStorageService,
    private _modal: ModalService
  ) {

    /*SUBSCRIBE BEHAVIOR SUBJECT */
    this.vulnerable = this.getPatientVulnerable$.getValue();
    this.eps = this.getEps$.getValue();
    this.patient = this.getPatient$.getValue();
    this.address = this.getAddress$.getValue();
    this.formulaA = this.getFormulaA$.getValue();
    this.formulaAS = this.getFormulaAS$.getValue();
    this.formulaE = this.getFormulaE$.getValue();
    this.formulaS = this.getFormulaS$.getValue();
    this.addresses = this.getAddresses$.getValue()
    this.attachedFormulaNoPbs = this.getAttachedFormulaNoPbs$.getValue();
    this.scheduleDelivery = this.getScheduleDelivery$.getValue();

    this.scheduleDeliveryForm = this._formBuilder.group({
      schedule: [
        '',
        [
          Validators.required
        ]
      ],
      date: [
        '',
        [
          Validators.required
        ]
      ]
    });
    if (this.attachedFormulaNoPbs != null) {
      this.formulas = this.formulaE.concat(this.formulaAS, this.formulaS, this.attachedFormulaNoPbs);
    } else {
      this.formulas = this.formulaE.concat(this.formulaAS, this.formulaS);
    }
    this.editar = this._localStorage.getItemString("editar");
    this.removeFilesToFormulaObject();


  }

  ngOnInit(): void {
    this.dateEnable = true;
    if (this.scheduleDelivery != null) {
      this.scheduleDeliveryForm.get('schedule').setValue(this.scheduleDelivery.schedule);
      this.scheduleDeliveryForm.get('date').setValue(this.scheduleDelivery.date);
    }
  }

  toReturn() {
    this._location.back();
  }

  validateFastDelivery() {
    this._parameter.validateStatusFastDelivery(this.order.idCity, 100, this.idDispensary).subscribe(response =>{
      if(response.resultado.codigo == 100){
        this.fastDaliveryEnabled = true;
        this.fastDaliveryisActive = true;
        this.fastDeliveryMessage = response.resultado.descripcion;
      } else if (response.resultado.codigo == 101) {
        this.fastDaliveryEnabled = false;
        this.fastDaliveryisActive = false;
        this.fastDeliveryMessage = response.resultado.descripcion;
      } else {
        this.fastDaliveryEnabled = false;
        this.fastDaliveryisActive = false;
        this.fastDeliveryMessage = response.resultado.descripcion;
      }
    }, error => {
      this.fastDaliveryEnabled = false;
      this.fastDaliveryisActive = false;
      });
  }


  prepareOrderFastDelivery(){
    this.dateEnable = false;
    this.timeZoneList = [100];
    let d = new Date();
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    this.scheduleDeliveryForm.setValue({schedule: `${ye}-${mo}-${da}`, date: '100'});
  }

  public getTimeDelivery() {
    let req = {
      idCity: this.order.idCity,
      idEps: this.order.idEps,
      idDispensary: this.idDispensary,
      type: this.order.type,
      channel: this.order.channel,
      dateLimitFormula: this.dateExpedition
    }
    this._order.getTimeDelivery(req).subscribe(
      response => {
        this.timeDeliveryList = response.zones;
        if (this.scheduleDelivery != null) {
          this.timeDeliveryList.forEach(element => {
            if (element.date == this.scheduleDelivery.schedule) {
              this.showLoadSpinner = false;
              this.setTimeZoneList(element.timeZones);
            }
          });
        }
      }
    );
    this.validateFastDelivery();
  }

  public getIdDispensaryByGeoreference() {
    this._order.getIdDispensaryByGeoreference(this.order).subscribe(
      response => {
        this.idDispensary = response.id;
        if (this.idDispensary != null && this.idDispensary != '') {
          this.getTimeDelivery();
        }
      }
    );
  }

  public getFormulaExpirationDate() {
    this._formula.getFormulaExpirationDate(this.formulasAux).subscribe(
      response => {
        this.dateExpedition = response.fechaVencimiento;
        this.loadAvailability();
      }
    );
  }


  public setTimeZoneList(list: any) {
    this.dateEnable = true;
    this.timeZoneList = list;
  }

  fillOrder() {
    this.order = new Order();
    this.order.identificationType = this.vulnerable.tipoIdentificacion;
    this.order.identification = this.vulnerable.identificacion;
    this.order.idEps =  this.eps == null || this.eps == undefined ? '': this.eps.id;
    this.order.idCity = this.addresses.idCity == null || this.addresses.idCity == undefined ? this.addresses.idCiudad : this.addresses.idCity;
    this.order.channel = environment.CHANNEL;
    this.order.type = environment.TYPE;
    this.order.idAddress = this.address.idAddress;
    this.order.usrCreation = `${this.vulnerable.identificacion}_${this.vulnerable.tipoIdentificacion}`;
    this.order.formulas = this.formulas;
    this.order.domicileApply = this.vulnerable.domicileApply;
  }

  loadAvailability() {
    this.showLoadBar();
    this.fillOrder();
    if (this.editar === 'gestionaDireccion') {
      this.idDispensary = this.addresses.idDispensario;
      this.getTimeDelivery();
    } else {
      this.getIdDispensaryByGeoreference();
    }
  }


  nextSubject() {
    console.log(this.timeZoneList);
    this.timeZoneList.forEach(element => {
      if (element.id == this.scheduleDeliveryForm.get('date').value) {
        this.timeZone = element.timeZone
      }
    });
    this.scheduleDelivery = {
      schedule: this.scheduleDeliveryForm.get('schedule').value,
      date: this.scheduleDeliveryForm.get('date').value,
      franja: this.timeZone,
      idDispensary: this.idDispensary
    }
    this.getScheduleDelivery$.next(this.scheduleDelivery);
    console.log(this.scheduleDeliveryForm);

    if (this.editar == 'verificacion') {
      this._router.navigate(['/verificacion-pedido'], {
        relativeTo: this._route
      });
    } else {
      this._router.navigate(['/contacto'], {
        relativeTo: this._route
      });
    }
    this.tagDelivered();
  }

  public showLoadBar() {
    const timerConst = setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (this.progressLoadBar == 100) {
          this.showLoadSpinner = false;
          clearInterval(progressInterval);
        } else {
          this.progressLoadBar = this.progressLoadBar + 10;
        }
      }, 100);
    }, 200);
  }


  public updateAddress() {
    let idTimeZone: string;
    this.timeZoneList.forEach(element => {
      if (element.id == this.scheduleDeliveryForm.get('date').value) {
        idTimeZone = element.id
      }
    });
    this.addresses.idFranja = idTimeZone;
    this.addresses.fechaEntrega = this.scheduleDeliveryForm.get('schedule').value;
    this._order.modifyAddressOrder(this.addresses).subscribe(
      response => {
        if (response.resultado.codigo == '100') {
          setTimeout(() => {
            this._router.navigate(['/gestionar-pedido'], {
              relativeTo: this._route
            });
          }, 30);
        } else {
          this.openModal("Error", "No se pudo actualizar la dirección,por favor intentelo más tarde \n Error: " + response.resultado.descripcion);
        }

      },
      err => {
      }
    );
    this.activate = true
  }

  public openModal(title: string, description: string) {
    this.title = title;
    this.ModalDescription = description;
    this._modal.openModal(this.idModal);
  }

  public closeModal() {
    this._router.navigate(['/gestionar-pedido'], {
      relativeTo: this._route
    });
    this._modal.closeModal(this.idModal);
  }


  public removeFilesToFormulaObject() {
    this.formulas.forEach(element => {
      let formula ={
        authorization: element.authorization,
        check: element.check,
        files: null,
        number: element.number,
        sizeTotal: element.sizeTotal,
      }
      this.formulasAux.push(formula);
     });
    this.getFormulaExpirationDate();
  }

  public tagDelivered() {
    let datePipe = new DatePipe('es');
    let nameDay = datePipe.transform(this.scheduleDelivery.schedule,'EEEE');
    this.scheduleDelivery;
    dataLayer.push({
      'event':'ga_event_continuarprogramacion',
      'category':'Entrega',
      'action':'Clic-boton',
      'label':'Programacion entrega',
      'UES':'Medicamentos',
      'Dia': nameDay,
      'Horario':this.scheduleDelivery.franja
    });
  }


}
