import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getItemString(key: string) {
    return localStorage.getItem(key);
  }
  stringifyItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  parseItem<T>(key: string) {

    return JSON.parse(localStorage.getItem(key)) as T;
  }

  setItem(key: string, item: any) {
    localStorage.setItem(key, item);
  }

  getItemJSON(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  saveWithExpiration(key, value) {
    const dataExpiration = { 'data': value, 'expirationDate': new Date().getTime() };
    localStorage.setItem(key, JSON.stringify(dataExpiration));
  }

  findWithExpiration(key) {
    const data = this.parseItem<any>(key);
    const dateNow = new Date();

    if (data == null || dateNow.getTime() - data.expirationDate > (1 * 60 * 60 * 1000)) { // un dia en ms
      localStorage.removeItem(key);
      return null;
    } else {
      return data.data;
    }
  }

}
