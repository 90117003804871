<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div>
  <form [formGroup]="findAddressRecurrentForm">
    <div class="ctn-fields">
      <div class="d-flex ctn-select">
        <div class="select">
          <label for="department" class="t-doc">
            Departamento
        
            <select id="department" name="department" formControlName="department" (change)="getCitiesDepartaments()">
              <option value="" disabled selected>Seleccione...</option>
              <option *ngFor="let depa of departments" [value]="depa.id">
                {{depa.name}}
              </option>
            </select>
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="findAddressRecurrentForm.get('department').touched"
                            [errors]="findAddressRecurrentForm.get('department').errors">
            </app-form-errors>
          </div>
        </div>

        <div class="select">
          <label for="city" class="t-doc">
            Ciudad
        
            <select id="city" name="city" formControlName="city" (change)="getAddress()">
              <option value="" disabled selected>Seleccione...</option>
              <option *ngFor="let city of cities" [value]="city.idCiudad">
                {{city.nombreCiudad}}
              </option>
            </select>
          </label>

          <div class="text-invalid">
            <app-form-errors *ngIf="findAddressRecurrentForm.get('city').touched"
                            [errors]="findAddressRecurrentForm.get('city').errors">
            </app-form-errors>
          </div>
        </div>
      </div>

      <div class="d-flex space-between buttons">
        <button (click)="toReturn()" class="btn-return">
          <img src="./assets/icons/navegacion/atras.svg" alt="icon">
        </button>

        <button *ngIf="!findAddressRecurrentForm.valid || !enabledContinue" type="button">
          <img src="./assets/icons/navegacion/boton-continuar-deshabilitado.svg" alt="icon">
        </button>

        <button type="button" (click)="nextSubject()" *ngIf="findAddressRecurrentForm.valid && enabledContinue">
          <div class="btn-next"></div>
        </button>
      </div>
    </div>
  </form>
</div>
