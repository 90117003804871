import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/app/configs/environment';
import { SubjectRecurrent } from 'src/app/core/models/recurrent/subjectRecurrent';

@Injectable({
	providedIn: 'root'
})

export class RecurrentService {

	constructor(
		private _HTTP: HttpService
	) { }

	getRecurrentByToken(token: string): Observable<any> {
		const params = {
			token: token,
		};

		return this._HTTP.get<any>(`${environment.API_RECURRENT}/valida/token`, { params })
			.pipe(
				map(
					response => response.datos
				)
			);
	}

	notifyRecurrent(recurrent: SubjectRecurrent): Observable<any> {
		return this._HTTP.put<any>(`${environment.API_RECURRENT}/aceptarPedidoRecurrente`, recurrent)
			.pipe(
				map(
					response => response
				)
			);
	}

	getStatusAffiliate(identificationType: string, identification: string): Observable<any> {
		const params = {
			tipoIdentificacion: identificationType,
			identificacion: identification
		};

		return this._HTTP.get<any>(`${environment.API_RECURRENT}/afiliacion/recurrente`, { params })
			.pipe(
				map(
					response => response
				)
			);
	}
	
	UpdateAffiliate(identificationType: string, identification: string, value:boolean): Observable<any> {
		let params = {
			tipoIdentificacion: identificationType,
			identificacion: identification,
			afiliacion:value
		};

		return this._HTTP.put<any>(`${environment.API_RECURRENT}/afiliacion/recurrente`, params)
			.pipe(
				map(
					response => response
				)
			);
	}
}