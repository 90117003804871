export class InformationFormatting {
  public static returnImageEps(eps: string) {
    if (eps === '800088702') {
      return './assets/icons/eps/suragris.svg';
    } else if (eps === '830003564') {
      return './assets/images/formulas/autorizacion-famisanar.jpg';
    } else if (eps === '900156264') {
      return './assets/images/formulas/autorizacion-neps.jpg';
    } else if (eps === '805000427') {
      return './assets/images/formulas/autorizacion-coomeva.jpg';
    }
  }

  public static returnNameDocument(typeDocument: string) {
    if (typeDocument === 'CC') {
      return 'Cédula de Ciudadanía';
    } else if (typeDocument === 'CE') {
      return 'Cédula de Extranjería';
    } else if (typeDocument === 'PA') {
      return 'Pasaporte';
    } else if (typeDocument === 'PE') {
      return 'Permiso Especial de Permanencia';
    } else if (typeDocument === 'RC') {
      return 'Registro Civil';
    } else if (typeDocument === 'TI') {
      return 'Tarjeta de Identidad';
    } else if (typeDocument === 'CD') {
      return 'Carnét Diplomático';
    }
  }
}
