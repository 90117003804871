import { Component, OnInit, Output, EventEmitter, ɵConsole } from '@angular/core';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { ProgressService } from 'src/app/core/services/progress/progress.service';
import { Router } from '@angular/router';
import { environment } from 'src/app/configs/environment';
import { DomSanitizer } from '@angular/platform-browser';

declare let dataLayer;

@Component({
  selector: 'app-attached-formula',
  templateUrl: './attached-formula.component.html',
  styleUrls: ['./attached-formula.component.scss']
})
export class AttachedFormulaComponent implements OnInit {

  @Output() showLoadImage = new EventEmitter();

  public files = [];
  public file: any;
  public fileSe = new Object();
  public fileSe1 = [];
  public fileMultiple = [];
  public mb: any;
  public fileDuplicated: boolean = false;
  maxSize = environment.SIZE_FILE_MAX;

  deploymentForm: FormGroup;

  showInput: boolean = false;
  showAuthorization: boolean;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getFormulaA$ = this._behaviorSubjectService.getAttachedFormula();
  getFormulaAS$ = this._behaviorSubjectService.getAttachedFormulaSelected();
  getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();

  public formulas: any;
  public objFormulaNoPbs = [];
  public orderFormulaNoPbs: any;

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  showMessage: boolean = false;
  showSizeTotal: boolean = false;
  weightImages: number;
  fileZise = [];
  fileSizeTotal: any;

  createFormula: boolean = false;
  public progress: number = 0;
  public newFile: any;
  public size: string;
  createFormulaImage: boolean = false;
  showLoad: boolean = false;
  showMultiple: boolean = false;
  loadingBar: boolean = false;
  showDes: boolean = false;
  selectedPhotos = [];

  constructor(
    private _behaviorSubjectService: BehaviorSubjectService,
    private _formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
  ) {
    this.deploymentForm = this._formBuilder.group({
      deployment: ['']
    });
  }

  ngOnInit(): void {
    this.formulas = this.getFormulaA$.getValue();
    this.selectedPhotos = this.getFormulaAS$.getValue();
    this.files = this.formulas;
    this.showMessage = false;

    this.objFormulaNoPbs = this.getAttachedFormulaNoPbsPreload$.getValue();
    this.orderFormulaNoPbs = this.getAttachedFormulaNoPbs$.getValue()
  }

  public base64(reader, files) {
    reader.onload = (e: any) => {
      const image = new Image();

      this.showMessage = false;
      image.src = e.target.result;
      const imgBase64Path = e.target.result;
      this.cardImageBase64 = imgBase64Path;
      this.isImageSaved = true;

      let fileF = files[0];

      fileF.fileBase64 = this.cardImageBase64;
      fileF.progress = false;
      fileF.src = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(fileF));
      let separation = fileF.name.split('.');
      fileF.extension = separation.pop();
      fileF.nameFile = separation.toString();

      this.file = fileF;
      this.fileSe = {};
     this.fileDuplicated = false;
      this.fileSe1.forEach(element => {
        if (element.fileBase64 === this.file.fileBase64) {
          this.fileDuplicated = true;
        }
      });
      if (!this.fileDuplicated) {
         this.showLoad = false;
      this.showMultiple = true;
        this.createFormula = true;
        this.fileBrowseHandler(files);
        this.fileSe1.push(this.file)
        this.fileZise.push(this.file.size);
        this.fileSizeTotal = this.fileZise.reduce((a, b) => a + b, 0);
      }else{
        this.showLoad = false;
        this.showMultiple = true;
      }
     
    };
  }

  public handleFileInput(files) {
    this.imageError = null;

    if (files && files[0]) {
      let peso = files[0].size / Number(1000000)

      const reader = new FileReader();

      let sizeTotal;
      let suma = 0;

      this.files.forEach(res => {
        suma += res.sizeTotal
      })

      if (this.orderFormulaNoPbs !== null) {
        this.orderFormulaNoPbs.files.forEach(res => {
          suma += res.size
        })
      }

      sizeTotal = environment.SIZE_TOTAL_FILES - suma

      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

      if (files[0].size > environment.SIZE_FILE || !_.includes(allowed_types, files[0].type)) {
        this.showMessage = true;
        return false;
      } else {
        if (this.fileSizeTotal && this.fileSizeTotal <= sizeTotal) {
          this.base64(reader, files);

        } else if (this.fileSizeTotal >= sizeTotal) {
          this.showSizeTotal = true;

          setTimeout(() => {
            this.showSizeTotal = false;
          }, 20000);
        }
        else {
          this.base64(reader, files);
          this.mb = Math.round(peso + 1) + 'MB';
        }
      }
      reader.readAsDataURL(files[0]);
    }
  }

  public addFile() {
    this.fileSe['files'] = this.fileSe1;

    let numberFormula = localStorage.getItem('numberFormula');
    let numberAuthorization = localStorage.getItem('numberAuthorization');
    this.fileSe['number'] = numberFormula;
    this.fileSe['authorization'] = numberAuthorization;
    this.fileSe['sizeTotal'] = this.fileSizeTotal;
    this.fileSe['check'] = true;

    this.files.push(this.fileSe);
    this.selectedPhotos.push(this.fileSe);

    this.getFormulaA$.next(this.files);
    this.getFormulaAS$.next(this.selectedPhotos);

    this.createFormula = false;
    this.showInput = false;
    this.showAuthorization = false;

    this.createFormulaImage = false;
    this.showLoad = false;
    this.loadingBar = false;

    this.showLoadImage.emit('true');
    this.tagCreate();
  }

  public notImage(index: number) {
    this.fileMultiple.splice(index, 1);

    this.fileZise.splice(index, 1);
    this.fileSe1.splice(index, 1);
    this.fileSizeTotal = this.fileZise.reduce((a, b) => a + b, 0);

    if (this.fileMultiple.length == 0) {
      this.createFormulaImage = false;
      this.deploymentForm.get('deployment').setValue('');
    }
  }

  public activar(mostrar: boolean) {
    this.showInput = true;

    if (mostrar == true) {
      this.showAuthorization = true;
    } else {
      this.showAuthorization = false;
    }
  }

  public close() {
    this.showLoadImage.emit('true');
  }

  public onFileDropped($event) {
    this.showLoad = true;
    this.showMultiple = false;
    this.newFile = $event;
    this.newFile['progress'] = 0;
    //this.formatBytes(this.newFile[0].size) para no duplicar
    this.handleFileInput(this.newFile)
  }

  public fileBrowseHandler(files) {
    this.showLoad = true;
    this.showMultiple = false;
    this.newFile = files;
    this.newFile['progress'] = 0;
    this.uploadFilesSimulator();
    this.formatBytes(this.newFile[0].size)
  }

  public uploadFilesSimulator() {
    this.showLoad = false;
    this.loadingBar = false;
    const timer = setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (this.newFile['progress'] === 100) {
          clearInterval(progressInterval);
          clearTimeout(timer);
          this.createFormulaImage = true;
          this.loadingBar = true;
          this.showMultiple = true;
        } else {
          this.newFile['progress'] += 5;
          this.showLoad = true;
        }
      }, 200);
    }, 1000);
  }

  public formatBytes(bytes, decimals?) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    this.size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    this.newFile['size'] = this.size;
    this.fileMultiple.push(this.newFile);
  }

  public deployment(id) {
    let idValue = 'deployment-' + id;
    const radio = this.deploymentForm.get('deployment').value;

    if (radio !== '' && radio == idValue) {
      this.deploymentForm.get('deployment').setValue('');
    }
  }
  public tagCreate() {
    dataLayer.push({
      'event': 'ga_event_carga',
      'category': 'carga_formula',
      'action': 'Clic-boton',
      'label': 'crear_formula',
      'UES': 'Medicamentos'
    });
  }

  
}
