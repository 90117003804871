import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal/modal.service';

@Component({
  selector: 'app-modal-images',
  templateUrl: './modal-images.component.html',
  styleUrls: ['./modal-images.component.scss']
})
export class ModalImagesComponent implements OnInit {

  @Input() idModal: string;
  @Input() image: string;
  @Input() ext: string;
  @Input() nameFile: string;
  public iframe: boolean;
  public screenWidth: number;

  constructor(
    private _MODAL: ModalService
  ) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    if(this.screenWidth < 768) {
      this.iframe = false
    } else {
      this.iframe = true
    }
  }

  close() {
    setTimeout(() => {
      this._MODAL.closeModal(this.idModal);
    }, 60);
  }

}
