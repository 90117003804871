import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

declare let dataLayer;

@Component({
	selector: 'app-patient',
	templateUrl: './suscriptionRecurrent.component.html',
	styleUrls: ['./suscriptionRecurrent.component.scss']
})
export class SuscriptionRecurrent implements OnInit {

	icon :string= './assets/icons/successful/exito.svg';
	title: string = 'Tu suscripción a los pedidos recurrentes ha sido realizada con éxito';
	subtitle: string  = 'En tu celular registrado recibirás la confirmación de tu solicitud y también continuaras recibiendo el estado de tus pedidos recurrentes';
	validateInfoRecurrentForm: FormGroup;
	progress = 100;
	success: string  = 'true';

	constructor(
		private _router: Router,
		private _route: ActivatedRoute) {
			this.success = this._route.snapshot.paramMap.get('success');

	}

	ngOnInit(): void {
		this.tagUrl();
		this.loadSuccess();

	}

	finishSuscription() {
		this.tagSuscription();
		this._router.navigate([''], {
			relativeTo: this._route
		});
	}
	
	public tagSuscription() {
		dataLayer.push({
			'event': 'ga_event_pabtnterminar',
			'category': 'Pedidos Recurrentes',
			'action': 'Tu suscripción a los pedidos recurrentes ha sido realizada con éxito',
			'label': 'Terminar',
			'UES': 'Medicamentos',
		});
	}
	
	public tagUrl() {
		dataLayer.push({
			'event': 'ga_virtual_Page',
			'titleVp': 'Pagina de agradecimiento',
			'urlVp': '/vp/formulario-recurrente/suscripcion'
		});
	}
	
	public tagCongrats() {
		dataLayer.push({
			'event': 'ga_event_pagagradecimiento',
			'category': 'Pedidos Recurrentes',
			'action': 'Tu suscripción a los pedidos recurrentes ha sido realizada con éxito',
			'label': 'Pagina de agradecimiento',
			'UES': 'Medicamentos',
		});
	}

	public loadSuccess(){
		if(this.success == 'true'){
			this.icon = './assets/icons/successful/exito.svg';
			this.title = 'Tu suscripción a los pedidos recurrentes ha sido realizada con éxito';
			this.subtitle= 'En tu celular registrado recibirás la confirmación de tu solicitud y también continuaras recibiendo el estado de tus pedidos recurrentes';
		}else{
			this.icon = './assets/icons/warning.svg';
			this.title = 'Tu suscripción a los pedidos recurrentes no pudo completarse';
			this.subtitle= 'Por favor intenta de nuevo mas tarde';
		}
	}
}


