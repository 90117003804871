<div class="ctn-image">
  <app-card class="list">
    <div class="list__info">
      <div class="d-flex space-between p-relative">
        <span class="title-card-img">
          Adjuntar una foto
        </span>
  
        <button class="list__info__btn-close" (click)="close()">
          <img src="./assets/icons/x.svg" class="close" alt="close">
        </button>
      </div>
  
      <form [formGroup]="deploymentForm">
        <div class="load">
          <app-card class="list" *ngFor="let f of fileMultiple; let i = index">
            <div class="d-flex ctn-load">
              <img src="./assets/icons/formula/imagen-iconos.svg" alt="adjuntar">
  
              <div class="w-100 p-l">
                <div>
                  <span class="name-file" *ngFor="let file of f">
                    {{file?.name}}
                  </span>
  
                  <div *ngIf="f.progress == 100">
                    <p class="size">
                      {{ f.size }}
                    </p>
                  </div>
                </div>
  
                <ng-container *ngIf="f.progress !== 100">
                  <app-progress [progress]="newFile?.progress"></app-progress>
  
                  <div class="text-center">
                    {{newFile?.progress}}%
                  </div>
                </ng-container>
              </div>
  
              <div class="ctn-deployment" >
                <label for="deployment-{{i}}">
                  <input type="radio" id="deployment-{{i}}" name="deployment" formControlName="deployment" value="deployment-{{i}}" (click)="deployment(i)" class="check p-relative">
                  
                  <img src="./assets/icons/more-horizontal.svg" alt="Opcion">
  
                  <div class="deployment">
                    <div>
                      <button (click)="notImage(i)" class="delete">
                        <img src="./assets/icons/trash-2.svg" alt="Eliminar">
    
                        Eliminar
                      </button>
                    </div>
                </div>
                </label>
              </div>
            </div>
          </app-card>
        </div>
      </form>
  
      <div *ngIf="showLoad == false || showMultiple == true">
        <div class="list__info__container" appDnd (fileDropped)="onFileDropped($event)">
          <input type="file" name="file" #fileDropRef id="fileDropRef" (change)="handleFileInput($event.target.files)">
        
          <div id="zona" class="zone d-flex a-items-center">
            <img src="./assets/icons/formula/adjuntar-imagen.svg" alt="adjuntar">
             <span class="p-l">
              <label class="pointer" for="fileDropRef">
                Arrastra tu imagen aquí o
                adjúntala
              </label>
             </span>
          </div>
        </div>
      </div>
  
      <div *ngIf="showMessage == true" class="c-red text-center">
        No se puede cargar el archivo, por favor revisa el tamaño y/o  tipo de archivo.
      </div>
  
      <div *ngIf="showSizeTotal == true" class="c-red text-center">
        El total de los archivos no puede superar las {{maxSize}}
      </div>

      <div *ngIf="fileDuplicated == true" class="c-red text-center">
        El archivo que intentas subir ya se encuentra adjunto.
      </div>
  
      <div class="text-center">
        JPEG, JPG, PNG Y PDF permitidos
      </div>

      <div class="text-center c-blue">
        Recuerda: debes crear la fórmula para poder continuar.
      </div>

      <div class="text-center">
        <button class="btn-create" [disabled]="createFormulaImage == false" (click)="addFile()" [ngClass]="{'btn-create__disabled': createFormulaImage == false}">
          Crear fórmula
        </button>
      </div>
    </div>
  </app-card>
</div>