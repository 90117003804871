<div id="main" class="page-wrapper l-dashboard">
  <header class="l-header bg-blue-dark">
    <app-header></app-header>
  </header>

  <div class="l-content">
    <section class="sect-main bg-gray-light">
      <router-outlet></router-outlet>
    </section>
  </div>
  
  <footer class="l-footer">
    <app-footer></app-footer>
  </footer>
</div>

<app-modal-exit [idModal]="idModal"></app-modal-exit>
