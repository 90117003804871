import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-behind',
  template: `
              <div class="card">
                Atrás
              </div>
            `,
  styleUrls: ['./btn-behind.component.scss']
})
export class BtnBehindComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
