import { Component, OnInit, Output, EventEmitter, ɵConsole } from '@angular/core';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { environment } from 'src/app/configs/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

declare let dataLayer;

@Component({
  selector: 'app-attached-formula-no-pbs',
  templateUrl: './attached-formula-no-pbs.component.html',
  styleUrls: ['./attached-formula-no-pbs.component.scss']
})
export class AttachedFormulaNoPbsComponent implements OnInit {


  icon = './assets/icons/formula/elige-formula.svg';
  title: string;
  subtitle: string;
  progressComponent = 33.2;
  maxSize = environment.SIZE_FILE_MAX;


  @Output() showLoadImage = new EventEmitter();

  public files = [];
  public file: any;
  public fileSe = new Object();
  public fileSe1 = [];
  public fileMultiple = [];
  public mb: any;
  public objFormulaNoPbs = [];
  public orderFormulaNoPbs: any;

  deploymentForm: FormGroup;

  showInput: boolean = false;
  showAuthorization: boolean;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getFormulaA$ = this._behaviorSubjectService.getAttachedFormula();
  getFormulaAS$ = this._behaviorSubjectService.getAttachedFormulaSelected();
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getEps$ = this._behaviorSubjectService.getEps();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
  getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();


  public vulnerable: PatientVulnerable;
  public eps: Eps;


  public formulas: any;

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  showMessage: boolean = false;
  showSizeTotal: boolean = false;
  weightImages: number;
  fileSize = [];
  fileSizeTotal: any = 0;

  createFormula: boolean = false;
  public progress: number = 0;
  public newFile: any;
  public size: string;
  createFormulaImage: boolean = false;
  showLoad: boolean = false;
  showMultiple: boolean = false;
  loadingBar: boolean = false;
  showDes: boolean = false;
  selectedPhotos = [];

  constructor(
    private _behaviorSubjectService: BehaviorSubjectService,
    private _formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location
  ) {

    /*SUBSCRIBE BEHAVIOR SUBJECT */
    this.vulnerable = this.getPatientVulnerable$.getValue();
    this.eps = this.getEps$.getValue();
    this.formulas = this.getFormulaAS$.getValue();
    this.objFormulaNoPbs = this.getAttachedFormulaNoPbsPreload$.getValue();

    this.orderFormulaNoPbs = this.getAttachedFormulaNoPbs$.getValue()


    this.title = `Hemos identificado que seleccionaste fórmulas para medicamentos que requieren autorización,`;
    this.subtitle = `por favor adjunta las fotos de las fórmulas y autorizaciones de tu requerimiento.`


    this.deploymentForm = this._formBuilder.group({
      deployment: ['']
    });
  }

  ngOnInit(): void {
    this.tagUrl();
    this.files = this.formulas;
    this.showMessage = false;

    if (this.objFormulaNoPbs.length > 0) {
      this.fileMultiple = this.objFormulaNoPbs;
      this.loadingBar = true;
      this.fileSe = this.orderFormulaNoPbs;
      this.fileSe1 = this.orderFormulaNoPbs.files;
      this.createFormulaImage = true;
    }

  }

  public base64(reader, files) {
    reader.onload = (e: any) => {
      const image = new Image();

      this.showMessage = false;
      image.src = e.target.result;
      const imgBase64Path = e.target.result;
      this.cardImageBase64 = imgBase64Path;
      this.isImageSaved = true;

      let fileF = files[0];

      fileF.fileBase64 = this.cardImageBase64;
      fileF.progress = 100;
      fileF.src = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(fileF));
      let separation = fileF.name.split('.');
      fileF.extension = separation.pop();
      fileF.nameFile = separation.toString();

      this.file = fileF;
      this.fileSe = {};
      this.fileSe1.push(this.file)

      this.fileSize.push(this.file.size);
      this.fileSizeTotal = this.fileSize.reduce((a, b) => a + b, 0);
    };
  }

  public handleFileInput(files) {
    this.imageError = null;


    if (files && files[0]) {


      const reader = new FileReader();

      let sizeTotal: number;

      //let suma = 0;
      let suma = this.fileSizeTotal;

      this.files.forEach(res => {
        suma += res.sizeTotal
      });

      if (this.orderFormulaNoPbs !== null) {
        this.orderFormulaNoPbs.files.forEach(res => {
          suma += res.size
        })
      }

      sizeTotal = environment.SIZE_TOTAL_FILES - suma
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

      if (files[0].size > environment.SIZE_FILE || !_.includes(allowed_types, files[0].type)) {
        this.showMessage = true;
        return false;
      } else {


        if (this.fileSizeTotal <= sizeTotal) {
          this.base64(reader, files);
          this.createFormula = true;
          this.fileBrowseHandler(files)
        } else if (this.fileSizeTotal > sizeTotal) {
          this.showSizeTotal = true;

          setTimeout(() => {
            this.showSizeTotal = false;
          }, 20000);
        }
        else {
          this.base64(reader, files);
          this.createFormula = true;
          this.fileBrowseHandler(files)
          this.uploadFilesSimulator();
        }
      }
      reader.readAsDataURL(files[0]);
    }
  }

  public addFile() {

    this.fileSe['files'] = this.fileSe1;
    this.fileSe['number'] = environment.FORMULA_NO_PBS_ID;
    this.fileSe['authorization'] = environment.FORMULA_NO_PBS_ID;
    this.fileSe['sizeTotal'] = this.fileSizeTotal;
    this.fileSe['check'] = true;

    //this.files.push(this.fileSe);
    //this.selectedPhotos.push(this.fileSe);

    this.getAttachedFormulaNoPbs$.next(this.fileSe);
    this.getAttachedFormulaNoPbsPreload$.next(this.fileMultiple);

    this.createFormula = false;
    this.showInput = false;
    this.showAuthorization = false;

    this.createFormulaImage = false;
    this.showLoad = false;
    this.loadingBar = false;
  }

  public notImage(index: number) {
    this.fileMultiple.splice(index, 1);

    this.fileSize.splice(index, 1);
    this.fileSe1.splice(index, 1);
    this.fileSizeTotal = this.fileSize.reduce((a, b) => a + b, 0);


    if (this.fileMultiple.length == 0) {
      this.createFormulaImage = false;
      this.deploymentForm.get('deployment').setValue('');
    } else {
      this.createFormulaImage = true;
    }
  }

  public activar(mostrar: boolean) {
    this.showInput = true;

    if (mostrar == true) {
      this.showAuthorization = true;
    } else {
      this.showAuthorization = false;
    }
  }

  public onFileDropped($event) {
    this.showLoad = true;
    this.showMultiple = false;
    this.newFile = $event;
    this.newFile['progress'] = 0;
    this.formatBytes(this.newFile[0].size)
    this.handleFileInput(this.newFile)
  }

  public fileBrowseHandler(files) {
    this.showLoad = true;
    this.showMultiple = false;
    this.newFile = files;
    this.newFile['progress'] = 0;
    this.uploadFilesSimulator();
    this.formatBytes(this.newFile[0].size)
  }

  public uploadFilesSimulator() {
    this.showLoad = false;
    this.loadingBar = false;
    const timer = setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (this.newFile['progress'] === 100) {
          clearInterval(progressInterval);
          clearTimeout(timer);
          this.createFormulaImage = true;
          this.loadingBar = true;
          this.showMultiple = true;
        } else {
          this.newFile['progress'] += 5;
          this.showLoad = true;
        }
      }, 200);
    }, 1000);
  }

  public formatBytes(bytes, decimals?) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    this.size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    this.newFile['size'] = this.size;
    this.fileMultiple.push(this.newFile);
  }

  public deployment(id) {
    let idValue = 'deployment-' + id;
    const radio = this.deploymentForm.get('deployment').value;

    if (radio !== '' && radio == idValue) {
      this.deploymentForm.get('deployment').setValue('');
    }
  }

  toReturn() {
    this.addFile();
    this._location.back();
  }

  continue() {
    this.addFile();
    this.tagContinue();
    if (localStorage.getItem('editar') == 'verificacion') {
      this._router.navigate(['/verificacion-pedido'], {
        relativeTo: this._route
      });
    } else {
      this._router.navigate(['/localizar-direccion'], {
        relativeTo: this._route
      });
    }
  }

  public tagContinue() {
    dataLayer.push({
      'event': 'ga_event_carga2',
      'category': 'NoPBS',
      'action': 'Clic-boton',
      'label': 'continuar_cargaNoPBS',
      'UES': 'Medicamentos'
    });
  }

  public tagUrl() {
    dataLayer.push({
      'event':'ga_virtual_Page',
      'titleVp':'CargaformulaNoPbs',
      'urlVp':'/vp/cargaformulanopbs'
    });
  }
}
