import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorInterceptor implements ErrorHandler {

    constructor() {}

    handleError(error: Error | HttpErrorResponse): void {
        if (error instanceof HttpErrorResponse) {
            // Error de servidor
            if (!navigator.onLine) {
                // Sin conexion a internet
            }
            /**
             * Todo
             * Error Http
             * Retorna el error
             */
        } else {
            /**
             * Todo
             * Ocurre un error en el cliente
             * Se redirecciona a la pagina de error o retorno el mensaje de error personalizado y lo muestro
             */
        }
    }

}
