import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Patient } from '../../models/patient/patient';
import { environment } from 'src/app/configs/environment';

@Injectable({
  providedIn: 'root'
})

export class PatientService {

  constructor(
    private _HTTP: HttpService
  ) { }

  getTypeDocuments(): Observable<any>{
    const params = {
      tipo: environment.TYPE,
    };

    return this._HTTP.get<any>(`${environment.API_PATIENT}/tipodocumento`, {params} )
    .pipe(
      map(
        response => response
      )
    );
  }


  getTypeDocumentsById(documentoAbrevitura: string, idDocumentoSistema:string, sistema:string ): Observable<any>{
    const params = {
      tipo: environment.TYPE,
      documentoAbrevitura: documentoAbrevitura,
      idDocumentoSistema: idDocumentoSistema,
      sistema: sistema
    };

    return this._HTTP.get<any>(`${environment.API_PATIENT}/tipodocumentoporid`, {params} )
    .pipe(
      map(
        response => response
      )
    );
  }

  getPatient(identificationType: string, identification: string): Observable<any>{
    const params = {
      identificationType: identificationType,
      identification: identification
    };

    return this._HTTP.get<any>(`${environment.API_PATIENT}/consultarPaciente`, {params} )
    .pipe(
      map(
        response => response
      )
    );
  }

  createPatient(patient: Patient): Observable<any>{
    return this._HTTP.post<any>(`${environment.API_PATIENT}/crearPaciente `, patient)
    .pipe(
      map(
        response => response
      )
    );
  }

  updatePatient(patient: Patient): Observable<any>{

    return this._HTTP.put<any>(`${environment.API_PATIENT}/actualizar `, patient)
    .pipe(
      map(
        response => response
      )
    );
  }

  getPatientVulnerable(identificationType: string, identification: string): Observable<any>{
    const params = {
      tipoIdentificacion: identificationType,
      identificacion: identification
    };

    return this._HTTP.get<any>(`${environment.API_VULNERABLE_PATIENT}/paciente`, {params} )
    .pipe(
      map(
        response => response
      )
    );
  }
  
}
