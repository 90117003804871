import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';

import { FormulaService } from 'src/app/core/services/formula/formula.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
//import { IFormula, ITratamiento, Formula, Dispensing, Treatment, Dose, Product} from 'src/app/core/models/electronic-formula/electronic-formula';
import { FormGroup, FormBuilder } from '@angular/forms';
import {Formula } from 'src/app/core/models/electronic-formula/electronic-formula';

@Component({
  selector: 'app-search-formula',
  templateUrl: './search-formula.component.html',
  styleUrls: ['./search-formula.component.scss']
})
export class SearchFormulaComponent implements OnInit {

  @Input() formula: Formula;
  @Input() last: number;
  @Output() sendFormulaSelected = new EventEmitter();
  @Output() sendFormulaDeselected = new EventEmitter();
  @Output() sendFormulaIndex = new EventEmitter();
  viewFormulaDetail: boolean;
  image: string;
  checked: boolean = false;
  formulaForm: FormGroup;
  seeMore: string;
  formulas: any;

    /*VARIABLES BEHAVIOR SUBJECT*/
    getFormulaE$ = this._behaviorSubjectService.getElectronicSearchFormula();

  constructor(
    private _formBuilder: FormBuilder,
    private _CHANGEDECTOR: ChangeDetectorRef,
    private _behaviorSubjectService: BehaviorSubjectService,
  ) {

    this.formulaForm = this._formBuilder.group({
      formu: ['']
    });
  }

  ngOnInit(): void { 
    this._CHANGEDECTOR.markForCheck();

    this.seeMore = `${this.last}fe`;

    

    this.formulas = this.getFormulaE$.getValue();

    if(this.formulas) {
      this.formulas.forEach(e => {
        if(e.number) {
          this.formulaForm.get('formu').setValue(e.number)
        }
      });
    }

    this.showInformation()
  }

  public showInformation(){
    if(this.last === 0){
      this.viewFormulaDetail = true;
    }else{
      this.viewFormulaDetail = false;
    }

  }

  public sendInformation(event) {
    if (event.target.checked){
      this.sendFormulaSelected.emit({formula: this.formula});
    }else{
      this.sendFormulaDeselected.emit({formula: this.formula});
      this.sendFormulaIndex.emit(this.last)
    }
  }

  /*public loadFormulaDeselected(){
    const index: number = this.formulasSea.indexOf(this.formulasSea.find(x => x.number));
    if(index !== -1){
        this.formulasSea.splice(index, 1);
    }  
    
    if(this.formulasSea.length == 0) {
      this.buttonContinueFoE = false;
    }
  }*/

}
