<ng-container *ngIf="errors && errors['required']">
  <span  class="font-12 color-red">
    <ng-content></ng-content>
  </span>
</ng-container>

<ng-container *ngIf="errors && errors['max']">
  <span  class="font-12 color-red">
    El número máximo permitido es {{ errors['max'].max | number }}
  </span>
</ng-container>

<ng-container *ngIf="errors && errors['min']">
  <span  class="font-12 color-red">
    El número minímo permitido es {{ errors['min'].min | number }}
  </span>
</ng-container>

<ng-container *ngIf="errors && errors['pattern']">
  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, numberPattern)">
    <span  class="font-12 color-red">
        Este campo solo admite números que no empiecen por 0
    </span>
  </ng-container>
</ng-container>

<ng-container *ngIf="errors && errors['minlength']">
  <span class="font-12 color-red">
    Mínimo {{ errors['minlength'].requiredLength }} caracteres.
  </span>
</ng-container>

<ng-container *ngIf="errors && errors['maxlength']">
  <span class="font-12 color-red">
    Máximo {{ errors['maxlength'].requiredLength }} caracteres.
  </span>
</ng-container>

<ng-container *ngIf="errors && errors['pattern']">

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, formulaNumber)">
    <span class="font-12 color-red">
      Debe contener al menos 1 número.
    </span>
  </ng-container>
  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, cellPhone)">
    <span class="font-12 color-red">
      Solo debe contener números, empezar por 3 y debe contener 10 dígitos.
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, validateEmail)">
    <span class="font-12 color-red">
      Ingrese un Correo Electrónico válido.
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, validateExt)">
    <span class="font-12 color-red">
      Sólo se permiten archivos de Excel.
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, numberPatternAddBeneficiary)">
    <span class="font-12 color-red">
      Sólo debe contener números.
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, numberLettersPattern)">
    <span class="font-12 color-red">
      Sólo debe contener números y letras.
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, onlyLetters)">
    <span class="font-12 color-red">
      Sólo debe contener letras.
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, onlyOtros)">
    <span class="font-12 color-red">
      Sólo debe contener números y letras.
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, validateAddress)">
    <span class="font-12 color-red">
      Ingrese una Dirección válida. Ej: KR 26Sur 60 25 ó CL 38A Bis Sur 3A 18 Este
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, lettersSpaces)">
    <span class="font-12 color-red">
      Sólo debe contener letras y no puede iniciar ni finalizar con espacios.
    </span>
  </ng-container>

  <ng-container *ngIf="compareRegex(errors['pattern'].requiredPattern, lettersSpacesSpecials)">
    <span class="font-12 color-red">
      Sólo debe contener letras y no puede iniciar ni finalizar con espacios.
    </span>
  </ng-container>

  <ng-container *ngIf="errors && errors['mustMatch']">
    <span class="font-12 color-red">
      Las contraseñas no coinciden
    </span>
  </ng-container>
</ng-container>
