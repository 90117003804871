import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EpsService } from 'src/app/core/services/eps/eps.service';
import { environment } from 'src/app/configs/environment';
import { Location } from '@angular/common';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Eps } from 'src/app/core/models/eps/eps';
import { SubjectRecurrent } from 'src/app/core/models/recurrent/subjectRecurrent';

declare let dataLayer;

@Component({
	selector: 'app-eps',
	templateUrl: './eps.component.html',
	styleUrls: ['./eps.component.scss']
})
export class EpsRecurrentComponent implements OnInit {

	icon = './assets/icons/start/elegir-eps.svg';
	title = '¿A qué EPS perteneces?';
	messageDomicile = environment.MESSAGE_DOMICILE;
	epsRecurrentForm: FormGroup;
	progress = 25;

	public epsObj: Eps;
	public eps: any[];
	public subjectRecurrent: any;
	public resumenRecurrent: any;


	/*VARIABLES BEHAVIOR SUBJECT*/
	getEps$ = this._behaviorSubjectService.getEps();
	getSubjectRecurrent$ = this._behaviorSubjectService.getSubjectRecurrent();
	getResumenRecurrent$ = this._behaviorSubjectService.getResumenRecurrent();

	constructor(
		private _formBuilder: FormBuilder,
		private _epsService: EpsService,
		private _location: Location,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _route: ActivatedRoute,
		private _router: Router

	) {
		this.epsRecurrentForm = this._formBuilder.group({
			eps: ['', [Validators.required]]
		});
		this.subjectRecurrent = this._behaviorSubjectService.getSubjectRecurrent();
		this.resumenRecurrent = this._behaviorSubjectService.getResumenRecurrent();
		
	}

	ngOnInit(): void {
		this.tagUrl();
		this.getEps();
		if (this.getEps$ != undefined) {			
			this.epsRecurrentForm.get('eps').setValue(this.getEps$.value.id);
			this.setEpsObj(this.getEps$.value);
		}
		
		
	}

	public getEps() {
		this._epsService.getEps().subscribe(
			response => {
				this.eps = response.eps;
				if(this.resumenRecurrent.recurringKey != null){
					let idSelectedEps = response.eps.filter(resp => resp.recurringKey == this.resumenRecurrent.recurringKey );
					this.epsRecurrentForm.get('eps').setValue(idSelectedEps[0].id);
					this.setEpsObj(idSelectedEps[0]);
				}
			}
		);
	}

	setEpsObj(obj: any) {
		let eps: Eps = {
			id: obj.id,
			nit: obj.nit,
			nombre: obj.name,
			EntregaFarmacia: '',
			idTurnoDigital: '',
			idServicio: '',
			webMostrar: '',
			mostrarWeb: '',
			aplicaFormulaElectronica: obj.applyElectronicFormula,
			tipo: ''
		};
		this.epsObj = eps;
	}



	nextSubject() {
		this.tagEps(this.subjectRecurrent.identificacion);
		this.getEps$.next(this.epsObj);
		this.subjectRecurrent.idEps = this.epsObj.id;
		this.resumenRecurrent.nombreEps = this.epsObj.nombre;
		this.resumenRecurrent.idEps = this.epsObj.id;
		this.redirect();
	}

	redirect() {		
		this._router.navigate(['/formulario-recurrente/location'], {
			relativeTo: this._route
		});
		this.getSubjectRecurrent$.next(this.subjectRecurrent);
		this.getResumenRecurrent$.next(this.resumenRecurrent);
		this.epsRecurrentForm.clearAsyncValidators();
	}
	toReturn() {
		this._location.back();
	}

	public tagEps(documentNumber: string) {
		dataLayer.push({
			'event': 'ga_event_seleccioneps',
			'category': 'Pedidos Recurrentes',
			'action': '¿A cuál EPS perteneces?',
			'label': 'Continuar',
			'UES': 'Medicamentos',
			'identificacion': documentNumber
		});
	}
	
	public tagUrl() {
		dataLayer.push({
			'event': 'ga_virtual_Page',
			'titleVp': 'Seleccion EPS',
			'urlVp': '/vp/formulario-recurrente/eps'
		});
	}

}
