import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/app/configs/environment';
import { AddressService } from 'src/app/core/services/address/address.service';
import { Department } from 'src/app/core/models/department/department';
import { City } from 'src/app/core/models/city/city';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { Eps } from 'src/app/core/models/eps/eps';
import { Address } from 'src/app/core/models/address/address';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OrderConfigurationService } from 'src/app/core/services/order-configuration/order-configuration.service';

declare let dataLayer;

@Component({
	selector: 'app-find-address',
	templateUrl: './find-address.component.html',
	styleUrls: ['./find-address.component.scss']
})
export class FindAddressRecurrentComponent implements OnInit {
	icon = './assets/icons/arrow-maps.svg';
	title = 'Localicemos tu dirección de residencia';
	subtitle = 'Elige el departamento y la ciudad.';
	progress = 49.8;
	
	findAddressRecurrentForm: FormGroup;
	idDe: string;
	order: any;
	orderA: any;
	departmentName: any;
	cityName: any;
	enabledContinue: boolean = false;
	newAddress: boolean;

	/*VARIABLES BEHAVIOR SUBJECT*/
	getEps$ = this._behaviorSubjectService.getEps();
	getAddresses$ = this._behaviorSubjectService.getAddresses();
	getSelectAddress$ = this._behaviorSubjectService.getSelectAddress();
	getAddress$ = this._behaviorSubjectService.getAddress();
	getResumenRecurrent$ = this._behaviorSubjectService.getResumenRecurrent();
	getSubjectRecurrent$ = this._behaviorSubjectService.getSubjectRecurrent();

	public eps: Eps;
	public subjectRecurrent: any;
	public resumenRecurrent: any;

	/*SERVICES */
	departments: Department[] = [];
	cities: City[] = [];
	address: Address[] = [];

	constructor(
		private _formBuilder: FormBuilder,
		private _addressService: AddressService,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _changeDetector: ChangeDetectorRef,
		private _location: Location,
		private _orderConfigurationService: OrderConfigurationService
	) {
		this.findAddressRecurrentForm = this._formBuilder.group({
			department: ['', [Validators.required]],
			city: ['', [Validators.required]]
		});

		/*SUBSCRIBE BEHAVIOR SUBJECT */
		this.eps = this.getEps$.getValue();

		this._changeDetector.markForCheck();

		this.subjectRecurrent = this.getSubjectRecurrent$;
		this.resumenRecurrent = this.getResumenRecurrent$;
	}

	ngOnInit(): void {
		this.tagUrl();
		if (this.eps !== null) {
			this.getDepartamentsEps();
		}

		this.order = this.getAddresses$.getValue();

		if (this.order !== null) {
			this.setValue();

			this.getCitiesDepartaments()
			this.getAddress();
		}
	}

	setValue() {
		this.findAddressRecurrentForm.setValue({
			department: this.order.idDepartment,
			city: this.order.idCity
		});
	}

	public getDepartamentsEps() {
		this._addressService.getDepartamentsEps(environment.TYPE, this.eps.id).subscribe(
			response => {
				this.departments = response.departamentos;
			}
		);
	}

	public getCitiesDepartaments() {
		this.enabledContinue = false
		if (this.order == null) {
			this.findAddressRecurrentForm.get('city').setValue('');
		}

		this.idDe = this.findAddressRecurrentForm.get('department').value;
		this._addressService.getCitiesDepartaments(environment.TYPE, this.idDe, this.eps.id).subscribe(
			response => {
				this.cities = response.ciudades;
			}
		);
	}

	public getAddress() {
		this.enabledContinue = false
		const id = this.findAddressRecurrentForm.get('city').value;

		this.subjectRecurrent.idCiudad = id;
		this.resumenRecurrent.idCiudad = id;

		this._addressService.getAddress(this.subjectRecurrent.tipoIdentificacion, this.subjectRecurrent.identificacion, environment.TYPE, id)
			.subscribe(
				response => {
					this.address = response.direcciones;
					this.enabledContinue = true
				}
			)

	}

	getFormData() {
		this.cities.every(res => {
			if (res.idCiudad == this.findAddressRecurrentForm.get('city').value) {
				this.cityName = res.nombreCiudad
				this.departmentName = res.nombreDepartamento
				return false
			}

			return true
		})

		if (this.address == null) {
			this.newAddress = true;
		}

		this.order = {
			city: this.cityName,
			idDepartment: this.findAddressRecurrentForm.get('department').value,
			department: this.departmentName,
			idCity: this.findAddressRecurrentForm.get('city').value,
			channel: environment.CHANNEL,
			available: '1',
			addresses: this.address,
			newAddress: this.newAddress,
		}

		this.orderA = {
			idCity: this.findAddressRecurrentForm.get('city').value,
		}
	}

	validateStatusOrderTimeZoneConfiguration(idCity: string, channel: string) {
		this._orderConfigurationService.validateStatusOrderTimeZoneConfiguration(idCity, channel).subscribe(
			response => {
				if (response.configuracion != null && response.configuracion.estado == 'ACTIVO') {
					localStorage.setItem('enableSchedule', 'true');
				} else {
					localStorage.setItem('enableSchedule', 'false');
				}
				this.getSubjectRecurrent$.next(this.subjectRecurrent);
				this.getResumenRecurrent$.next(this.resumenRecurrent);
				if (this.address == null) {
					localStorage.setItem('updateA', 'false');
					this._router.navigate(['/formulario-recurrente/direccion'], {
						relativeTo: this._route
					});
					this.getAddress$.next(null);
					this.getSelectAddress$.next(null);
				} else {

					this._router.navigate(['/formulario-recurrente/seleccionar-direccion'], {
						relativeTo: this._route
					});
				}
			}
		);
	}


	toReturn() {
		this._location.back();
	}
	nextSubject() {
		this.tagContinue(this.subjectRecurrent.identificacion);
		this.getFormData();

		this.getAddresses$.next(this.order);
		this.validateStatusOrderTimeZoneConfiguration(this.order.idCity, environment.CHANNEL);


	}

	public tagUrl() {
		dataLayer.push({
			'event': 'ga_virtual_Page',
			'titleVp': 'Localizacion',
			'urlVp': '/vp/formulario-recurrente/location'
		});
	}

	public tagContinue(documentNumber: string) {
		dataLayer.push(
			{
				'event' : 'ga_event_dirlocalizacion',
				'category': 'Pedidos Recurrentes',
				'action': 'Elige el departamento y la ciudad',
				'label': 'Continuar',
				'UES': 'Medicamentos',
				'identificacion': documentNumber
			});
	}
}
