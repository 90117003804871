<div class="ctn-input">
  <app-card class="list">
    <form [formGroup]="formulaForm">
      <label for="{{formula.number}}">

        <input type="checkbox" id="{{formula.number}}" name="{{formula.number}}" value="{{formula.number}}" formControlName="formu" (change)="sendInformation($event)" checked>

        <div>
          <input type="checkbox" id="{{seeMore}}" class="see-more">

          <span class="d-flex space-between p-20 info-formula">
            <div class="ctn-formula">
              <div class="n-formula">
                Fórmula número {{formula.number}}
              </div>
        
              <div class="ctn-treatment">
                <ul>
                  <li *ngFor="let itemsF of formula.treatment" class="treatment">
                    {{itemsF.product.description}}
                  </li>
                </ul>
              </div>
            </div>

            <span class="check c-blue">
              <img src="./assets/icons/check.svg" alt="check">
            </span>
          </span>

          <label class="tab-label" for="{{seeMore}}">
            Ver más
          </label>
        </div>
      </label>
    </form>
  </app-card>
</div>
