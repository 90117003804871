import { Injectable } from '@angular/core';
import MicroModal from 'micromodal';  // es6 module

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }

  openModal(idModal: string) {
    MicroModal.init({
      disableScroll: true,
    });
    MicroModal.show(idModal);
  }

  closeModal(idModal: string) {
    MicroModal.init({
        awaitCloseAnimation: true,
      });
    MicroModal.close(idModal);
  }
}
