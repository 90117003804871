import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { environment } from 'src/app/configs/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private _HTTP: HttpService
  ) {}

  public getDepartamentsEps(type: string, idEps: string) {
    return this._HTTP.get<any>(`${environment.API_LOCATION_APP}/ubicacion/consultarDepartamentoPorEps?type=${ type }&idEps=${ idEps }`)
    .pipe(
      map(
        response => response
      )
    );
  }

  public getCitiesDepartaments(type: string, idDepartment: any, idEps: string) {
    return this._HTTP.get<any>(`${environment.API_LOCATION_APP}/ubicacion/ciudades?tipo=${type}&idDepartamento=${idDepartment}&idEps=${idEps}`)
    .pipe(
      map(
        response => response
      )
    );
  }

  public getAbreviatura() {
    return this._HTTP.get<any>(`${environment.API_ADDRESS}/abreviatura`)
    .pipe(
      map(
        response => response
      )
    );
  }

  public getAddress(dentificationType: string, identificacion: string, type: string, idCity: string) {

    const params = {
      tipoIdentificacion: dentificationType,
      identificacion: identificacion,
      tipo: type,
      idCiudad: idCity
    }

    return this._HTTP.get<any>(`${environment.API_ADDRESS}/paciente/direcciones`, {params})
    .pipe(
      map(
        response => response
      )
    );
  }

  public createAddress(data) {
    return this._HTTP.post<any>(`${environment.API_ADDRESS}/paciente`, data)
    .pipe(
      map(
        response => response
      )
    );
  }

  public updateAddress(data) {
    return this._HTTP.put<any>(`${environment.API_ADDRESS}/paciente`, data)
    .pipe(
      map(
        response => response
      )
    );
  }

  public getCoverage(idCity: string, address: any, channel: string) {
    return this._HTTP.get<any>(`${environment.API_PATIENT}/consultarCobertura?ciudad=${idCity}&direccion=${address}&canal=${channel}`)
    .pipe(
      map(
        response => response
      )
    );
  }

  public getAddressById(idAddress: string) {
    return this._HTTP.get<any>(`${environment.API_ADDRESS}/paciente/direcciones/id?idDireccion=${idAddress}`)
    .pipe(
      map(
        response => response
      )
    );
  }
}
