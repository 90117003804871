<app-page-progress [progress]="progress"></app-page-progress>

<div>
	<form [formGroup]="validateInfoRecurrentForm">
		<div class="ctn-fields">
			<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>
		</div>
		<div class="ctn-fields">
			<div class="buttons">
				<div></div>
				<div></div>
				<div></div>
				<button class="btn-end d-flex j-center" (click)="finishSuscription()">
					Terminar
					<div class="ctn-spinner">
						<div class="spinner"></div>
					</div>

				</button>
			</div>
		</div>
	</form>
</div>