<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div>
  <form [formGroup]="addressForm">
    <div class="ctn-fields">
      <div class="d-flex f-wrap space-between p-v-10">
        <div class="ctn-data dir">
          <div class="select">
            <label for="department" class="t-doc">
              Departamento

              <select
                id="department"
                name="department"
                formControlName="department"
                (change)="getCitiesDepartaments()"
              >
                <option value="" disabled selected>Seleccione...</option>
                <option *ngFor="let depa of departments" [value]="depa.id">
                  {{ depa.name }}
                </option>
              </select>
            </label>

            <div class="text-invalid">
              <app-form-errors
                *ngIf="addressForm.get('department').touched"
                [errors]="addressForm.get('department').errors"
              >
              </app-form-errors>
            </div>
          </div>
        </div>
        <div class="ctn-data dir">
          <div class="select">
            <label for="city" class="t-doc">
              Ciudad

              <select
                id="city"
                name="city"
                formControlName="city"
                (change)="
                  validateStatusOrderTimeZoneConfiguration()
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option *ngFor="let city of cities" [value]="city.idCiudad">
                  {{ city.nombreCiudad }}
                </option>
              </select>
            </label>

            <div class="text-invalid">
              <app-form-errors
                *ngIf="addressForm.get('city').touched"
                [errors]="addressForm.get('city').errors"
              >
              </app-form-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex f-wrap space-between p-v-10">
        <div class="d-flex a-items-center dir">
          <div class="select w-50">
            <label for="addressNomenclature" class="t-doc">
              Dirección

              <select
                id="addressNomenclature"
                name="addressNomenclature"
                formControlName="addressNomenclature"
              >
                <option
                  *ngFor="let abr of abreviatura"
                  [value]="abr.abreviacion"
                >
                  {{ abr.nomenclatura }}
                </option>
              </select>
            </label>

            <div class="text-invalid">
              <app-form-errors
                *ngIf="addressForm.get('addressNomenclature').touched"
                [errors]="addressForm.get('addressNomenclature').errors"
              >
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>

          <div class="ctn-data">
            <label for="numberNomenclature">
              <input
                class="i-data"
                type="text"
                name="numberNomenclature"
                id="numberNomenclature"
                formControlName="numberNomenclature"
              />
            </label>

            <div class="text-invalid">
              <app-form-errors
                *ngIf="addressForm.get('numberNomenclature').touched"
                [errors]="addressForm.get('numberNomenclature').errors"
              >
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>
        </div>

        <div class="d-flex a-items-center dir">
          <span> # </span>

          <div class="ctn-data ctn-data-one">
            <label for="numberOne">
              <input
                class="i-data"
                type="text"
                name="numberOne"
                id="numberOne"
                formControlName="numberOne"
              />
            </label>

            <div class="text-invalid">
              <app-form-errors
                *ngIf="addressForm.get('numberOne').touched"
                [errors]="addressForm.get('numberOne').errors"
              >
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>

          <span> - </span>

          <div class="ctn-data ctn-data-two">
            <label for="numberTwo">
              <input
                class="i-data"
                type="text"
                name="numberTwo"
                id="numberTwo"
                formControlName="numberTwo"
              />
            </label>

            <div class="text-invalid">
              <app-form-errors
                *ngIf="addressForm.get('numberTwo').touched"
                [errors]="addressForm.get('numberTwo').errors"
              >
                Campo obligatorio
              </app-form-errors>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex f-wrap space-between p-v-10">
        <div class="ctn-data dir">
          <label for="name">
            Dale un nombre a tu dirección
            <input
              class="i-data"
              type="text"
              name="name"
              id="name"
              formControlName="name"
              maxlength="190"
            />
          </label>

          <div class="text-invalid">
            <app-form-errors
              *ngIf="addressForm.get('name').touched"
              [errors]="addressForm.get('name').errors"
            >
              Campo obligatorio
            </app-form-errors>
          </div>
        </div>

        <div class="ctn-data dir">
          <label for="district">
            Barrio

            <input
              class="i-data"
              type="text"
              name="district"
              id="district"
              formControlName="district"
            />
          </label>
        </div>
      </div>

      <div class="d-flex">
        <div class="ctn-data in-dir">
          <label for="comments">
            Información adicional

            <textarea
              class="i-data"
              type="text"
              name="comments"
              id="comments"
              formControlName="comments"
              placeholder="Ingresa indicaciones especiales como apartamento, bloque, interior, conjunto, edificio, puntos de referencia y otros datos que consideres necesarios"
              cols="30"
              rows="6"
              maxlength="490"
            ></textarea>
          </label>
        </div>
      </div>

      <div class="d-flex space-between buttons">
        <button (click)="toReturn()" class="btn-return">
          <img src="./assets/icons/navegacion/atras.svg" alt="icon" />
        </button>

        <button
          type="button"
          (click)="updateAddress()"
          *ngIf="addressForm.valid && !activate && !enableSchedule && enableButton"
          class="btn-save"
        >
          Guardar
        </button>

        <button
          type="button"
          *ngIf="addressForm.valid && !activate && enableSchedule && enableButton"
          class="btn-save"
          (click)="fillAddressRequest()"
        >
          Seleccionar Franja
        </button>
      </div>
    </div>
  </form>
</div>
<app-modal-generic
  [idModal]="idModal"
  [title]="ModalTitle"
  [description]="ModalDescription"
>
  <button class="w-50 btn btn2" (click)="closeModal()">Aceptar</button>
</app-modal-generic>
