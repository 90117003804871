import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { HomeComponent } from './components/home/home.component';
import { PatientComponent } from './components/patient/patient.component';
import { FormulaComponent } from './components/formula/formula.component';
import { AddressComponent } from './components/address/address.component';
import { ContactComponent } from './components/contact/contact.component';
import { VerificationComponent } from './components/verification/verification.component';
import { SuccessfulComponent } from './components/successful/successful.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { SelectAddressComponent } from './components/select-address/select-address.component';
import { HabeasDataComponent } from './components/habeas-data/habeas-data.component';
import { FailedComponent } from './components/failed/failed.component';
import { SuraRedirectComponent } from './components/sura-redirect/sura-redirect.component';
import { FindAddressComponent } from './components/find-address/find-address.component';
import { AttachedFormulaNoPbsComponent } from './components/attached-formula-no-pbs/attached-formula-no-pbs.component';
import { LoggedCIAMInGuard } from './core/guards/logged-in/loggedCIAM-in.guard';
import { NoLoggedGuard } from './core/guards/no-logged/no-logged.guard';
import { RoutingPath } from './configs/routing-path';
import { NoAuthorizedComponent } from './components/no-authorized/no-authorized.component';
import { EpsComponent } from './components/eps/eps.component';
import { OrderManagerComponent } from './components/order-manager/order-manager.component';
import { AddressManagerComponent } from './components/address-manager/address-manager.component';
import { ScheduleDeliveryComponent } from './components/schedule-delivery/schedule-delivery.component';
import { PatientRecurrentComponent } from './components/recurrent/patient/patient.component';
import { EpsRecurrentComponent } from './components/recurrent/eps/eps.component';
import { FindAddressRecurrentComponent } from './components/recurrent/find-address/find-address.component';
import { SelectAddressRecurrentComponent } from './components/recurrent/select-address/select-address.component';
import { AddressRecurrentComponent } from './components/recurrent/address/address.component';
import { ValidateInfoRecurrent } from './components/recurrent/validateInfoRecurrent/validateInfoRecurrent.componet';
import { SuscriptionRecurrent } from './components/recurrent/suscription/suscriptionRecurrent.component';
import { ContactOrderManagerComponent } from './components/contactOrder-manager/contactOrder-manager.component';


const routes: Routes = [
	{
		path: '',
		redirectTo: 'inicio',
		pathMatch: 'prefix',
	},
	{
		path: RoutingPath.appRouting.pages.token.path,
		canActivate: [NoLoggedGuard],
		component: NoAuthorizedComponent
	},
  {
		path: 'null',
		component: HomeComponent
	},
	{
		path: 'logout',
		component: HomeComponent
	},
  {
		path: 'inicio',
		component: HomeComponent
	},
	{
		path: 'magicLink',
		component: HomeComponent
	},
	{
		path: '',
		component: MainLayoutComponent,
		data: {
			breadcrumb: null
		},
		children: [
			{
				path: 'datos-paciente',
				component: PatientComponent
			},
			{
				path: 'formula',
				component: FormulaComponent
			},
			{
				path: 'formula-no-pbs',
				component: AttachedFormulaNoPbsComponent
			},
			{
				path: 'localizar-direccion',
				component: FindAddressComponent
			},
			{
				path: 'seleccionar-direccion',
				component: SelectAddressComponent
			},
			{
				path: 'direccion',
				component: AddressComponent
			},
			{
				path: 'gestionar-direccion',
				component: AddressManagerComponent
			},
			{
				path: 'contacto',
				component: ContactComponent
			},
			{
				path: 'verificacion-pedido',
				component: VerificationComponent
			},
			{
				path: 'pedido-exitoso',
				component: SuccessfulComponent
			},
			{
				path: 'pedido-fallido',
				component: FailedComponent
			},
			{
				path: 'redireccion-sura',
				component: SuraRedirectComponent
			},
			{
				path: 'eps',
				component: EpsComponent
			},
			{
				path: 'programar-entrega',
				component: ScheduleDeliveryComponent
			},
			{
				path: 'formulario-recurrente/paciente/:token',
				component: PatientRecurrentComponent
			},
			{
				path: 'formulario-recurrente/eps',
				component: EpsRecurrentComponent
			},
			{
				path: 'formulario-recurrente/location',
				component: FindAddressRecurrentComponent
			},
			{
				path: 'formulario-recurrente/seleccionar-direccion',
				component: SelectAddressRecurrentComponent
			},
			{
				path: 'formulario-recurrente/direccion',
				component: AddressRecurrentComponent
			},
			{
				path: 'formulario-recurrente/validar',
				component: ValidateInfoRecurrent
			},
			{
				path: 'formulario-recurrente/suscripcion/:success',
				component: SuscriptionRecurrent
			}, {
				path: 'gestionar-pedido',
				component: OrderManagerComponent,
				canActivate: [LoggedCIAMInGuard],

			},
			{
				path: 'gestionar-pedido/contacto',
				component: ContactOrderManagerComponent,
				canActivate: [LoggedCIAMInGuard],
			}
		]
	},
	{
		path: 'inicio',
		component: HomeComponent,
		pathMatch: 'prefix'
	},
	{
		path: 'terminos-y-condiciones',
		component: TermsConditionsComponent
	},
	{
		path: 'habeas-data',
		component: HabeasDataComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
