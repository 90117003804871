export class slider {
  public static imagesDesktop = [
    {
      path: './assets/images/carousel/banners-frases-landing_bienvenidos2_1200x375.jpg',
      id: '1',
      name: 'Imagen 1'
    },
    {
      path: './assets/images/carousel/banners-frases-landing-aqui-podras_1200x375.jpg',
      id: '1',
      name: 'Imagen 1'
    },
    {
      path: './assets/images/carousel/banner-lo-mas-importante_1200x375.jpg',
      id: '2',
      name: 'Imagen 2'
    },
    {
      path: './assets/images/carousel/banner-cuidar-tu-salud_1200x375.jpg',
      id: '1',
      name: 'Imagen 1'
    },
    {
      path: './assets/images/carousel/banner-mas-cerca-de-ti_1200x375.jpg',
      id: '1',
      name: 'Imagen 1'
    },
    {
      path: './assets/images/carousel/banner-facil-y-seguro_1200x375.jpg',
      id: '1',
      name: 'Imagen 1'
    }
  ]

  public static imagesMobile = [
    {
      path: './assets/images/carousel/banners-frases-landing_bienvenidos2_320x360.jpg',
      id: '1',
      name: 'Imagen 1'
    },
    {
      path: './assets/images/carousel/banners-frases-landing-aqui-podras_320x360.jpg',
      id: '1',
      name: 'Imagen 1'
    },
    {
      path: './assets/images/carousel/banner-lo-mas-importante_320x365.jpg',
      id: '2',
      name: 'Imagen 2'
    },
    {
      path: './assets/images/carousel/banner-cuidar-tu-salud_320x360.jpg',
      id: '1',
      name: 'Imagen 1'
    },
    {
      path: './assets/images/carousel/banner-mas-cerca-de-ti_320x360.jpg',
      id: '1',
      name: 'Imagen 1'
    },
    {
      path: './assets/images/carousel/banner-facil-y-seguro_320X360.jpg',
      id: '1',
      name: 'Imagen 1'
    }
  ]
}
