import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { of } from 'rxjs';
import { environment } from 'src/app/configs/environment';
import { map, catchError } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _HTTP: HttpService,
    private _STORAGE: LocalStorageService
  ) { }

  authJwt() {
    const encode = btoa(environment.USER_PASS)
    const headers = new HttpHeaders({ 'Auth': encode });
    return this._HTTP.post(`${environment.AUTH_ENDPOINT}/login`, '', { headers: headers, observe: 'response' }).pipe(
      map((response) => {
        const token = response.headers.get('Authorization');
        if (token) {
          this._STORAGE.setItem(environment.AUTHORIZATION, token.replace('Bearer ', ''));
          return true;
        }
        else {
          return false;
        }
      }), catchError(err => {
        return of(false);
      })
    );
  }

  getJwtToken(): string {
    return this._STORAGE.getItemString(environment.AUTHORIZATION);
  }

  async logout(token: string) {
    let params ={
      tokenId:token
  };
  //  const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded','iPlanetDirectoryPro': token });
    this._HTTP.delete(`${environment.API_USER_IDENTITY}`,{params}).subscribe(response => {
      this._STORAGE.clear();
      location.href = '';
    }, err => {
      this._STORAGE.clear();
      location.href = '';
    });
  }

  async logoutWhitOutRedirect(token: string) {
    let params ={
        tokenId:token
    };
 //   const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded','iPlanetDirectoryPro': token });
    this._HTTP.delete(`${environment.API_USER_IDENTITY}`, {params}).subscribe(response => {
      this._STORAGE.clear();
    }, err => {
      this._STORAGE.clear();
    });
  }
}
