<app-page-progress [progress]="progress"></app-page-progress>

<app-title [icon]="icon" [title]="title" [subtitle]="subtitle"></app-title>

<div class="ctn-pedido">



  <div class="group-info" *ngIf="!enableTimeZone">
    <div>
      <img src="./assets/icons/successful/48-horas.svg" alt="hora" />
    </div>

    <span class="f-300">
      Recuerda que tu pedido será entregado en máximo
    </span>

    <span> 48 horas. </span>
  </div>

  <div class="group-info" *ngIf="enableTimeZone">
    <div>
      <img src="./assets/icons/successful/48-horas.svg" alt="hora" />
    </div>

    <span class="f-300">
      Recuerda que tu pedido será entregado el día {{scheduleDelivery.schedule | date: "dd 'de' MMMM 'de' yyyy"}},
    </span>

    <span class="f-300" *ngIf="scheduleDelivery.franja != null"> en la franja {{scheduleDelivery.franja}} </span>
    <span class="f-300" *ngIf="scheduleDelivery.franja == null"> en la franja seleccionada </span>
  </div>

  <div class="group-info">
    <div>
      <img
        src="./assets/icons/successful/recuerda-tener-a-la-mano.svg"
        alt="formula"
      />
    </div>
    <div>
      <span> Ten a la mano tu fórmula médica vigente </span>

      <span class="f-300"> para recibir los medicamentos. </span>
    </div>
    <br />
    <span class="f-300">
      {{ messageDomicile }}
    </span>
  </div>

  <div class="group-info">
    <div>
      <img src="./assets/icons/successful/a-traves-de-tu.svg" alt="telefono" />
    </div>

    <span class="f-300">
      Recibirás información del estado de tu pedido a tu celular.
    </span>
  </div>

  <div class="ctn-button">
    <button class="btn-end" (click)="nextSubject()">Finalizar</button>
  </div>
</div>
