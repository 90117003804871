import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { environment } from 'src/app/configs/environment';
import { IScheduleDelivery } from 'src/app/core/models/schedule-delivery/schedule-delivery';


declare let dataLayer;

@Component({
  selector: 'app-successful',
  templateUrl: './successful.component.html',
  styleUrls: ['./successful.component.scss']
})
export class SuccessfulComponent implements OnInit {

  title = 'Tu pedido ha sido creado';
  subtitle: string;
  icon = './assets/icons/successful/exito.svg';
  nPedido: string;
  progress = 100;
  messageDomicile = environment.REMEMBER_MESSAGE_DOMICILE;
  enableTimeZone: boolean = false;
  public addresses: any;
  public scheduleDelivery: IScheduleDelivery;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatientVulnerable$ = this._behaviorSubjectService.getPatientVulnerable();
  getPatient$ = this._behaviorSubjectService.getPatient();
  getEps = this._behaviorSubjectService.getEps();
  getElectronicFormula = this._behaviorSubjectService.getElectronicFormula();
  getElectronicFormulaS = this._behaviorSubjectService.getElectronicSearchFormula();
  getAttachedFormula = this._behaviorSubjectService.getAttachedFormula();
  getAttachedFormulaSelected = this._behaviorSubjectService.getAttachedFormulaSelected();
  getSelectAddress = this._behaviorSubjectService.getSelectAddress();
  getAddress = this._behaviorSubjectService.getAddress();
  getContact = this._behaviorSubjectService.getContact();
  getAttachedFormulaNoPbs$ = this._behaviorSubjectService.getAttachedFormulaNoPbs();
  getAttachedFormulaNoPbsPreload$ = this._behaviorSubjectService.getAttachedFormulaNoPbsPreload();
  getScheduleDelivery$ = this._behaviorSubjectService.getScheduleDelivery();
  getAddresses$ = this._behaviorSubjectService.getAddresses();
  

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _behaviorSubjectService: BehaviorSubjectService,
  ) {
    
    this.scheduleDelivery = this.getScheduleDelivery$.getValue();
    this.nPedido = localStorage.getItem('pedidoLanding');
    this.subtitle =  `Número de solicitud #${this.nPedido}.`;
    this.addresses = this.getAddresses$.getValue();
    this.getEnableTimeZone();
  }

  ngOnInit(): void {
    this.tagUrl();
  }

  nextSubject() {
    this.getPatientVulnerable$.next(null);
    this.getPatient$.next(null);
    this.getEps.next(null);
    this.getElectronicFormula.next([]);
    this.getElectronicFormulaS.next([]);
    this.getAttachedFormula.next([]);
    this.getAttachedFormulaSelected.next([]);
    this.getSelectAddress.next(null);
    this.getAddress.next(null);
    this.getContact.next(null);
    this.getAttachedFormulaNoPbs$.next(null);
    this.getAttachedFormulaNoPbsPreload$.next([]);
    this.getScheduleDelivery$.next(null);

    this._router.navigate(['/inicio'], {
      relativeTo: this._route
    });
  }

  getEnableTimeZone(){
    if(localStorage.getItem('enableSchedule') == 'true'){
      this.enableTimeZone = true;
    }else{
      this.enableTimeZone = false;
    }
  }

  public tagUrl() {
    dataLayer.push({
      'event':'ga_virtual_Page',
      'titleVp':'Solicitudrecibida',
      'urlVp':'/vp/solicitudrecibida'
    });
  }
}
