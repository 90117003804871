import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Department } from 'src/app/core/models/department/department';
import { City } from 'src/app/core/models/city/city';
import { Router, ActivatedRoute } from '@angular/router';
import { AddressService } from 'src/app/core/services/address/address.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { Location } from '@angular/common';
import { environment } from 'src/app/configs/environment';
import { PatientVulnerable } from 'src/app/core/models/patient-vulnerable/patient-vulnerable';
import { Eps } from 'src/app/core/models/eps/eps';
import { Address } from 'src/app/core/models/address/address';

declare let dataLayer;

@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.component.html',
  styleUrls: ['./select-address.component.scss']
})
export class SelectAddressComponent implements OnInit {

  icon = './assets/icons/arrow-maps.svg';
  title = '¿A qué dirección llevamos los medicamentos?';
  subtitle = 'Crea una dirección o selecciona una ya creada.';

  progress = 49.8;
  addressForm: FormGroup;
  newAddress: boolean;
  updaAddress: Address;
  update: boolean;
  public idAddress: any;
  departmentName: any;
  cityName: any;
  iconEdit: boolean = false;
  idDe: string;
  addressCoverage: string;
  showDes: boolean = false;
  enableTimeZone: boolean = false;

  adresses: any;

  /*VARIABLES BEHAVIOR SUBJECT*/
  getPatient$ = this._behaviorSubjectService.getPatientVulnerable();
  getEps$ = this._behaviorSubjectService.getEps();
  getAddresses$ = this._behaviorSubjectService.getAddresses();
  getSelectAddress$ = this._behaviorSubjectService.getSelectAddress();
  getAddress$ = this._behaviorSubjectService.getAddress();
  order: any;
  orderA: any;

  public vulnerable: PatientVulnerable;
  public eps: Eps;

  address: Address[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _addressService: AddressService,
    private _behaviorSubjectService: BehaviorSubjectService,
    private _CHANGEDECTOR: ChangeDetectorRef,
    //private _patientService: PatientService,
    private _location: Location,
  ) {
    this.addressForm = this._formBuilder.group({
      address: ['', [Validators.required]]
    });

    /*SUBSCRIBE BEHAVIOR SUBJECT */
    this.vulnerable = this.getPatient$.getValue();
    this.eps = this.getEps$.getValue();
    this.order = this.getSelectAddress$.getValue();
    this.adresses = this.getAddresses$.getValue();
    this._CHANGEDECTOR.markForCheck();
    this.getEnableTimeZone();
  }

  ngOnInit(): void {
    this.tagUrl();

    this.getAddress$.next(null)

   

    this.address = this.adresses.addresses;

    if(this.order !== null) {
      this.idAddress = this.order.idAddress;

      this.setValue();

      this.addressCoverage = this.order.addressDir;
    }
  }

  setValue() {
    if(this.order.address == 'nueva') {
      this.order.address = ''
    }

    this.addressForm.setValue({
      address: this.order.address,
    });

    this.iconEdit = true;
  }

  selectAddress(addres?:string) {
    localStorage.setItem('direccion', addres);
    if(this.addressForm.get('address').value != 'nueva') {
      this.newAddress = false;
      this.update = true;
      this.iconEdit = true;
      this.address.forEach(e => {
        if(e.idDireccion == this.addressForm.get('address').value) {
          this.updaAddress = e;
          this.idAddress = this.updaAddress.idDireccion;
          this.addressCoverage = this.updaAddress.direccion;
        }
      });
    } else {
      this.newAddress = true;
      this.update = false;
      localStorage.setItem('updateA', 'false');

      this.tagContinue();
      this.getFormData();

      this.getSelectAddress$.next(this.order);
      this.getAddress$.next(this.order);

      this._router.navigate(['/direccion'], {
        relativeTo: this._route
      });
    }
  }

  getFormData() {
    this.order = {
      idAddress: this.idAddress,
      address: this.addressForm.get('address').value,
      channel: environment.CHANNEL,
      available: '1',
      newAddress: this.newAddress,
      addresses: this.address,
      addressDir: this.addressCoverage
    }

    this.orderA = {
      idAddress: this.idAddress,
    }
  }

  despliegue() {
    this.showDes = !this.showDes;
  }

  toReturn() {
    this._location.back();
  }

  nextSubject() {
    this.tagContinue();
    this.getFormData();

    this.getSelectAddress$.next(this.order);
    this.getAddress$.next(this.order);

    if(this.addressForm.get('address').value == 'nueva') {
      this._router.navigate(['/direccion'], {
        relativeTo: this._route
      });
    } else {
      if(localStorage.getItem('editar') == 'verificacion') {
        this._router.navigate(['/verificacion-pedido'], {
          relativeTo: this._route
        });
      } else {
        if(this.enableTimeZone){
          this._router.navigate(['/programar-entrega'], {
            relativeTo: this._route
          });
        }else{
          this._router.navigate(['/contacto'], {
            relativeTo: this._route
          });
        }
       
      }
    }
  }

  edit() {
    this.tagContinue();
    this.getFormData();

    this.getSelectAddress$.next(this.order);

    this._router.navigate(['/direccion'], {
      relativeTo: this._route
    });
  }
 
  getEnableTimeZone(){
    if(localStorage.getItem('enableSchedule') == 'true'){
      this.enableTimeZone = true;
    }else{
      this.enableTimeZone = false;
    }
  }

  public tagUrl() {
    dataLayer.push({
      'event':'ga_virtual_Page',
      'titleVp':'Direccionenvio',
      'urlVp':'/vp/direccionenvio'
    });
  }

  public tagContinue() {
    dataLayer.push({
      'event':'ga_event_direccion',
      'category':'Direccion',
      'action':'Seleccion-direccion',
      'label':'continuar_direccion',
      'UES':'Medicamentos'
    });
  }
}
