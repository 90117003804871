import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'email'
})
export class EmailPipe implements PipeTransform {
  
  transform(value: string): string {

    let maskedSection = value.slice(2);
    let visibleSection = value.slice(0,2);
    return maskedSection 
      ? visibleSection+maskedSection.replace(/.+@/g,'****@') 
      : value;
  }

}
/* visibleSection+maskedSection.replace(/.+@/g, (c, ) => c.split('').slice(0, -1).map(v => '*').join('') + '@') */ 