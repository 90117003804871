import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Location } from '@angular/common';

import { ParameterService } from 'src/app/core/services/parameter/parameter.service';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { RecurrentService } from 'src/app/core/services/recurrent/recurrent.services';

import { Router, ActivatedRoute } from '@angular/router';
import { Parameter } from 'src/app/core/models/parameter/parameter';
import { RegularExpressions } from 'src/app/core/helpers/regular-expressions/regular-expressions';
import { JwUser } from 'src/app/core/models/jw-user/jw-user';
import { JwtService } from 'src/app/core/services/jwt.service';
import { environment } from 'src/app/configs/environment';
import { SubjectRecurrent } from 'src/app/core/models/recurrent/subjectRecurrent';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


declare let dataLayer;

@Component({
	selector: 'app-patient',
	templateUrl: './validateInfoRecurrent.component.html',
	styleUrls: ['./validateInfoRecurrent.component.scss']
})
export class ValidateInfoRecurrent implements OnInit {
	icon = './assets/icons/verificar.svg';
	title = 'Revisa que todo este en orden';
	subtitle = 'Verifica la informacion antes de confirmar tu suscripcion.';
	progress = 90;
	validateInfoRecurrentForm: FormGroup;
	recurrent : SubjectRecurrent;
	name:string;
	documentType:string;
	documentNumber:string;
	nameEps:string;
	address:string;
	addressAditionalInformation:string;
	idModal : string;
	titlebox: string;
	subTitleBox : string;

	public subjectRecurrent : any;
	public resumenRecurrent : any;
	public safeResponse : any;
	
 


	/*VARIABLES BEHAVIOR SUBJECT*/
	getResumenRecurrent$ = this._behaviorSubjectService.getResumenRecurrent();
	getSubjectRecurrent$ = this._behaviorSubjectService.getSubjectRecurrent();

	constructor(
		private _formBuilder: FormBuilder,
		private _router: Router,
		private _route: ActivatedRoute,
		private _recurrentService: RecurrentService,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _location: Location,
		private _modal: ModalService
	) {
		this.validateInfoRecurrentForm = this._formBuilder.group({
			documentNumber: [''],
			documentType: [''],
			name: [''],
			nameEps: [''],
			address: [''],
			addressAditionalInformation: ['']
		});

		
	}

	ngOnInit(): void {
		this.tagUrl();
		this.subjectRecurrent = this._behaviorSubjectService.getSubjectRecurrent();
		this.resumenRecurrent = this._behaviorSubjectService.getResumenRecurrent();
		this.loadRecurrent();
		this.mapeoCamposRecurrentes();
	}
	
	public mapeoCamposRecurrentes(){
		this.recurrent =new SubjectRecurrent();
		this.recurrent.idCiudad=this.subjectRecurrent.idCiudad;
		this.recurrent.idDireccion=this.subjectRecurrent.idDireccion;
		this.recurrent.identificacion=this.subjectRecurrent.identificacion;
		this.recurrent.idEps=this.subjectRecurrent.idEps;
		this.recurrent.tipoIdentificacion=this.subjectRecurrent.tipoIdentificacion;
	}

	public loadRecurrent() {
		this.name=this.resumenRecurrent.nombreCompleto;
		if(this.name == 'NO REGISTRA NO REGISTRA'){
			this.name = null;
		}
		this.documentType=this.resumenRecurrent.tipoIdentificacion;
		this.documentNumber=this.resumenRecurrent.identificacion;
		this.nameEps=this.resumenRecurrent.nombreEps;
		this.address=this.resumenRecurrent.direccion;
		this.addressAditionalInformation = this.resumenRecurrent.comentarios;
	}


	toReturn() {
		this._location.back();
	}

	nextSubject() {
		this.tagValidation(this.documentNumber,this.nameEps);
		this._recurrentService.notifyRecurrent(this.recurrent).subscribe(
			response => {
				if(response.resultado.codigo == '200'){
					this.safeResponse=response.datos;
					this.redirect('true');
				}else{
					this.errorRecurrentMessage(response.resultado.descripcion);
				}
			},error =>{
				this.errorRecurrentMessage(error.message);
			}
			
		);
		
	}
	
	redirect(success : string){
		this._router.navigate(['/formulario-recurrente/suscripcion/'+success], {
					relativeTo: this._route
				});
	}

	public tagValidation(documentNumber: string, eps : string) {
		dataLayer.push({
			'event' : 'ga_event_veriinformacion',
			'category': 'Pedidos Recurrentes',
			'action': 'Verifica la información antes de confirmar tu suscripción',
			'label': 'Continuar',
			'UES': 'Medicamentos',
			'identificacion': documentNumber,
			'EPS' : eps
		});
	}
	
	public tagUrl() {
		dataLayer.push({
			'event': 'ga_virtual_Page',
			'titleVp': 'Verifica Informacion',
			'urlVp': '/vp/formulario-recurrente/validar'
		});
	}

	public openModal() {
		this._modal.openModal(this.idModal);
	}
	public closeModal() {
		this._modal.closeModal(this.idModal);
		this.redirect('false');		
	} 	

	public errorRecurrentMessage(message : string){
		this.title = "Advertencia"
		this.subTitleBox = message;
		this.openModal();
	}

}
