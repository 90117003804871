<!--<div class="dsc-carousel" [ngStyle]="{'min-height': finalHeight}">
  <div class="dsc-carousel__btns">
    <button (click)="setBack()">
      <img src="./assets/icons/navegacion/slider.svg" class="fa fa-chevron-circle-left" alt="atrás">
    </button>

    <button (click)="setNext()">
      <img src="./assets/icons/navegacion/slider.svg" class="fa fa-chevron-circle-left" alt="adelante">
    </button>
  </div>

  <div class="dsc-carousel__content">
    <ng-container *ngFor="let section of items;">
      <div class="dsc-carousel__content-item" [ngStyle]="{'background-image': 'url(' + section.image + ')', 'margin-left': section.marginLeft + '%' }">
        <h1>
          {{section.title.first}}

          <span>
            {{section.title.second}}
          </span>
        </h1>

        <p>
          {{section.subtitle}}
        </p>
      </div>
    </ng-container>
  </div>
  <div class="dsc-carousel__steps">
    <div 
      class="dsc-carousel__steps-step 
      dsc-carousel__steps-step-{{currentPosition === item.id ? 'active': ''}}" 
      *ngFor="let item of items" (click)="setCurrentPosition(item.id)">
    </div>
  </div>
</div>-->

<div class="slideshow-container">
  <div class="mySlides fade" *ngFor="let image of images">
    <img src="{{image.path}}">
  </div>

  <div class="circle">
    <ng-container *ngFor="let image of images; let i = index">
      <span class="dot" (click)="setCurrentPosition(i)"></span>
    </ng-container>
  </div>

  <a class="prev" (click)="prevSlide()">
    <img src="./assets/icons/navegacion/slider-left.svg" alt="prev">
  </a>
  
  <a class="next" (click)="showSlides()">
    <img src="./assets/icons/navegacion/slider-right.svg" alt="next">
  </a>
</div>
