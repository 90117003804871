import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EpsService } from 'src/app/core/services/eps/eps.service';
import { environment } from 'src/app/configs/environment';
import { Location } from '@angular/common';
import { BehaviorSubjectService } from 'src/app/core/services/behavior-subject/behavior-subject.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Eps } from 'src/app/core/models/eps/eps';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalService } from 'src/app/core/services/modal/modal.service';

declare let dataLayer;

@Component({
	selector: 'app-eps',
	templateUrl: './eps.component.html',
	styleUrls: ['./eps.component.scss']
})
export class EpsComponent implements OnInit {

	icon = './assets/icons/start/elegir-eps.svg';
	title = '¿A qué EPS perteneces?';
	messageDomicile = environment.MESSAGE_DOMICILE;
	epsForm: FormGroup;
	public epsObj: Eps;
	public eps: any[];

	/*VARIABLES BEHAVIOR SUBJECT*/
	getEps$ = this._behaviorSubjectService.getEps();
  imageEps;

	constructor(
		private _formBuilder: FormBuilder,
		private _epsService: EpsService,
		private _location: Location,
		private _behaviorSubjectService: BehaviorSubjectService,
		private _route: ActivatedRoute,
		private _router: Router,
    private _modal: ModalService,
    private sanitizer: DomSanitizer

	) {
		this.epsForm = this._formBuilder.group({
			eps: ['', [Validators.required]]
		});
	}

	ngOnInit(): void {
		this.getEps();
		if (this.getEps$ != undefined) {
			this.epsForm.get('eps').setValue(this.getEps$.value.id);
		}
	}

	public getEps() {
		this._epsService.getEps().subscribe(
			response => {
				this.eps = response.eps;
        const idArray = this.eps.map((eps) => eps.id);
        const idsSeparatedByComma = idArray.join(",");
        this.getImagesByEps(idsSeparatedByComma);
			}
		);


	}

	setEpsObj(obj: any) {
		let eps: Eps = {
			id: obj.id,
			nit: obj.nit,
			nombre: obj.name,
			EntregaFarmacia: '',
			idTurnoDigital: '',
			idServicio: '',
			webMostrar: '',
			mostrarWeb: '',
			aplicaFormulaElectronica: obj.applyElectronicFormula,
			tipo: ''
		};
		this.epsObj = eps;
	}



	nextSubject() {
		this.tagEps(this.epsObj.nombre);
		this.getEps$.next(this.epsObj);
		if(this.epsObj.nit == '800088702'){
			location.href=environment.SURAREDIRECT;
		}else{
			this.redirect();
		}
	}

	redirect() {
		this._router.navigate(['/formula'], {
			relativeTo: this._route
		});
	}
	toReturn() {
		this._location.back();
	}

	public tagEps(epsName: string) {
		dataLayer.push({
			'event': 'ga_event_seleccioneps',
			'category': 'EPS',
			'action': 'Clic-boton',
			'label': epsName,
			'UES': 'Medicamentos'
		});
	}


	public getImagesByEps(params) {
		//this._modal.openModal("Cargando eps");
    console.log('imagenes')
		this._epsService.getImagesById(params).subscribe((response) => {
		  this.imageEps = response.epsImagen
		  //this._modal.closeModal("Cargando eps");
		},
		(error) => {
     // this._modal.openModal("Cargando eps");
		},
		() => {
		//  this._modal.closeModal("Cargando eps");
		});
	  }
	  getSanitizedImageUrl(base64String: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(base64String);
	  }

}
